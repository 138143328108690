
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter();
    const catOnline = reactive({
      list: [],
      dataPG: {
        kode: "",
        title: "",
        nilaiPg: 0,
        jmlSoal: 0,
        durasi: 0,
        jurusan: [],
        selectJurusan: "",
      }
    });

    const saveUrl = async (data: any) => {
      gpf.gLoading.show();
      const hide = () => gpf.gLoading.hide();

      const url = api.tentorPassingGrade + data.kode;
      const hitApi = await api.hitApiGet(url, hide);
      const rs = hitApi.data.response;
      catOnline.dataPG = rs;
      if ( gpf.trimText(rs.jurusan) ) {
        catOnline.dataPG.jurusan = rs.jurusan;
        catOnline.dataPG.selectJurusan = rs.jurusan[0].kode;
      } else {
        catOnline.dataPG.jurusan = [];
        catOnline.dataPG.selectJurusan = "";
      }

      gpf.showModal("#modal-cat-online");

    }

    const gotoTryoutExam = () => {
      gpf.hideModal("#modal-cat-online");
      gpf.gLoading.show();

      const kode = catOnline.dataPG.kode;

      setTimeout(() => {
        if ( catOnline.dataPG.jurusan ) {
            router.push('cat-online/'+ kode + '?jurusan=' + catOnline.dataPG.selectJurusan);
        } else {
          router.push('cat-online/'+ kode);
        }
      }, 700);
    }

    const getData = async () => {
      cash("#loadingCatOnline").show();
      const hide = () => cash("#loadingCatOnline").hide();

      const url = api.tentorJenisTryout;
      const hitApi = await api.hitApiGet(url, hide);
      const rs = hitApi.data.response;
      catOnline.list = rs;

    };
    
    getData();

    return {
      saveUrl,
      gotoTryoutExam,
      catOnline,
      getData,
    };
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.catOnline.dataPG;
      ex[f] = val;

      console.log("this.catOnline ", this.catOnline);
    },
  }
});
