
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    okeHide() {
      const id = "#modal-msg-handler";
      cash(id + ' .ic-s').hide();
      cash(id + ' .ic-e').hide();
    }
  }
});
