
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    pageTitle: {
      type: String,
      default: "",
    },
    withReloadButton: {
      type: Boolean,
      default: false,
    },
    actionReloadButton: {
      type: Function,
      default: () => {},
    },
  },
});
