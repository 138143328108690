
import { defineComponent, ref, reactive } from "vue";
import { gpf } from "@/utils/global-functions";
import { helper as $h} from "@/utils/helper";
import { api } from "@/utils/api-config";

export default defineComponent({
  setup() {
    const salesReportFilter = ref("");
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();
    const listStatus = gpf.dataStatus();

    const titleTable: Array<string> = [
      "Title",
      "Content",
      "Status",
    ];

    const dataMK = reactive({
      tempData: [] as any,
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
        dataPaginate: [],
      } as any,
    });

    const getDataPagination = async () => {
      const page = dataMK.page;
      const pageSize = dataMK.pageSize;
      dataMK.paginate = $h.paginationLocal(dataMK.tempData.length, page, pageSize, 3, dataMK.tempData);
      dataMK.data = dataMK.paginate.dataPaginate;
    };

    const getData = async () => {
      cash("#loadingDataMK").show();

      const url = api.adminMarketingKit;
      const hide = () => cash("#loadingDataMK").hide();
      const hitApi = await api.hitApiGet(url, hide);
      const rs = hitApi.data.response;

      dataMK.tempData = rs;
      await getDataPagination();
      
    };
    getData();

    const changePS = () => {
      getDataPagination();
    };

    const goToPage = (p: number) => {
      dataMK.page = p;
      getDataPagination();
    };

    const nextPage = () => {
      dataMK.page = dataMK.page + 1;
      getDataPagination();
    };

    const beforePage = () => {
      dataMK.page = dataMK.page - 1;
      getDataPagination();
    };

    const lastPage = () => {
      dataMK.page = dataMK.paginate.totalPages;
      getDataPagination();
    };

    return {
      salesReportFilter,
      titleTable,
      getData,
      dataMK,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
      listStatus,
    };
  },
  data() {
    return {
      section: "add",
      exDataMK: {
        id: "",
        title: "",
        content: "",
        active: "1",
      },
      exDeleteMK: {
        id: "",
        title: "",
      },
    };
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.exDataMK;
      ex[f] = val;
    },
    async emptyField() {
      const ex = this.exDataMK;
      ex.id = "";
      ex.title = "";
      ex.content = "";
      ex.active = "1";
    },
    async addMK() {
      this.section = "add";
      await this.emptyField();
      gpf.showModal("#modal-data-mk");
    },
    async addMKApi() {
      gpf.gLoading.show();
      const ex = this.exDataMK;
      const params = new URLSearchParams();
      params.append("title", ex.title);
      params.append("content", ex.content);
      params.append("active", ex.active);

      const hide = () => gpf.gLoading.hide();
      const url = api.adminMarketingKit;
      const hitApi = await api.hitApiPost(url, params, hide);
      await gpf.handleSds(hitApi);
      gpf.hideModal("#modal-data-mk");
      await this.getData();
      await this.emptyField();

    },
    async editMK(data: any) {
      this.section = "edit";
      const ex = this.exDataMK;
      ex.id = data.id;
      ex.title = data.title;
      ex.content = data.content;
      ex.active = data.isActive.toString();
      gpf.showModal("#modal-data-mk");
    },
    async editMKApi() {
      gpf.gLoading.show();
      const ex = this.exDataMK;
      const params = new URLSearchParams();
      params.append("title", ex.title);
      params.append("content", ex.content);
      params.append("active", ex.active);

      const hide = () => gpf.gLoading.hide();
      const url = api.adminMarketingKit;
      const hitApi = await api.hitApiPut(url + ex.id, params, hide);
      gpf.hideModal("#modal-data-mk");
      await gpf.handleSds(hitApi);
      await this.getData();
      await this.emptyField();
    },
    async deleteMK(data: any) {
      const ex = this.exDeleteMK;
      ex.id = data.id;
      ex.title = data.title;
      gpf.showModal("#delete-modal-data-mk");
    },
    async deleteMKApi() {
      gpf.gLoading.show();
      const ex = this.exDeleteMK;

      const hide = () => gpf.gLoading.hide();
      const url = api.adminMarketingKit;
      const hitApi = await api.hitApiDel(url + ex.id, hide);
      await gpf.handleSds(hitApi);
      await this.getData();

      gpf.hideModal("#delete-modal-data-mk");
    },
  },
});
