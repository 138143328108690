
import { defineComponent, reactive } from "vue";
import { dataStatus } from "./kategori-soal";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";
import axios from "axios";

export default defineComponent({
  setup() {
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();
    const listStatus = dataStatus();

    const titleTable: Array<string> = [
      "Kode / Title",
      "Nilai PassingGrade / <br> Jumlah Soal",
      "Jenis Soal",
      "Status",
      "Created",
      "#",
    ];

    const dataKS = reactive({
      data: [] as any,
      search: "",
    });

    const getData = async () => {
      cash("#loadingKSadmin").show();

      axios
        .get(
          api.adminKategoriSoal,
          configGet
        )
        .then(async (result: any) => {
          const rs = result.data.response;
          // console.log("response get js ", rs);

          dataKS.data = rs;
          // console.log("dataKS.paginate ", dataKS.paginate);
          cash("#loadingKSadmin").hide();
        })
        .catch(async (error: any) => {
          cash("#loadingKSadmin").hide();
          // console.log("response get du err ", error.response);
          await gpf.handleEds(error);
        });
    };
    getData();

    return {
      titleTable,
      getData,
      dataKS,
      configGet,
      configXwfu,
      listStatus,
    };
  },
  data() {
    return {
      // for modal data user
      section: "add",
      msgSuccess: "",

      detailKS: {
        id: 0,
        kode: "",
        title: "",
        nilaiPg: "",
        jmlSoal: "",
        isActive: "1",
        idJenis: "0",
      } as any,

      exDataKS: {
        id: 0,
        kode: "",
        title: "",
        nilaiPg: "",
        jmlSoal: "",
        isActive: "1",
        idJenis: "0",
      },

      exDeleteKS: {
        id: 0,
        title: "",
      },

      listKodeKS: [],
    };
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.exDataKS;
      ex[f] = val;
    },
    async listJenisSoalApi(callback: any): Promise<void> {
      gpf.gLoading.show();
      axios
        .get(
          api.adminJenisSoal,
          this.configGet
        )
        .then(async (result: any) => {
          gpf.gLoading.hide();
          const rs = result.data.response;
          // console.log("response get js ", rs);
          this.exDataKS.idJenis = rs[0].id;
          this.listKodeKS = rs.map((e: any) => {
            const obj = {
              title: e.title,
              value: e.id,
              kode: e.kode,
            };
            return obj;
          });

          await callback();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    async emptyField() {
      const ex = this.exDataKS;
      ex.kode = "";
      ex.title = "";
      ex.nilaiPg = "";
      ex.jmlSoal = "";
      ex.isActive = "1";
      ex.idJenis = "";
    },
    async addKS() {
      await this.emptyField();
      this.section = "add";
      const showMKS = () =>{ gpf.showModal("#modal-data-ks") };
      this.listJenisSoalApi( showMKS );
    },
    addKSApi() {
      gpf.gLoading.show();
      const ex = this.exDataKS;
      const params = new URLSearchParams();
      params.append("kode", ex.kode);
      params.append("title", ex.title);
      params.append("nilaiPg", ex.nilaiPg);
      params.append("jmlSoal", ex.jmlSoal);
      params.append("isActive", ex.isActive);
      params.append("idJenis", ex.idJenis);

      axios
        .post(api.adminKategoriSoal, params, this.configXwfu)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-ks");
          await this.emptyField();
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    async editKS(data: any) {
      this.section = "edit";
      const showMKS = () =>{ gpf.showModal("#modal-data-ks") };
      await this.listJenisSoalApi( showMKS );

      gpf.gLoading.show();

      axios
        .get(api.adminKategoriSoal + data.id, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          const rs = result.data.response;
          // console.log("response get deu ", rs);

          const ex = this.exDataKS;
          ex.id = rs.id;
          ex.kode = rs.kode;
          ex.title = rs.title;
          ex.nilaiPg = rs.nilaiPg;
          ex.jmlSoal = rs.jmlSoal;
          ex.isActive = rs.isActive ? "1" : "0";
          ex.idJenis = rs.idJenis.toString();

          await gpf.showModal("#modal-data-ks");
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          // console.log("response get deu err ", error.response);
          await gpf.handleEds(error);
        });
    },
    editKSApi(): void {
      gpf.gLoading.show();
      const ex = this.exDataKS;
      const params = new URLSearchParams();
      params.append("kode", ex.kode);
      params.append("title", ex.title);
      params.append("nilaiPg", ex.nilaiPg);
      params.append("jmlSoal", ex.jmlSoal);
      params.append("isActive", ex.isActive);
      params.append("idJenis", ex.idJenis);

      axios
        .put(api.adminKategoriSoal + ex.id, params, this.configXwfu)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-ks");
          await this.emptyField();
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    detailKSApi(data: any): void {
      gpf.gLoading.show();

      axios
        .get(api.adminKategoriSoal + data.id, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          const rs = result.data.response;
          // console.log("response get deu ", rs);

          this.detailKS = rs;
          await gpf.showModal("#modal-detail-ks");
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    showPass(id: string) {
      const idHtml = cash("#"+ id);
      if (idHtml.attr("type") === "password") {
        idHtml.attr("type", "text");
      } else {
        idHtml.attr("type", "password");
      }
    },
    deleteKSApi(data: any): void {
      const ex = this.exDeleteKS;
      ex.id = data.id;
      ex.title = data.title;

      gpf.showModal("#delete-modal-data-ks");
    },
    fixDeleteKS() {
      const ex = this.exDeleteKS;

      gpf.gLoading.show();

      axios
        .delete(api.adminKategoriSoal + ex.id, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          ex.id = 0;
          ex.title = "";

          await gpf.handleSds(result);
          await gpf.hideModal("#delete-modal-data-ks");
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    }
  },
});
