
import { defineComponent, reactive } from "vue";
import { dataStatus, dataPayType, dataPayStatus } from "./pengeluaran";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";
import { helper as $h } from "@/utils/helper";
import axios from "axios";
import { formatDate } from "@/utils/date-format";

export default defineComponent({
  setup() {
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();
    const listStatus = dataStatus();
    const listPayType = dataPayType();
    const listPayStatus = dataPayStatus();

    // "id": 303,
    // "date": "2021-03-13",
    // "title": "PB Fee",
    // "description": "10 Item",
    // "qty": 1,
    // "price": 30000,
    // "total": 30000,
    // "createdAt": "2021-03-13 09:31:53",
    // "createdBy": "Admin"

    const titleTable: Array<string> = [
      "Title",
      "Description",
      "Qty",
      "Price / <br> Total",
      "Date",
      "Created At",
      "Created By",
      "#",
    ];

    const dataExpense = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
      dateRange: "",
    });

    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    dataExpense.dateRange = `${formatDate(
      firstDay.toString(),
      "DD MMM, YYYY"
    )} - ${formatDate(lastDay.toString(), "DD MMM, YYYY")}`;

    const getData = async () => {
      cash("#loadingDPadmin").show();

      // console.log("dateRange ", dataExpense.dateRange);
      // console.log("firstDay lastDay ", firstDay, lastDay);

      const page = dataExpense.page;
      const pageSize = dataExpense.pageSize;
      const search = dataExpense.search;
      const since = formatDate(
        dataExpense.dateRange.split("-")[0],
        "YYYY-MM-DD"
      );
      const until = formatDate(
        dataExpense.dateRange.split("-")[1],
        "YYYY-MM-DD"
      );
      axios
        .get(
          api.adminPengeluaran +
            "?search=" +
            search +
            "&page=" +
            page +
            "&pageSize=" +
            pageSize +
            "&since=" +
            since +
            "&until=" +
            until,
          configGet
        )
        .then(async (result: any) => {
          const ds = result.data.diagnostic;
          const rs = result.data.response;
          // console.log("response get du ", rs);

          dataExpense.data = rs;
          dataExpense.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);
          // console.log("dataExpense.paginate ", dataExpense.paginate);
          cash("#loadingDPadmin").hide();
        })
        .catch(async (error: any) => {
          cash("#loadingDPadmin").hide();
          // console.log("response get du err ", error.response);
          await gpf.handleEds(error);
        });
    };
    getData();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataExpense.page = p;
      getData();
    };

    const nextPage = () => {
      dataExpense.page = dataExpense.page + 1;
      getData();
    };

    const beforePage = () => {
      dataExpense.page = dataExpense.page - 1;
      getData();
    };

    const lastPage = () => {
      dataExpense.page = dataExpense.paginate.totalPages;
      getData();
    };

    return {
      titleTable,
      getData,
      dataExpense,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
      listStatus,
      listPayType,
      listPayStatus,

      gpf,
      formatDate,
    };
  },
  data() {
    return {
      // for modal data bs
      section: "add",
      msgSuccess: "",

      detailExp: {
        id: "",
        date: "",
        title: "",
        description: "",
        qty: "0",
        price: "0",
        total: "0",
      },

      exDataExp: {
        id: "",
        date: "",
        title: "",
        description: "",
        qty: "0",
        price: "0",
        total: "0",
      },

      exDeleteExp: {
        id: "",
        title: "",
      },
    };
  },
  methods: {
    async emptyField() {
      const dateNow = new Date();
      const ex = this.exDataExp;
      ex.id = "";
      ex.date = formatDate(dateNow.toString(), "DD MMM, YYYY") as string;
      ex.title = "";
      ex.description = "";
      ex.qty = "0";
      ex.price = "0";
      ex.total = "0";
    },
    ctc(f: string) {
      const ex: any = this.exDataExp;
      ex[f] = gpf.ctc(ex[f]);
    },
    addExp() {
      this.section = "add";
      this.emptyField();
      gpf.showModal("#modal-data-exp");
    },
    addExpApi() {
      gpf.gLoading.show();
      const ex = this.exDataExp;
      const params = new URLSearchParams();
      params.append("date", formatDate(ex.date, "YYYY-MM-DD") as string);
      params.append("title", ex.title);
      params.append("description", ex.description);
      params.append("qty", ex.qty);
      params.append("price", gpf.btc(ex.price));

      axios
        .post(api.adminPengeluaran, params, this.configXwfu)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await this.emptyField();
          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-exp");
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    async editExp(data: any) {
      gpf.gLoading.show();
      setTimeout(() => {
        gpf.gLoading.hide();
        gpf.showModal("#modal-data-exp");
      }, 2000);
      this.section = "edit";
      const ex = this.exDataExp;
      ex.id = data.id;
      ex.date = formatDate(data.date.toString(), "DD MMM, YYYY") as string;
      ex.title = data.title;
      ex.description = data.description;
      ex.qty = gpf.ctc(data.qty);
      ex.price = gpf.ctc(data.price);
      ex.total = data.total;
    },
    editExpApi() {
      gpf.gLoading.show();
      const ex = this.exDataExp;
      const params = new URLSearchParams();
      params.append("date", formatDate(ex.date, "YYYY-MM-DD") as string);
      params.append("title", ex.title);
      params.append("description", ex.description);
      params.append("qty", gpf.btc(ex.qty));
      params.append("price", gpf.btc(ex.price));

      axios
        .put(api.adminPengeluaran + "?id=" + ex.id, params, this.configXwfu)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await this.emptyField();
          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-exp");
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    async detailExpApi(data: any) {
      gpf.gLoading.show();
      setTimeout(() => {
        gpf.gLoading.hide();
      }, 2000);
      this.section = "edit";
      const ex = this.detailExp;
      ex.id = data.id;
      ex.date = formatDate(data.date.toString(), "DD MMM, YYYY") as string;
      ex.title = data.title;
      ex.description = data.description;
      ex.qty = data.qty;
      ex.price = gpf.convertToCurrency(data.price, "IDR ");
      ex.total = gpf.convertToCurrency(data.total, "IDR ");

      await gpf.showModal("#modal-detail-exp");
    },
    deleteExp(data: any): void {
      const ex = this.exDeleteExp;
      ex.id = data.id;
      ex.title = data.title;

      gpf.showModal("#delete-modal-data-exp");
    },
    deleteExpApi() {
      const ex = this.exDeleteExp;

      gpf.gLoading.show();

      axios
        .delete(api.adminPengeluaran + "?id=" + ex.id, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          ex.id = "";
          ex.title = "";

          await gpf.handleSds(result);
          await gpf.hideModal("#delete-modal-data-exp");
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
  },
});
