
import { defineComponent, reactive } from "vue";
import { api } from "@/utils/api-config";
import { gpf } from "@/utils/global-functions";
import DataPromo from "./DataPromo.vue";

export default defineComponent({
  components: {
    DataPromo,
  },
  setup() {
    const dataPS = reactive({
      data: {
        kode: "",
        pixelId: "",
        gaId: "",
        tiktokId: "",
      },
    });

    const getData = async () => {
      cash("#loadingPS").show();

      const url = api.affPS ;

      const hide = () => cash("#loadingPS").hide();
      const hitApi = await api.hitApiGet(url, hide);
      const rs = hitApi.data.response;

      dataPS.data = rs;
    };
    getData();

    const updatePSAff = async() => {
      gpf.gLoading.show();
      const hide = () => gpf.gLoading.hide();
      const ex = dataPS.data;
      const params = new URLSearchParams();
      params.append("pixelId", ex.pixelId);
      params.append("gaId", ex.gaId);
      params.append("tiktokId", ex.tiktokId);

      const hitApi = await api.hitApiPost(api.affPS, params, hide);
      await gpf.handleSds(hitApi);
      await gpf.hideModal("#modal-data-aw");
      await getData();
    };

    return {
      dataPS,
      getData,
      updatePSAff,
    };
  },
  data() {
    return {};
  },
});
