
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";

export default defineComponent({
  setup() {
    const router = useRouter();

    const tryoutHasil = (data: any): void => {
      router.push({ name: "tryout-hasil", params: data });
    };

    const dataRiwayat = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
    });

    const titleTable = [
      "Kode",
      "Type",
      "Total Skor",
      "Status",
      "Start Date",
      "End Date",
      "#",
    ];

    const getData = async () => {
      cash("#loadingCR").show();

      const hide = () => cash("#loadingCR").hide();
      const page = dataRiwayat.page;
      const pageSize = dataRiwayat.pageSize;
      const url = api.tentorHistoryCat + "?page=" + page + "&pageSize=" + pageSize;
      const hitApi = await api.hitApiGet(url, hide);
      const ds = hitApi.data.diagnostic;
      const rs = hitApi.data.response;

      dataRiwayat.data = rs;
      dataRiwayat.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);

    };
    getData();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataRiwayat.page = p;
      getData();
    };

    const nextPage = () => {
      dataRiwayat.page = dataRiwayat.page + 1;
      getData();
    };

    const beforePage = () => {
      dataRiwayat.page = dataRiwayat.page - 1;
      getData();
    };

    const lastPage = () => {
      dataRiwayat.page = dataRiwayat.paginate.totalPages;
      getData();
    };

    return {
      tryoutHasil,
      titleTable,
      dataRiwayat,
      router,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
    };
  },
  data() {
    return {
      filter: {
        field: "skd",
      },
    };
  },
  methods: {
    goDetail(data: any): void {
      this.router.push(
        "riwayat-cat/" + data.type + "/hasil" + "?kode=" + data.kode
      );
    },
  },
});
