
import { defineComponent, reactive } from "vue";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";
import axios from "axios";
import { dataPaymentType } from "./order";
import { helper as $h } from "@/utils/helper";

export default defineComponent({
  setup() {
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();
    const listPaymentType = dataPaymentType();

    const titleTable: Array<string> = [
      "Invoice",
      "Name",
      "Phone Number",
      "Email",
      "Price",
      "Paket",
      "Status",
      "Payment Status",
      "Nomor VA",
      "Created",
    ];

    const dataOrder = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
    });

    const getData = async () => {
      cash("#loadingDMaff").show();

      const page = dataOrder.page;
      const pageSize = dataOrder.pageSize;
      const search = dataOrder.search;
      axios
        .get(
          api.salesOrder +
            "?search=" +
            search +
            "&page=" +
            page +
            "&pageSize=" +
            pageSize,
          configGet
        )
        .then(async (result: any) => {
          const ds = result.data.diagnostic;
          const rs = result.data.response;
          // console.log("response get du ", rs);

          dataOrder.data = rs;
          dataOrder.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);
          // console.log("dataOrder.paginate ", dataOrder.paginate);
          cash("#loadingDMaff").hide();
        })
        .catch(async (error: any) => {
          cash("#loadingDMaff").hide();
          // console.log("response get du err ", error.response);
          await gpf.handleEds(error);
        });
    };
    getData();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataOrder.page = p;
      getData();
    };

    const nextPage = () => {
      dataOrder.page = dataOrder.page + 1;
      getData();
    };

    const beforePage = () => {
      dataOrder.page = dataOrder.page - 1;
      getData();
    };

    const lastPage = () => {
      dataOrder.page = dataOrder.paginate.totalPages;
      getData();
    };

    // ==========================================================
    const titleTableSM: Array<string> = [
      "Name",
      "Username",
      "Email",
      "Phone Number",
      "Status",
    ];

    const dataSM = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
    });

    const getDataSM = async () => {
      cash("#loadingSM").show();

      const page = dataSM.page;
      const pageSize = dataSM.pageSize;
      const search = dataSM.search;
      axios
        .get(
          api.salesMember +
            "?search=" +
            search +
            "&page=" +
            page +
            "&pageSize=" +
            pageSize,
          configGet
        )
        .then(async (result: any) => {
          const ds = result.data.diagnostic;
          const rs = result.data.response;
          // console.log("response get du ", rs);

          dataSM.data = rs;
          dataSM.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);
          // console.log("dataSM.paginate ", dataSM.paginate);
          cash("#loadingSM").hide();
        })
        .catch(async (error: any) => {
          cash("#loadingSM").hide();
          // console.log("response get du err ", error.response);
          await gpf.handleEds(error);
        });
    };

    const changePSSM = () => {
      getDataSM();
    };

    const goToPageSM = (p: number) => {
      dataSM.page = p;
      getDataSM();
    };

    const nextPageSM = () => {
      dataSM.page = dataSM.page + 1;
      getDataSM();
    };

    const beforePageSM = () => {
      dataSM.page = dataSM.page - 1;
      getDataSM();
    };

    const lastPageSM = () => {
      dataSM.page = dataSM.paginate.totalPages;
      getDataSM();
    };
    // ==========================================================

    return {
      titleTable,
      getData,
      dataOrder,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
      listPaymentType,

      // ================================
      titleTableSM,
      dataSM,
      getDataSM,
      changePSSM,
      goToPageSM,
      nextPageSM,
      beforePageSM,
      lastPageSM,
      // ================================
    };
  },
  data() {
    return {
      exDataNewOrder: {
        memberId: "",
        promoId: "",
        packageId: "",
        paymentId: "",
        // price: "",
        paymentType: "",
      },
      listPromoId: [] as any,
      listPackageId: [] as any,
      listPaymentId: [] as any,
      required: {
        memberId: false,
      },

      exDataConfirmOrder: {
        invoice: "",
        tglTransaksi: "",
        jmlTransaksi: "",
        noRekening: "",
        namaPengirim: "",
        bankTujuan: "",
        buktiTransfer: "",
        nameBT: "Choose File",
        oversizeBT: false,
        tempBT: "",
      }
    };
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.exDataNewOrder;
      ex[f] = val;
    },
    changeTSCO(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.exDataConfirmOrder;
      ex[f] = val;
    },
    chooseBT() {
      const id = "fileBT";
      const btn: any = document.getElementById(id);
      btn.click();
    },
    removeBT() {
      const ex = this.exDataConfirmOrder;
      ex.nameBT = "Choose File";
      ex.buktiTransfer = "";
      cash("#showImage").attr("src", "");
    },
    handleFile(evt: any ) {
      const target = evt.target;
      const files = target.files;
      const sizeFiles = target.files[0].size;
      const ex = this.exDataConfirmOrder;

      if (sizeFiles > 1000000) {
        ex.oversizeBT = true;
        this.removeBT();
      } else {
        const nameFile = target.files[0].name;
        ex.nameBT = nameFile;
        ex.buktiTransfer = files[0];
        ex.oversizeBT = false;
        // const bodyFormData = new FormData();
        // bodyFormData.append("files", files[0]);

        this.renderImage(target.files[0]);
      }
      // console.log("evt ", evt);
    },
    renderImage(files: any) {
      // console.log('masuk render image ', files)
      const reader = new FileReader();
      reader.onload = function(e: any) {
        cash("#showImage").attr("src", e.target.result);
      };
      reader.readAsDataURL(files);
    },
    showTableMember() {
      cash("#tableSearchMember").show();
    },
    hideTableMember() {
      cash("#tableSearchMember").hide();
    },
    async emptyField() {
      const ex = this.exDataNewOrder;
      ex.memberId = "";
      ex.promoId = "";
      ex.packageId = "";
      ex.paymentId = "";
      // ex.price = "";
      ex.paymentType = "";
    },
    ctcNO(f: string) {
      const ex: any = this.exDataNewOrder;
      ex[f] = gpf.ctc(ex[f]);
    },
    ctcKO(f: string) {
      const ex: any = this.exDataConfirmOrder;
      ex[f] = gpf.ctc(ex[f]);
    },
    chooseMember(data: any) {
      this.exDataNewOrder.memberId = data.id;
      this.dataSM.search = data.name;

      setTimeout(() => {
        this.hideTableMember();
      }, 1000);
    },
    async addNO() {
      await gpf.gLoading.show();

      const obj = {
        id: "0",
        code: "Tanpa Promo",
        discount: 0,
        type: "percent",
      };

      const listPromo = await api.getPromo();
      this.listPromoId.push(obj);
      listPromo.map((e: any) => {
        this.listPromoId.push(e);
      });
      this.listPackageId = await api.getPackage();
      this.listPaymentId = await api.getPayment();

      const ex = this.exDataNewOrder;
      ex.promoId = "0";
      ex.packageId = this.listPackageId[0].id;
      ex.paymentId = this.listPaymentId[0].options[0].value;
      ex.paymentType = this.listPaymentType[0].value;

      await gpf.showModal("#modal-data-no");
      await gpf.gLoading.hide();
    },
    checkValidationNO() {
      const rq = this.required;
      const ex = this.exDataNewOrder;
      if ( !ex.memberId || !this.dataSM.search ) {
        rq.memberId = true;
        return false;
      } else {
        rq.memberId = false;
        return true;
      }
    },
    addNOApi() {
      const check = this.checkValidationNO();
      if ( check ) {
        
        gpf.gLoading.show();
        const ex = this.exDataNewOrder;
        const params = new URLSearchParams();
        params.append("memberId", ex.memberId);
        params.append("promoId", ex.promoId === "0" ? "" : ex.promoId);
        params.append("packageId", ex.packageId);
        params.append("paymentId", ex.paymentId);
        params.append("paymentType", ex.paymentType);
        // params.append("price", gpf.btc(ex.price));

        axios
          .post(api.salesOrder, params, this.configXwfu)
          .then(async (result: any) => {
            gpf.gLoading.hide();

            await gpf.handleSds(result);
            await gpf.hideModal("#modal-data-no");
            await this.emptyField();
            await this.getData();
          })
          .catch(async (error: any) => {
            gpf.gLoading.hide();
            await gpf.handleEds(error);
          });

      }
    },
    seeInfoVA(data: any) {
      gpf.gLoading.show();
      const inv = data.invoice;
      const va = data.nomorVA;

      axios
        .get(
          api.salesStatusOrder +
            "?invoice=" +
            inv +
            "&nomorVA=" +
            va,
          this.configGet
        )
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await gpf.handleSds(result);
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();

          // console.log("response get du err ", error.response);
          await gpf.handleEds(error);
        });
    },
    async confirmOrder(data: any) {
      gpf.gLoading.show();
      const ex = this.exDataConfirmOrder;
      const dateNow = new Date();

      ex.invoice = data.invoice;
      ex.jmlTransaksi = "";
      ex.noRekening = "";
      ex.namaPengirim = "";
      ex.buktiTransfer = "";
      ex.nameBT = "Choose File";
      ex.oversizeBT = false;
      ex.tempBT = "";
      ex.tglTransaksi = $h.formatDate(dateNow.toString(), "DD MMM, YYYY");

      this.listPaymentId = await api.getPayment();
      ex.bankTujuan = this.listPaymentId[0].options[0].value;

      gpf.showModal("#modal-data-ko");
      gpf.gLoading.hide();
    },
    confirmOrderApi() {
      gpf.gLoading.show();
      const ex = this.exDataConfirmOrder;
      const body = new FormData();
      body.append("tglTransaksi", $h.formatDate(ex.tglTransaksi, "YYYY-MM-DD"));
      body.append("jmlTransaksi", gpf.btc(ex.jmlTransaksi));
      body.append("noRekening", ex.noRekening);
      body.append("namaPengirim", ex.namaPengirim);
      body.append("bankTujuan", ex.bankTujuan);
      body.append("buktiTransfer", ex.buktiTransfer);

      axios
        .post(api.salesConfirm + ex.invoice, body, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-ko");
          await this.emptyField();
          await this.getData();
        })
        .catch(async(error: any) => {
          gpf.gLoading.hide();
          // console.log("response add dm err ", error.response);
          await gpf.handleEds(error);
        });
    },
  },
});
