type tv = {
  title: string;
  value: string;
};

const dataPaymentType = (): Array<tv> => {
  const obj: Array<tv> = [
    {
      title: "Cash",
      value: "cash",
    },
    {
      title: "Credit",
      value: "credit",
    },
  ];
  return obj;
};

export { dataPaymentType };
