import { breadcrumb, Breadcrumb } from "@/components/top-bar";
import { getRole } from "./cookie-next";

const defaultBreadcrumb: Breadcrumb[] = [
  {
    title: "Dashboard",
    to: "/" + getRole(),
  },
];

export const setBreadcrumb = (data?: Breadcrumb[]) => {
  if (!data || !data.length) {
    breadcrumb.value = defaultBreadcrumb;
  }

  breadcrumb.value = data as Breadcrumb[];
};
