
import { configAppJson } from "@/services/constant";
import { api } from "@/utils/api-config";
import { gpf } from "@/utils/global-functions";
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";

type TypeHasilCat = {
  idSoal: number;
  nomor: number;
  ragu: string;
  opsi: string;
};

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();

    const params = route.params;
    const type = params.slug;
    const loc = window.location;

    // const resp = dataExam.response;
    // const soalList = dataExam.response.soal;
    // console.log('soalList ', soalList.length, type);

    gpf.hideModal("#modal-cat-cpns");
    gpf.hideModal("#modal-cat-pppk");

    const rn = (): void => {
      cash("#cvRn").toggleClass("transform rotate-180");
      cash("#nsRn").toggleClass("flex-wrap justify-center");
    };

    return {
      params,
      rn,
      route,
      router,
      type,
      loc,

      gpf,
    };
  },
  data() {
    return {
      cs: {
        id: 0,
        nomor: 0,
        kategori: "",
        bidang: "",
        question: "",
        questionImg: "",
        option: [
          {
            option: "",
            text: "",
            img: "",
          },
        ],
      },
      allSoal: [] as any[],
      cis: 0,
      time: {
        hours: "00",
        minutes: "00",
        seconds: "00",
      },
      timeExpired: false,
      urlExam: "",
      dataExam: {
        kode: "",
        type: "",
        startDate: "",
        endDate: "",
        duration: 0,
        title: "",
        soal: [],
      },
      countDownDate: 0,
    };
  },
  methods: {
    async getSoalCat(jrs: string) {
      const url = this.urlExam;
      const hitApi = await api.hitApiGet(url);
      const rs = hitApi.data.response;
      this.dataExam = rs;
      this.allSoal = rs.soal;
      // this.countDownDate = rs.endDate;
      this.cs = this.allSoal[this.cis];
      this.countDownDate = new Date(rs.endDate).getTime();

      gpf.setUpPayloadSubmitHasilCat(rs.soal, rs.kode);
      gpf.setUpDataCat(rs, rs.kode);
      this.countTime();

      if (jrs === "with") {
        await this.withJurusan();
      } else {
        await this.withoutJurusan();
      }
    },
    async tryoutHasil() {
      // console.log("loc.search ", this.loc.search);
      gpf.gLoading.show();

      const doneExam = () => console.log("Tryout Exam Done");
      const kodeExam = gpf.getParameterByName("kode");
      const jwbCat: Array<TypeHasilCat> = JSON.parse(
        gpf.memberHasilCat(kodeExam)
      );

      const url = api.tentorSubmitCat + kodeExam;
      const config = configAppJson();

      await api.hitApiPost(url, jwbCat, doneExam, config);
      await gpf.removeStorageCat(kodeExam);
      this.router.push(this.type + "/hasil" + this.loc.search);
    },
    changeSoal(soal: any, idx: number): void {
      this.cs = soal;
      this.cis = idx;
    },
    changeSoalCis(tp: string): void {
      tp === "next" ? this.cis++ : this.cis--;
      this.cs = this.allSoal[this.cis];
    },
    chooseOpt(cs: any, no: any, idx: any): void {
      const a = cs.option;
      for (let i = 0; i < a.length; i++) {
        if (i !== idx) {
          cash('[opt="chs-' + no + "-" + i + '"]').removeClass("active");
        } else {
          cash('[opt="chs-' + no + "-" + idx + '"]').addClass("active");
          this.allSoal[this.cis].answer = cs.option[idx].option;
          this.allSoal[this.cis].answerIdx = idx;
          gpf.setUpPayloadSubmitHasilCat(this.allSoal, this.dataExam.kode);
        }
      }
    },
    setRagu() {
      gpf.setUpPayloadSubmitHasilCat(this.allSoal, this.dataExam.kode);
    },
    async countTime() {
      // console.log("masuk ke countTime ", this.countDownDate);
      // Update the count down every 1 second
      const x = setInterval(async () => {
        // Get today's date and time
        const now = new Date().getTime();

        // Find the distance between now and the count down date
        const distance = this.countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        // const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        this.time.hours = hours.toString();
        this.time.minutes = minutes.toString();
        this.time.seconds = seconds.toString();

        // Display the result in the element with id="demo"

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(x);
          this.timeExpired = true;

          await this.tryoutHasil();
        }
      }, 1000);
    },
    async withoutJurusan() {
      let newurl = "";
      newurl =
        this.loc.protocol +
        "//" +
        this.loc.host +
        this.loc.pathname +
        "?kode=" +
        this.dataExam.kode;
      window.history.pushState({ path: newurl }, "Page Exam", newurl);

      gpf.gLoading.hide();
    },
    async withJurusan() {
      const jurusan = gpf.getParameterByName("jurusan");
      let newurl = "";

      newurl =
        this.loc.protocol +
        "//" +
        this.loc.host +
        this.loc.pathname +
        "?jurusan=" +
        jurusan +
        "&kode=" +
        this.dataExam.kode;
      window.history.pushState({ path: newurl }, "Page Exam", newurl);

      gpf.gLoading.hide();
    },
  },
  async mounted() {
    const jurusan = gpf.getParameterByName("jurusan");
    const kodeExam = gpf.getParameterByName("kode");

    if (kodeExam === null && jurusan === null) {
      this.urlExam = api.tentorSoalCat + this.type;
      await this.getSoalCat("without");
    } else if (jurusan !== null) {
      this.urlExam = api.tentorSoalCat + this.type + "?jurusan=" + jurusan;
      await this.getSoalCat("with");
    } else {
      const dataCat: any = JSON.parse(gpf.getDataCat(kodeExam));
      // console.log("dataCat ", dataCat.endDate);
      this.countDownDate = new Date(dataCat.endDate).getTime();
      this.countTime();
      this.dataExam = dataCat;
      this.allSoal = JSON.parse(gpf.getSoalCat(kodeExam));
      this.cs = this.allSoal[this.cis];
    }
  },
});
