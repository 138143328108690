
import { defineComponent, ref, reactive } from "vue";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";

export default defineComponent({
  setup() {
    const salesReportFilter = ref("");
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();

    const titleTable: Array<string> = [
      "Nama",
      "Saldo",
      "Nominal",
      "Bank Name",
      "Account Name / <br> Account Number",
      "Status",
      "Created",
      "#",
    ];

    const dataAW = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
    });

    const getData = async () => {
      cash("#loadingAW").show();

      const page = dataAW.page;
      const pageSize = dataAW.pageSize;
      const search = dataAW.search;
      const url =
        api.adminWithdrawal +
        "?search=" +
        search +
        "&page=" +
        page +
        "&pageSize=" +
        pageSize;

      const hide = () => cash("#loadingAW").hide();
      const hitApi = await api.hitApiGet(url, hide);
      const ds = hitApi.data.diagnostic;
      const rs = hitApi.data.response;

      dataAW.data = rs;
      dataAW.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);

    };
    getData();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataAW.page = p;
      getData();
    };

    const nextPage = () => {
      dataAW.page = dataAW.page + 1;
      getData();
    };

    const beforePage = () => {
      dataAW.page = dataAW.page - 1;
      getData();
    };

    const lastPage = () => {
      dataAW.page = dataAW.paginate.totalPages;
      getData();
    };

    return {
      salesReportFilter,
      titleTable,
      getData,
      dataAW,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
    };
  },
  data() {
    return {
      exDataAW: {
        id: "",
        pin: "",
        amount: "",
        bank: "",
      },
      listBank: [],
      exAcceptAW: {
        id: "0",
        name: "",
      },
      exDeleteAW: {
        id: "0",
        name: "",
      },
    };
  },
  methods: {
    async acceptAW(data: any) {
      const ex = this.exAcceptAW;
      ex.id = data.id;
      ex.name = data.name;

      gpf.showModal("#modal-accept-aw");
    },
    async acceptAWApi() {
      gpf.gLoading.show();

      const hide = () => gpf.gLoading.hide();
      const ex = this.exAcceptAW;
      const params = new URLSearchParams();
      params.append("id", ex.id);

      const hitApi = await api.hitApiPost(api.adminWithdrawal, params, hide);
      await gpf.handleSds(hitApi);
      await this.getData();
    },
    deleteAW(data: any): void {
      const ex = this.exDeleteAW;
      ex.id = data.id;
      ex.name = data.name;

      gpf.showModal("#delete-modal-data-aw");
    },
    async deleteAWApi() {
      gpf.gLoading.show();

      const hide = () => gpf.gLoading.hide();
      const ex = this.exDeleteAW;
      const params = new URLSearchParams();
      params.append("id", ex.id);

      const hitApi = await api.hitApiDel(api.adminWithdrawal + ex.id, hide);
      await gpf.handleSds(hitApi);
      await gpf.hideModal("#delete-modal-data-aw");
      await this.getData();

    },
  },
});
