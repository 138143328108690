import { ActionTree, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.SET_TITLE_PAGE](
    { commit }: AugmentedActionContext,
    activeTitle: string
  ): void;
  [ActionTypes.SET_URL_SUB_MENU](
    { commit }: AugmentedActionContext,
    activeUrlSubMenu: string
  ): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.SET_TITLE_PAGE]({ commit }, activeTitle: string) {
    commit(MutationTypes.SET_TITLE_PAGE, activeTitle);
  },
  [ActionTypes.SET_URL_SUB_MENU]({ commit }, activeUrlSubMenu: string) {
    commit(MutationTypes.SET_URL_SUB_MENU, activeUrlSubMenu);
  },
};
