import { App } from "vue";
import { logout } from "@/utils/api-config";
import Toastify from "toastify-js";
import useClipboard from "vue-clipboard3";
import { loadingScreen } from "@/utils/loading-screen";

type ppgt = {
  currentPage: number;
  endIndex: number;
  endPage: number;
  pageSize: number;
  pages: Array<any>;
  startIndex: number;
  startPage: number;
  totalItems: number;
  totalPages: number;
};

type tv = {
  title: string;
  value: string;
};

const { toClipboard } = useClipboard();
const globalFunctions = {
  async removeDoubleModal(idm: string): Promise<void> {
    const idReplacer = document.querySelectorAll(
      "[data-modal-replacer=" + idm.slice(1, idm.length) + "]"
    );
    let countReplacer = 0;
    Array.prototype.forEach.call(idReplacer, (node) => {
      if (countReplacer !== 0) {
        node.parentNode.removeChild(node);
      }
      countReplacer++;
      if (countReplacer === idReplacer.length) {
        countReplacer = 0;
      }
    });

    const idEl = document.querySelectorAll(
      "[id=" + idm.slice(1, idm.length) + "]"
    );
    let countEl = 0;
    Array.prototype.forEach.call(idEl, (node) => {
      if (countEl !== 0) {
        node.parentNode.removeChild(node);
      }
      countEl++;
      if (countEl === idEl.length) {
        countEl = 0;
      }
    });
  },
  async showModal(id: string): Promise<void> {
    await globalFunctions.removeDoubleModal(id);
    await cash(id).modal("show");
  },
  async hideModal(id: string): Promise<void> {
    await globalFunctions.removeDoubleModal(id);
    await cash(id).modal("hide");
  },
  async toggleModal(id: string): Promise<void> {
    await globalFunctions.removeDoubleModal(id);
    await cash(id).modal("toggle");
  },
  async onShowModal(id: string, callback: Function): Promise<void> {
    await cash(id).modal("on.show", callback);
  },
  async onHideModal(id: string, callback: Function): Promise<void> {
    await cash(id).modal("on.hide", callback);
  },
  async showDD(id: string): Promise<void> {
    await cash(id).dropdown("show");
  },
  async hideDD(id: string): Promise<void> {
    await cash(id).dropdown("hide");
    await cash(id).dropdown("hide");
  },
  upperCase(txt: string): string {
    return txt.toUpperCase();
  },
  lowerCase(txt: string): string {
    return txt.toLowerCase();
  },
  trimText(txt: string): string {
    if (txt === undefined || txt === "" || txt === null) {
      return "";
    } else {
      return txt;
    }
  },
  trimTextDash(txt: string): string {
    if (txt === undefined || txt === "" || txt === null) {
      return "-";
    } else {
      return txt;
    }
  },
  gLoading: {
    async show(): Promise<void> {
      // cash("#gLoading").modal("show");
      await globalFunctions.removeDoubleModal("#gLoading");
      // globalFunctions.showModal("#gLoading");

      // updated function
      loadingScreen().show();
    },
    async hide(): Promise<void> {
      // cash("#gLoading").modal("hide");
      await globalFunctions.removeDoubleModal("#gLoading");
      // globalFunctions.hideModal("#gLoading");

      // updated function
      loadingScreen().hide();
    },
    async toggle(): Promise<void> {
      // cash("#gLoading").modal("toggle");
      await globalFunctions.removeDoubleModal("#gLoading");
      globalFunctions.toggleModal("#gLoading");
    },
  },
  getParameterByName(name: string): any {
    const url = window.location.href;
    name = name.replace(/[\\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },
  getParameterByNameWL(urlLink: string, name: string): any {
    const url = urlLink;
    name = name.replace(/[\\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },
  async handleJwtExpired(error: any): Promise<void> {
    const rs = error.response;
    const eds = rs.data;
    const jwt = eds.message ? eds.message : eds.diagnostic.message;
    if (jwt === "jwt expired") {
      globalFunctions.showModal("#modal-jwt-expired");
      setTimeout(() => {
        globalFunctions.hideModal("#modal-jwt-expired");
        logout();
      }, 7000);
    }
  },
  async handleSds(rs: any, type = "msg"): Promise<void> {
    const ds = rs.data;

    const message = ds?.message || ds?.diagnostic?.message;

    if (type === "toast") {
      Toastify({
        text: message,
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "#0e2c88",
        stopOnFocus: true,
      }).showToast();
    } else {
      this.msgHandler(true, "Good Job!", message);
    }

    globalFunctions.gLoading.hide();
  },
  async handleSdsV2(message: string, type = "msg"): Promise<void> {
    if (type === "toast") {
      Toastify({
        text: message,
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "#0e2c88",
        stopOnFocus: true,
      }).showToast();
    } else {
      this.msgHandler(true, "Good Job!", message, 5000);
    }

    globalFunctions.gLoading.hide();
  },
  async handleEds(error: any): Promise<void> {
    const ers = error?.response;

    if (ers === undefined) {
      Toastify({
        text:
          "Disconnected - Silahkan cek koneksi Internet Anda. \n Atau Network Error.",
        duration: -1,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "#0e2c88",
        stopOnFocus: true,
      }).showToast();
      return;
    }

    const eds = ers.data;
    const message = eds.message ? eds.message : eds.diagnostic.message;
    const status = ers.status;

    const jwt = eds.message ? eds.message : eds.diagnostic.message;
    if (jwt === "jwt expired") {
      globalFunctions.showModal("#modal-jwt-expired");
      setTimeout(() => {
        globalFunctions.hideModal("#modal-jwt-expired");
        logout();
      }, 7000);

      return;
    }

    const textualStickyToast = () => {
      Toastify({
        text: message,
        duration: -1,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "#0e2c88",
        stopOnFocus: true,
      }).showToast();
    };

    if (status === 403) {
      textualStickyToast();
    } else {
      await globalFunctions.msgHandler(false, "Error!", message);
    }

    globalFunctions.gLoading.hide();
  },
  paginate(
    totalItems: number,
    currentPage: number,
    pageSize: number,
    maxPages: number
  ): ppgt {
    // calculate t otal pages
    const totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number;
    let endPage: number;
    if (totalPages <= maxPages) {
      // total pages less than max so show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1;
        endPage = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // current page near the end
        startPage = totalPages - maxPages + 1;
        endPage = totalPages;
      } else {
        // current page somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  },
  setUpDataCat(data: any, kode: string): void {
    window.localStorage.setItem("dataCat" + kode, JSON.stringify(data));
  },
  setUpPayloadSubmitHasilCat(data: Array<any>, kode: string): void {
    // console.log('data set up ', data);
    const hasil = data.map((e) => {
      const obj = {
        nomor: e.nomor,
        idSoal: e.id,
        opsi: e.answer,
        ragu: e.ragu ? "Y" : "N",
      };
      // console.log('e.ragu ? "Y" : "N" ', e.ragu ? "Y" : "N");
      return obj;
    });
    window.localStorage.setItem(
      "hasilCat" + kode,
      JSON.stringify({ hasil: hasil })
    );
    window.localStorage.setItem("soalCat" + kode, JSON.stringify(data));
  },
  async removeStorageCat(kode: string): Promise<void> {
    window.localStorage.removeItem("hasilCat" + kode);
    window.localStorage.removeItem("soalCat" + kode);
    window.localStorage.removeItem("dataCat" + kode);
    window.localStorage.removeItem("submitCatApi" + kode);
  },
  checkSetUpPayloadSHC(kode: string): boolean {
    const hasilCat = window.localStorage.getItem("hasilCat" + kode);
    const soalCat = window.localStorage.getItem("soalCat" + kode);

    if (hasilCat === null || soalCat === null) {
      return false;
    } else {
      return true;
    }
  },
  convertToCurrency(n: string, currency: string): string {
    const m = parseInt(n ? globalFunctions.btc(n) : "0");
    return (
      currency +
      m.toFixed(0).replace(/./g, function (c: any, i: any, a: any) {
        return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
      })
    );
  },
  ctc(n: string): string {
    const m = parseInt(n ? globalFunctions.btc(n) : "0");
    return m.toFixed(0).replace(/./g, function (c: any, i: any, a: any) {
      return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
    });
  },
  btc(n: string): string {
    // console.log("btc ", n);
    return n ? n.toString().split(".")[0].split(",").join("") : "0";
  },
  getDataCat(kode: string): any {
    const dataCat = window.localStorage.getItem("dataCat" + kode);
    const rs = JSON.stringify(dataCat);
    const rss = JSON.parse(rs);
    return rss;
  },
  memberHasilCat(kode: string): any {
    const hasilCat = window.localStorage.getItem("hasilCat" + kode);
    const rs = JSON.stringify(hasilCat);
    return JSON.parse(rs);
  },
  getSoalCat(kode: string): any {
    const soalCat = window.localStorage.getItem("soalCat" + kode);
    const rs = JSON.stringify(soalCat);
    const rss = JSON.parse(rs);
    return rss;
  },
  randomNumbers(from: number, to: number, length: number): string {
    const numbers = [0];
    for (let i = 1; i < length; i++) {
      numbers.push(Math.ceil(Math.random() * (from - to) + to));
    }

    return numbers.join("-");
  },
  async msgHandler(
    type: boolean,
    tmsg: string,
    cmsg: string,
    time = 3000
  ): Promise<void> {
    const id = "#modal-msg-handler";
    type ? cash(id + " .ic-s").show() : cash(id + " .ic-e").show();
    type ? cash(id + " .ic-e").hide() : cash(id + " .ic-s").hide();
    const tmsgEl = cash(id + " .t-msg");
    const cmsgEl = cash(id + " .c-msg");

    tmsgEl[0].innerHTML = tmsg;
    cmsgEl[0].innerHTML = cmsg;
    // console.log("tmsg ", id + ".t-msg", tmsgEl);

    await globalFunctions.showModal(id);

    setTimeout(async () => {
      await globalFunctions.hideModal(id);
    }, time);
  },
  showPass(id: string): void {
    const idHtml = cash("#" + id);
    if (idHtml.attr("type") === "password") {
      idHtml.attr("type", "text");
    } else {
      idHtml.attr("type", "password");
    }
  },
  async copy(txt: string): Promise<void> {
    try {
      await toClipboard(txt);

      Toastify({
        text: "Text Berhasil di Copy.",
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "right",
        backgroundColor: "#0e2c88",
        stopOnFocus: true,
      }).showToast();
    } catch (e) {
      console.error(e);
    }
  },
  getRT(): string {
    // get random theme
    const rt = Math.floor(Math.random() * (15 - 1 + 1)) + 1;
    return "text-theme-" + rt;
  },
  dataStatus(): Array<tv> {
    const obj: Array<tv> = [
      {
        title: "Active",
        value: "1",
      },
      {
        title: "Suspend",
        value: "0",
      },
    ];
    return obj;
  },
};

const install = (app: App): void => {
  app.config.globalProperties.$gpf = globalFunctions;
};

export { install as default, globalFunctions as gpf };
