
import { defineComponent, reactive } from "vue";
import { helper as $h} from "@/utils/helper";
import { api } from "@/utils/api-config";

export default defineComponent({
  setup() {
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();

    const titleTable: Array<string> = [
      "Title",
      "Content",
    ];

    const dataLF = reactive({
      tempData: [] as any,
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
        dataPaginate: [],
      } as any,
    });

    const getDataPagination = async () => {
      const page = dataLF.page;
      const pageSize = dataLF.pageSize;
      dataLF.paginate = $h.paginationLocal(dataLF.tempData.length, page, pageSize, 3, dataLF.tempData);
      dataLF.data = dataLF.paginate.dataPaginate;
    };

    const getData = async () => {
      cash("#loadingDataMK").show();

      const hide = () => cash("#loadingDataMK").hide();
      const url = api.affLinkRef;
      const hitApi = await api.hitApiGet(url, hide);
      const rs = hitApi.data.response;

      dataLF.tempData = rs;
      await getDataPagination();

    };
    getData();

    const changePS = () => {
      getDataPagination();
    };

    const goToPage = (p: number) => {
      dataLF.page = p;
      getDataPagination();
    };

    const nextPage = () => {
      dataLF.page = dataLF.page + 1;
      getDataPagination();
    };

    const beforePage = () => {
      dataLF.page = dataLF.page - 1;
      getDataPagination();
    };

    const lastPage = () => {
      dataLF.page = dataLF.paginate.totalPages;
      getDataPagination();
    };

    return {
      titleTable,
      getData,
      dataLF,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
    };
  },
  data() {
    return {};
  },
  methods: {
  },
});
