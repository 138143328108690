import { VueCookieNext } from "vue-cookie-next";
import { gpf } from "@/utils/global-functions";
import { AxiosInstance } from "axios";

let $axios: any;

const $api = (): AxiosInstance => {
  return $axios;
};

const setAxios = (newAxios: any) => {
  $axios = newAxios;
};

export { $api, setAxios };

const cookie = VueCookieNext;
export const getToken = () => {
  const token = cookie.getCookie("tkn-data");
  return token;
};

export const somethingWrong = () => {
  gpf.showModal("#modal-something-wrong");
};

export const configGet = () => {
  const token = getToken();
  const obj = {
    headers: {
      Authorization: token,
    },
  };
  if (token === null) {
    somethingWrong();
    return obj;
  } else {
    return obj;
  }
};

export const configXwfu = () => {
  const token = getToken();
  const obj = {
    headers: {
      Authorization: token,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  if (token === null) {
    somethingWrong();
  } else {
    return obj;
  }
};

export const configAppJson = () => {
  const token = getToken();
  const obj = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };
  if (token === null) {
    somethingWrong();
  } else {
    return obj;
  }
};
