import { gpf } from "@/utils/global-functions";
import { reactive } from "vue";
import { PopupStatusState, PopupStatusType } from "./types";

const idPopupStatus = `#popup-status`;

export const statePopupStatus = reactive({
  type: "s" as PopupStatusType,
  message: "Any message",
}) as PopupStatusState;

export const setupPopupStatus = (popupData?: PopupStatusState) => {
  statePopupStatus.type = popupData?.type || "s";
  statePopupStatus.message = popupData?.message || "Any message";
  statePopupStatus.action = popupData?.action || popupStatusHide;
};

export const emptyStatePopupStatus = () => {
  statePopupStatus.type = "s";
  statePopupStatus.message = "Any message";
  statePopupStatus.action = popupStatusHide;
};

export const popupStatus = (popupData?: PopupStatusState) => {
  setupPopupStatus(popupData);

  return {
    show: () => {
      setTimeout(() => {
        gpf.showModal(idPopupStatus);
      }, 500);
    },
    hide: () => {
      gpf.hideModal(idPopupStatus);
      emptyStatePopupStatus();
    },
  };
};

export const popupStatusHide = () => popupStatus().hide();
