import { computed, ref } from "vue";
import { VueCookieNext } from "vue-cookie-next";

const currentPackage = ref("");

export const setupCurrentUserPackage = (): void => {
  const cookie = VueCookieNext;
  const loginData = cookie.getCookie("login-data");
  console.log("setupcurrentPackages newcurrentPackage ", loginData);
  currentPackage.value = loginData?.paket || "";
};

export const isPackageBasic = computed(() => currentPackage.value === "basic");

export const isPackagePremium = computed(
  () => currentPackage.value === "premium"
);

export const isPackagePlatinum = computed(
  () => currentPackage.value === "platinum"
);
