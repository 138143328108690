
import { defineComponent, reactive } from "vue";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";
import axios from "axios";

export default defineComponent({
  setup() {
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();
    const role = api.getRole();

    const titleTable: Array<string> = [
			"Kode",
      "Nama",
      "Account Name",
      "Account Number",
			"#",
    ];

    // "id": 4,
    // "code": "002",
    // "name": "test Bank BRI",
    // "accountName": "Nasrullah",
    // "accountNumber": "1234567890"

    const dataAB = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
    });

    const getData = async () => {
      cash("#loadingAB").show();

      const page = dataAB.page;
      const pageSize = dataAB.pageSize;
      const search = dataAB.search;
      axios
        .get(
          api.publicAkunBank +
            "?search=" +
            search +
            "&page=" +
            page +
            "&pageSize=" +
            pageSize,
          configGet
        )
        .then(async (result: any) => {
          const ds = result.data.diagnostic;
          const rs = result.data.response;
          // console.log("response get du ", rs);

          dataAB.data = rs;
          dataAB.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);
          // console.log("dataAB.paginate ", dataAB.paginate);
          cash("#loadingAB").hide();
        })
        .catch(async (error: any) => {
          cash("#loadingAB").hide();
          // console.log("response get du err ", error.response);
					await gpf.handleEds(error);
        });
    };
    getData();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataAB.page = p;
      getData();
    };

    const nextPage = () => {
      dataAB.page = dataAB.page + 1;
      getData();
    };

    const beforePage = () => {
      dataAB.page = dataAB.page - 1;
      getData();
    };

    const lastPage = () => {
      dataAB.page = dataAB.paginate.totalPages;
      getData();
    };

    return {
      titleTable,
      getData,
      dataAB,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
      role,
    };
  },
  data() {
    return {
			section: "add",

			exDataAB: {
				id: "",
				code: "",
				name: "",
				accountName: "",
				accountNumber: "",
			},

      exDeleteAB: {
        id: "",
        name: "",
      }

		};
  },
  methods: {
		async emptyField() {
			const ex = this.exDataAB;
			ex.id = "0";
			ex.code = "";
			ex.name = "";
			ex.accountName = "";
			ex.accountNumber = "";
		},
		async addAB() {
      await this.emptyField();
      this.section = "add";
			gpf.showModal("#modal-data-ab");
    },
    addABApi() {
      gpf.gLoading.show();
      const ex = this.exDataAB;
      const params = new URLSearchParams();
      params.append("code", ex.code);
      params.append("name", ex.name);
      params.append("accountName", ex.accountName);
      params.append("accountNumber", ex.accountNumber);

      axios
        .post(api.publicAkunBank, params, this.configXwfu)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-ab");
          await this.emptyField();
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    editAB(data: any) {
      this.section = "edit";

      const ex = this.exDataAB;
      ex.id = data.id;
      ex.code = data.code;
      ex.name = data.name;
      ex.accountName = data.accountName;
      ex.accountNumber = data.accountNumber;

      gpf.showModal("#modal-data-ab");
    },
    editABApi(): void {
      gpf.gLoading.show();
      const ex = this.exDataAB;
      const params = new URLSearchParams();
      params.append("code", ex.code);
      params.append("name", ex.name);
      params.append("accountName", ex.accountName);
      params.append("accountNumber", ex.accountNumber);

      axios
        .put(api.publicAkunBank + ex.id, params, this.configXwfu)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-ab");
          await this.emptyField();
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    deleteAB(data: any): void {
      const ex = this.exDeleteAB;
      ex.id = data.id;
      ex.name = data.name;

      gpf.showModal("#delete-modal-data-ab");
    },
    deleteABApi() {
      const ex = this.exDeleteAB;

      gpf.gLoading.show();

      axios
        .delete(api.publicAkunBank + ex.id, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          ex.id = "0";
          ex.name = "";

          await gpf.handleSds(result);
          await gpf.hideModal("#delete-modal-data-ab");
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    }

  },
});
