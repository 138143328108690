
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";

import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";
import axios from "axios";

export default defineComponent({
  setup() {
    const router = useRouter();
    const configGet = api.configGet();

    const dataKP = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
      exDataTopik: {
        topik: "",
        kategori: "",
        content: "",
      },
      listKategori: [],
      msgSuccess: "",
    });

    const titleTable: Array<string> = ["Topik", "Kategori", "Member", "Tanggal", "#"];

    const getData = async () => {
      cash("#loadingKP").show();

      const page = dataKP.page;
      const pageSize = dataKP.pageSize;
      const search = dataKP.search;
      axios
        .get(
          api.adminKelasPrivate +
            "?search=" +
            search +
            "&page=" +
            page +
            "&pageSize=" +
            pageSize
					, configGet
        )
        .then(async (result: any) => {
          const ds = result.data.diagnostic;
          const rs = result.data.response;
          // console.log("response get kp ", rs);

          dataKP.data = rs;
          dataKP.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);
          // console.log("dataKP.paginate ", dataKP.paginate);
          cash("#loadingKP").hide();
        })
        .catch( async(error: any) => {
          // console.log("response get kp err ", error.response);
					await gpf.handleEds(error);
        });
    };
    getData();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataKP.page = p;
      getData();
    };

    const nextPage = () => {
      dataKP.page = dataKP.page + 1;
      getData();
    };

    const beforePage = () => {
      dataKP.page = dataKP.page - 1;
      getData();
    };

    const lastPage = () => {
      dataKP.page = dataKP.paginate.totalPages;
      getData();
    };

    return {
      titleTable,
      dataKP,
      getData,
      router,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
			configGet,
    };
  },
  data() {
    return {
			exDeleteKP: {
				uuid: "",
				topik: ""
			}
		};
  },
  methods: {
    detailKP(data: any) {
      this.$router.push('kelas-private/' + data.uuid)
    },
		deleteKP(data: any): void {
      const ex = this.exDeleteKP;
      ex.uuid = data.uuid;
      ex.topik = data.topik;

      gpf.showModal("#delete-modal-data-kp");
    },
    deleteKPApi() {
      const ex = this.exDeleteKP;

      gpf.gLoading.show();

      axios
        .delete(api.adminKelasPrivate + ex.uuid, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          ex.uuid = "";
          ex.topik = "";

          await gpf.handleSds(result);
          await gpf.hideModal("#delete-modal-data-kp");
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    }
  },
});
