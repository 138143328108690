import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState } from "./state";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_TITLE_PAGE](state: S, activeTitle: string): void;
  [MutationTypes.SET_URL_SUB_MENU](state: S, activeUrlSubMenu: string): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_TITLE_PAGE](state: LocalState, activeTitle: string) {
    state.activeTitle = activeTitle;
  },
  [MutationTypes.SET_URL_SUB_MENU](state: LocalState, activeUrlSubMenu: string) {
    state.activeUrlSubMenu = activeUrlSubMenu;
  },
};
