
import { defineComponent, reactive } from "vue";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";
import { dataStatus } from "./sales";
import axios from "axios";

export default defineComponent({
  setup() {
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();
    const listStatus = dataStatus();

    const titleTable: Array<string> = [
      "Name",
      "Username",
      "Email",
      "Phone Number",
      "Fee Sales",
      "Status",
      "Created",
      "#",
    ];

    const dataAS = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
    });

    const getData = async () => {
      cash("#loadingAS").show();

      const page = dataAS.page;
      const pageSize = dataAS.pageSize;
      const search = dataAS.search;
      axios
        .get(
          api.affSales +
            "?search=" +
            search +
            "&page=" +
            page +
            "&pageSize=" +
            pageSize,
          configGet
        )
        .then(async (result: any) => {
          const ds = result.data.diagnostic;
          const rs = result.data.response;
          // console.log("response get du ", rs);

          dataAS.data = rs;
          dataAS.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);
          // console.log("dataAS.paginate ", dataAS.paginate);
          cash("#loadingAS").hide();
        })
        .catch(async (error: any) => {
          cash("#loadingAS").hide();
          // console.log("response get du err ", error.response);
          await gpf.handleEds(error);
        });
    };
    getData();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataAS.page = p;
      getData();
    };

    const nextPage = () => {
      dataAS.page = dataAS.page + 1;
      getData();
    };

    const beforePage = () => {
      dataAS.page = dataAS.page - 1;
      getData();
    };

    const lastPage = () => {
      dataAS.page = dataAS.paginate.totalPages;
      getData();
    };

    return {
      titleTable,
      getData,
      dataAS,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
      listStatus,
    };
  },
  data() {
    return {
      section: "add",

      exDataAS: {
        id: "",
        nama: "",
        email: "",
        phone: "",
        username: "",
        password: "",
        tokenWa: "",
        feeSales: "",
        status: "1",
      },

      detailDataAS: {
        id: "",
        nama: "",
        email: "",
        phone: "",
        username: "",
        tokenWa: "",
        feeSales: "",
        photo: "",
      },
    };
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.exDataAS;
      ex[f] = val;
    },
    async emptyField() {
      const ex = this.exDataAS;
      ex.id = "0";
      ex.nama = "";
      ex.email = "";
      ex.phone = "";
      ex.username = "";
      ex.password = "";
      ex.tokenWa = "";
      ex.feeSales = "";
    },
    ctc(f: string) {
      const ex: any = this.exDataAS;
      ex[f] = gpf.ctc(ex[f]);
    },
    async addAS() {
      await this.emptyField();
      this.section = "add";
      gpf.showModal("#modal-data-as");
    },
    addASApi() {
      gpf.gLoading.show();
      const ex = this.exDataAS;
      const params = new URLSearchParams();
      params.append("nama", ex.nama);
      params.append("email", ex.email);
      params.append("phone", ex.phone);
      params.append("username", ex.username);
      params.append("password", ex.password);
      params.append("tokenWa", ex.tokenWa);
      params.append("feeSales", gpf.btc(ex.feeSales));

      axios
        .post(api.affSales, params, this.configXwfu)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-as");
          await this.emptyField();
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    editAS(data: any) {
      this.section = "edit";
      gpf.gLoading.show();

      axios
        .get(api.affSales + data.id, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          const rs = result.data.response;
          // console.log("response get deu ", rs);
          const ex = this.exDataAS;
          ex.id = rs.id;
          ex.nama = rs.name;
          ex.email = rs.email;
          ex.phone = rs.phone;
          ex.username = rs.username;
          ex.tokenWa = rs.tokenWa;
          ex.feeSales = gpf.ctc(rs.feeSales);
          ex.status = rs.status === "Active" ? "1" : "0";

          await gpf.showModal("#modal-data-as");
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    editASApi(): void {
      gpf.gLoading.show();
      const ex = this.exDataAS;
      const params = new URLSearchParams();
      params.append("nama", ex.nama);
      params.append("email", ex.email);
      params.append("phone", ex.phone);
      params.append("username", ex.username);
      params.append("password", ex.password);
      params.append("tokenWa", ex.tokenWa);
      params.append("status", ex.status);
      params.append("feeSales", gpf.btc(ex.feeSales));

      axios
        .put(api.affSales + ex.id, params, this.configXwfu)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-as");
          await this.emptyField();
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    detailASApi(data: any): void {
      gpf.gLoading.show();

      axios
        .get(api.affSales + data.id, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          const rs = result.data.response;
          // console.log("response get deu ", rs);

          const ex = this.detailDataAS;
          ex.id = rs.id;
          ex.nama = rs.name;
          ex.email = rs.email;
          ex.phone = rs.phone;
          ex.username = rs.username;
          ex.tokenWa = rs.tokenWa;
          ex.feeSales = rs.feeSales;
          ex.photo = rs.photo;

          await gpf.showModal("#modal-detail-oa");
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
  },
});
