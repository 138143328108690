import { computed, ref } from "vue";
import { VueCookieNext } from "vue-cookie-next";

const role = ref("");

export const setupRoles = (): void => {
  const cookie = VueCookieNext;
  const newRole = cookie.getCookie("role-data");
  console.log("setupRoles newRole ", newRole);
  role.value = newRole;
};

export const isMember = computed(() => role.value === "member");

export const isAdmin = computed(() => role.value === "admin");

export const isAffiliate = computed(() => role.value === "affiliate");

export const isSales = computed(() => role.value === "sales");

export const isOwner = computed(() => role.value === "owner");

export const isTentor = computed(() => role.value === "tentor");
