
import { defineComponent, reactive } from "vue";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";

export default defineComponent({
  setup() {
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();

    const titleTable: Array<string> = [
      "Invoice",
      "Sales",
      "Name",
      "Amount",
      "Created",
    ];

    const dataKomisi = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
    });

    const getData = async () => {
      cash("#loadingDMaff").show();

      const hide = () => cash("#loadingDMaff").hide();
      const page = dataKomisi.page;
      const pageSize = dataKomisi.pageSize;
      const search = dataKomisi.search;
      const url = api.affKomisi +
        "?search=" +
        search +
        "&page=" +
        page +
        "&pageSize=" +
        pageSize;

      const hitApi = await api.hitApiGet(url, hide);
      const ds = hitApi.data.diagnostic;
      const rs = hitApi.data.response;
      // console.log("response get du ", rs);

      dataKomisi.data = rs;
      dataKomisi.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);

    };
    getData();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataKomisi.page = p;
      getData();
    };

    const nextPage = () => {
      dataKomisi.page = dataKomisi.page + 1;
      getData();
    };

    const beforePage = () => {
      dataKomisi.page = dataKomisi.page - 1;
      getData();
    };

    const lastPage = () => {
      dataKomisi.page = dataKomisi.paginate.totalPages;
      getData();
    };

    return {
      titleTable,
      getData,
      dataKomisi,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
    };
  },
  data() {
    return {};
  },
  methods: {
  },
});
