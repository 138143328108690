import { App } from "vue";
import faker from "./faker";
import helper from "./helper";
import lodash from "./lodash";
import gpf from "./global-functions";
import api from "./api-config";

export default (app: App): void => {
  app.use(faker);
  app.use(helper);
  app.use(lodash);
  app.use(gpf);
  app.use(api);
};
