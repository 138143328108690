
import { defineComponent, reactive } from "vue";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";

export default defineComponent({
  setup() {
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();
		const listStatus = gpf.dataStatus();

    const listType = [
      { 
        value: "percent",
        title: "Percent",
      },
      { 
        value: "price",
        title: "Price",
      },
    ];

    const titleTable: Array<string> = [
			"Kode",
      "Discount",
      "Type",
			"Status",
			"#",
    ];

    // "id": 9,
    // "code": "UPGRADE",
    // "discount": 90,
    // "type": "percent",
    // "isActive": true

    const dataAP = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
    });

    const getData = async () => {
      cash("#loadingAP").show();

      const hide = () => cash("#loadingAP").hide();
      const page = dataAP.page;
      const pageSize = dataAP.pageSize;
      const search = dataAP.search;
      const url = api.affPromo +
        "?search=" +
        search +
        "&page=" +
        page +
        "&pageSize=" +
        pageSize;
      const hitApi = await api.hitApiGet(url, hide);
      const ds = hitApi.data.diagnostic;
      const rs = hitApi.data.response;

      dataAP.data = rs;
      dataAP.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);
      
    };
    getData();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataAP.page = p;
      getData();
    };

    const nextPage = () => {
      dataAP.page = dataAP.page + 1;
      getData();
    };

    const beforePage = () => {
      dataAP.page = dataAP.page - 1;
      getData();
    };

    const lastPage = () => {
      dataAP.page = dataAP.paginate.totalPages;
      getData();
    };

    return {
      titleTable,
      getData,
      dataAP,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
			listStatus,
      listType,
    };
  },
  data() {
    return {
			section: "add",

			exDataAP: {
				id: "",
				code: "",
				discount: "",
				type: "percent",
				status: "1",
			},

		};
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.exDataAP;
      ex[f] = val;
    },
		async emptyField() {
			const ex = this.exDataAP;
			ex.id = "0";
			ex.code = "";
			ex.discount = "";
			ex.type = "percent";
			ex.status = "1";
		},
		ctc(f: string) {
      const ex: any = this.exDataAP;
      ex[f] = gpf.ctc(ex[f]);
    },
		async addAP() {
      await this.emptyField();
      this.section = "add";
			gpf.showModal("#modal-data-ap");
    },
    async addAPApi() {
      gpf.gLoading.show();

      const hide = () => gpf.gLoading.hide();
      const ex = this.exDataAP;
      const params = new URLSearchParams();
      params.append("code", ex.code);
      params.append("discount", ex.discount);
      params.append("type", ex.type);
      params.append("status", ex.status);

      const url = api.affPromo;
      const hitApi = await api.hitApiPost(url, params, hide);
      await gpf.handleSds(hitApi);
      await gpf.hideModal("#modal-data-ap");
      await this.emptyField();
      await this.getData();
    },
    async editAP(data: any) {
      this.section = "edit";
      const ex = this.exDataAP;
      ex.id = data.id;
      ex.code = data.code;
      ex.discount = data.discount;
      ex.type = data.type;
      ex.status = data.isActive.toString();

      await gpf.showModal("#modal-data-ap");
    },
    async editAPApi() {
      gpf.gLoading.show();

      const hide = () => gpf.gLoading.hide();
      const ex = this.exDataAP;
      const params = new URLSearchParams();
      params.append("code", ex.code);
      params.append("discount", ex.discount);
      params.append("type", ex.type);
      params.append("status", ex.status);

      const url = api.affPromo + ex.code;
      const hitApi = await api.hitApiPut(url, params, hide);
      await gpf.handleSds(hitApi);
      await gpf.hideModal("#modal-data-ap");
      await this.emptyField();
      await this.getData();
    },

  },
});
