type tv = {
  title: string,
  value: string,
};

const dataStatus = (): Array<tv> => {
  const obj: Array<tv> = [
    {
      title: "Suspend", value: "0",
    },
    {
      title: "Active", value: "1",
    },
  ];
  return obj;
}

export { dataStatus };
