interface soal {
  kode: string;
  type: string;
  startDate: string;
  endDate: string;
  duration: number;
  soal: Array<any>;
}

interface typeDE {
  diagnostic: any;
  response: soal;
}

const resultExam: typeDE = {
  diagnostic: {
    code: 200,
    status: "success",
    message: "Get soal skd",
  },
  response: {
    kode: "20122606IFCX",
    type: "skd",
    startDate: "2020-12-26T14:39:51+08:00",
    endDate: "2020-12-26T08:09:51.595Z",
    duration: 90,
    soal: [
      {
        id: 56,
        nomor: 1,
        kategori: "twk",
        bidang: "Bahasa Indonesia",
        question:
          "\r\nPancasila sebagai dasar negara secara yuridis konstitusional tercantum dalam pembukaan UUD 1945, merupakan …",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Kepribadian Bangsa",
            img: "",
          },
          {
            option: "B",
            text: "Cita-cita Bangsa",
            img: "",
          },
          {
            option: "C",
            text: "Pemersatu Bangsa",
            img: "",
          },
          {
            option: "D",
            text: "Ideologi Terbuka",
            img: "",
          },
          {
            option: "E",
            text: "Sumber dari segala sumber hukum",
            img: "",
          },
        ],
        answer: "E",
        answerIdx: 4,
        pembahasan:
          "Pancasila sebagai dasar negara secara yuridis konstitusional tercantum dalam pembukaan UUD 1945, merupakan sumber dari segala sumber hukum.",
        answerKey: "E",
        answerValue: "Sumber dari segala sumber hukum",
        point: 5,
      },
      {
        id: 9,
        nomor: 2,
        kategori: "twk",
        bidang: "Nasionalisme",
        question:
          "Proses terjadinya stratifikasi sosial di Indonesia antara lain akibat adanya perbedaan ...",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Adat istiadat dan budaya",
            img: "",
          },
          {
            option: "B",
            text: "Kepercayaan dan religi",
            img: "",
          },
          {
            option: "C",
            text: "Kebiasaan dan kegemaran",
            img: "",
          },
          {
            option: "D",
            text: "Peradaban dan adat",
            img: "",
          },
          {
            option: "E",
            text: "Pendidikan dan kekayaan",
            img: "",
          },
        ],
      },
      {
        id: 6,
        nomor: 3,
        kategori: "twk",
        bidang: "Nasionalisme",
        question:
          "Mewujudkan nasionalisme yang tinggi dari segala aspek kehidupan rakyat Indonesia yang mengutamakan kepentingan nasional dari pada kepentingan perorangan, kelompok, golongan, suku bangsa atau daerah merupakan tujuan dari ...\r\n",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Pembangunan nasional",
            img: "",
          },
          {
            option: "B",
            text: "Persatuan Indonesia",
            img: "",
          },
          {
            option: "C",
            text: "Wawasan kebangsaan",
            img: "",
          },
          {
            option: "D",
            text: "Wawasan nusantara",
            img: "",
          },
          {
            option: "E",
            text: "Tujuan nasional",
            img: "",
          },
        ],
      },
      {
        id: 5,
        nomor: 4,
        kategori: "twk",
        bidang: "Nasionalisme",
        question:
          "Pancasila memiliki ideologi terbuka, sehingga dalam strukturnya Pancasila memiliki beberapa dimensi. Yang termasuk dimensi yang dimiliki Pancasila tersebut adalah ...",
        questionImg: "",
        option: [
          {
            option: "A",
            text: ". Dimensi waktu, dimensi normatif, dimensi ruang",
            img: "",
          },
          {
            option: "B",
            text: "Dimensi realitas, dimensi waktu, dimensi normatif",
            img: "",
          },
          {
            option: "C",
            text: "Dimensi idealis, dimensi realitas, dimensi ruang",
            img: "",
          },
          {
            option: "D",
            text: "Dimensi realitas, dimensi idealis, dimensi normatif",
            img: "",
          },
          {
            option: "E",
            text: "Dimensi normatif, dimensi idealis, dimensi ruang",
            img: "",
          },
        ],
      },
      {
        id: 67,
        nomor: 5,
        kategori: "twk",
        bidang: "Nasionalisme",
        question: " ",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Ki  Hajar Dewantara",
            img: "",
          },
          {
            option: "B",
            text: " M A Pellaupessy",
            img: "",
          },
          {
            option: "C",
            text: "Moh. Natsir",
            img: "",
          },
          {
            option: "D",
            text: "A.  RM Ng Poerbatjaraka",
            img: "",
          },
          {
            option: "E",
            text: "moh. Hatta",
            img: "",
          },
        ],
      },
      {
        id: 7,
        nomor: 6,
        kategori: "twk",
        bidang: "Nasionalisme",
        question:
          "Indonesia bisa memilih untuk berhubungan dengan negara manapun di dunia, tanpa membedakan sistem pemerintahan dan ideologinya. Ini merupakan konsekuensi dari salah satu prinsip dalam politik luar negeri Indonesia, yaitu ..\r\n",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Aktif ",
            img: "",
          },
          {
            option: "B",
            text: "Setara ",
            img: "",
          },
          {
            option: "C",
            text: "Bebas",
            img: "",
          },
          {
            option: "D",
            text: "Adil",
            img: "",
          },
          {
            option: "E",
            text: "Seimbang",
            img: "",
          },
        ],
      },
      {
        id: 10,
        nomor: 7,
        kategori: "twk",
        bidang: "Nasionalisme",
        question:
          "Sikap saling menghargai sangat diperlukan untuk Negara Indonesia yang penuh dengan keanekaragaman budaya.\r\nSalah satu sikap yang dapat memecah keutuhan bangsa adalah.....\r\n \r\n",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Chauvinisme",
            img: "",
          },
          {
            option: "B",
            text: "Toleransi",
            img: "",
          },
          {
            option: "C",
            text: "Primodialisme",
            img: "",
          },
          {
            option: "D",
            text: "Ekstrimisme",
            img: "",
          },
          {
            option: "E",
            text: "Xenosentrisme",
            img: "",
          },
        ],
      },
      {
        id: 15,
        nomor: 8,
        kategori: "twk",
        bidang: "Integritas",
        question:
          "Andi adalah bagian dari generasi milenial yang gemar menggunakan internet untuk\r\nmengisi waktu. Suatu hari Andi melihat sebuah berita yang menarik perhatiannya di\r\nFacebook dan langsung membagikannya di linimasa facebook miliknya. Setelah\r\nbeberapa waktu, salah satu sahabat Andi mengirim pesan dan menyampaikan bahwa\r\nberita yang dipost oleh Andi adalah hoax dan dapat menyulut perpecahan dan Dia\r\nterancam UU ITE. Tindakan yang dilakukan Andi bertentangan dengan pancasila\r\nkhususnya sila ke...\r\n",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Pertama",
            img: "",
          },
          {
            option: "B",
            text: "Kedua",
            img: "",
          },
          {
            option: "C",
            text: "Ketiga",
            img: "",
          },
          {
            option: "D",
            text: "Keempat",
            img: "",
          },
          {
            option: "E",
            text: "Kelima ",
            img: "",
          },
        ],
      },
      {
        id: 11,
        nomor: 9,
        kategori: "twk",
        bidang: "Integritas",
        question:
          "Pancasila yang memiliki ideologi terbuka memiliki beberapa dimensi. Salah satunya di antaranya adalah dimensi idealitas. Maksud dari Pancasila sebagai dimensi idealitas adalah ...",
        questionImg: "",
        option: [
          {
            option: "A",
            text:
              "Nilai-nilai yang terkandung dalam Pancasila bersifat sistematis dan rasional",
            img: "",
          },
          {
            option: "B",
            text:
              "Nilai-nilai yang terkandung dalam Pancasila merupakan nilai yang mendasar dalam perkembangan zaman",
            img: "",
          },
          {
            option: "C",
            text:
              "Nilai-nilai yang terkandung dalam Pancasila perlu penjabaran dalam sebuah sistem normatif",
            img: "",
          },
          {
            option: "D",
            text:
              "Nilai-nilai yang terkandung dalam Pancasila merupakan cita-cita dan harapan yang hendak diwujudkan.",
            img: "",
          },
          {
            option: "E",
            text:
              "Nilai-nilai yang terkandung dalam Pancasila mencerminkan realitas yang sedang berkembang di masyarakat",
            img: "",
          },
        ],
      },
      {
        id: 16,
        nomor: 10,
        kategori: "twk",
        bidang: "Integritas",
        question:
          "politik atas setiap kebijakan yang diambil oleh penguasa. Pernyataan tersebut merupakan pengertian dari konsepsi ideologi ...",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Ideologi secara total",
            img: "",
          },
          {
            option: "B",
            text: "Ideologi fungsional",
            img: "",
          },
          {
            option: "C",
            text: "Ideologi tertutup",
            img: "",
          },
          {
            option: "D",
            text: "Ideologi partikular",
            img: "",
          },
          {
            option: "E",
            text: "Ideologi struktural",
            img: "",
          },
        ],
      },
      {
        id: 68,
        nomor: 11,
        kategori: "twk",
        bidang: "Integritas",
        question:
          "\r\nHak kewarganegaan yang diperoleh seseorang (individu) berdasarkan kewarganegaraan ayah dan ibu biologisnya disebut …",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Kelima ",
            img: "",
          },
          {
            option: "B",
            text: "Keempat",
            img: "",
          },
          {
            option: "C",
            text: "Ketiga ",
            img: "",
          },
          {
            option: "D",
            text: "Kedua ",
            img: "",
          },
          {
            option: "E",
            text: "pertama ",
            img: "",
          },
        ],
      },
      {
        id: 20,
        nomor: 12,
        kategori: "twk",
        bidang: "Integritas",
        question:
          "Komisi DPR yang membidangi urusan kependudukan dan kesehatan adalah ...",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "VII",
            img: "",
          },
          {
            option: "B",
            text: "VIII",
            img: "",
          },
          {
            option: "C",
            text: "IX",
            img: "",
          },
          {
            option: "D",
            text: "X",
            img: "",
          },
          {
            option: "E",
            text: "XI",
            img: "",
          },
        ],
      },
      {
        id: 12,
        nomor: 13,
        kategori: "twk",
        bidang: "Integritas",
        question:
          "Bentuk negara Indonesia sebagai NKRI diatur pada Pasal .... UUD 1945",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Pasal 1 ayat (1)",
            img: "",
          },
          {
            option: "B",
            text: "Pasal 1 ayat (2)",
            img: "",
          },
          {
            option: "C",
            text: "Pasal 1 ayat (3)",
            img: "",
          },
          {
            option: "D",
            text: "Pasal 2 ayat (1)",
            img: "",
          },
          {
            option: "E",
            text: "Pasal 2 ayat (2)",
            img: "",
          },
        ],
      },
      {
        id: 19,
        nomor: 14,
        kategori: "twk",
        bidang: "Integritas",
        question:
          "Dalam pasal 5 ayat (1) UUD 1945, presiden berhak mengajukan rancangan undang-undang kepada ...",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Presiden",
            img: "",
          },
          {
            option: "B",
            text: "MA",
            img: "",
          },
          {
            option: "C",
            text: "DPR",
            img: "",
          },
          {
            option: "D",
            text: "MK",
            img: "",
          },
          {
            option: "E",
            text: "BPK",
            img: "",
          },
        ],
      },
      {
        id: 30,
        nomor: 15,
        kategori: "twk",
        bidang: "Bela Negara",
        question:
          "Saat ini, tegas Abisai, di tanah Papua dan khususnya Papua Barat dan khususnya Kota Jayapura tidak ada bendera yang lain, hanya satu bendera, yaitu Bendera Merah putih.",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Non Blok",
            img: "",
          },
          {
            option: "B",
            text: "NATO",
            img: "",
          },
          {
            option: "C",
            text: "G20",
            img: "",
          },
          {
            option: "D",
            text: "G8",
            img: "",
          },
          {
            option: "E",
            text: "PBB",
            img: "",
          },
        ],
      },
      {
        id: 25,
        nomor: 16,
        kategori: "twk",
        bidang: "Bela Negara",
        question: "",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Tidak Langsung",
            img: "",
          },
          {
            option: "B",
            text: "Langsung",
            img: "",
          },
          {
            option: "C",
            text: "Referendum",
            img: "",
          },
          {
            option: "D",
            text: "Liberal",
            img: "",
          },
          {
            option: "E",
            text: "Parlementer",
            img: "",
          },
        ],
      },
      {
        id: 64,
        nomor: 17,
        kategori: "twk",
        bidang: "Bela Negara",
        question:
          "\r\nPancasila yang memiliki ideologi terbuka memiliki beberapa dimensi. Salah satunya di antaranya adalah dimensi idealitas. Maksud dari Pancasila sebagai dimensi idealitas adalah ...",
        questionImg: "",
        option: [
          {
            option: "A",
            text:
              "Bersama surat ini kami mengundang Saudara untuk menghadiri rapat pada",
            img: "",
          },
          {
            option: "B",
            text:
              " Dengan surat ini kami mengundang Saudara untuk menghadiri rapat pada",
            img: "",
          },
          {
            option: "C",
            text: "Kami mengundang Saudara untuk menghadiri rapat pada",
            img: "",
          },
          {
            option: "D",
            text: " Kami mengundang Saudara hadir untuk rapat",
            img: "",
          },
          {
            option: "E",
            text: "Saya mengundang Saudara untuk menghadiri rapat pada",
            img: "",
          },
        ],
      },
      {
        id: 28,
        nomor: 18,
        kategori: "twk",
        bidang: "Bela Negara",
        question:
          "“Semua supaya tidak ada lagi itu yang bikin kacau di Papua yang adalah bagian dari Negara Kesatuan Republik Indonesia (NKRI). Negara Kesatuan Republik Indonesia harga mati buat Papua,” tegas Abisai.",
        questionImg: "",
        option: [
          {
            option: "A",
            text: " Pengabdian tanpa batas",
            img: "",
          },
          {
            option: "B",
            text: " kewajiban mutlak",
            img: "",
          },
          {
            option: "C",
            text: "pengabdian sesuai profesi",
            img: "",
          },
          {
            option: "D",
            text: " kewajiban sesuai profesi",
            img: "",
          },
          {
            option: "E",
            text: "kesukarelaan sesuai profesi",
            img: "",
          },
        ],
      },
      {
        id: 69,
        nomor: 19,
        kategori: "twk",
        bidang: "Bela Negara",
        question:
          "\r\nAndi adalah bagian dari generasi milenial yang gemar menggunakan internet untuk",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Ketuhanan ",
            img: "",
          },
          {
            option: "B",
            text: "Kemanusiaan ",
            img: "",
          },
          {
            option: "C",
            text: "Persatuan ",
            img: "",
          },
          {
            option: "D",
            text: "Demokrasi ",
            img: "",
          },
          {
            option: "E",
            text: "Keadilan Sosial ",
            img: "",
          },
        ],
      },
      {
        id: 24,
        nomor: 20,
        kategori: "twk",
        bidang: "Bela Negara",
        question:
          "Salah satu yang disampaikan Abisai adalah kesediaan Presiden Jokowi untuk membangun Istana Presiden Republik di Kota Jayapura, ibu kota Provinsi Papua. Dengan dibangunnya Istana Presiden itu, menurut Abisai, telah merubah yang tadinya beliau (Presiden) berkunjung ke Papua, diubah menjadi berkantor di Papua.",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "UUD 1945- TAP MPR- UU – PERPU-PP-PERDA- Kepres",
            img: "",
          },
          {
            option: "B",
            text: "UUD 1945- TAP MPR- UU – PERPU- PP-Kepres- PERDA",
            img: "",
          },
          {
            option: "C",
            text: "1945-  TAP  MPR-  UU  –  Kepres-  - PP- PERDA",
            img: "",
          },
          {
            option: "D",
            text: "TAP MPR- UUD 1945 – PERPU-PP-Kepres-PERDA",
            img: "",
          },
          {
            option: "E",
            text: "UUD 1945- UU – PERPU- TAP MPR-PP-Kepres- PERDA",
            img: "",
          },
        ],
      },
      {
        id: 26,
        nomor: 21,
        kategori: "twk",
        bidang: "Bela Negara",
        question:
          "Ketika Presiden berkantor di Papua, Abisai yakin Presiden akan melihat Papua secara utuh, sehingga (kalau) ada kelompok-kelompok yang belum dijamah dengan baik semua akan diusahakan untuk dijamah.",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "regelaarsrecht",
            img: "",
          },
          {
            option: "B",
            text: "justitierecht",
            img: "",
          },
          {
            option: "C",
            text: "huwelijksrecht",
            img: "",
          },
          {
            option: "D",
            text: "politierecht",
            img: "",
          },
          {
            option: "E",
            text: "bestuursrecht",
            img: "",
          },
        ],
      },
      {
        id: 70,
        nomor: 22,
        kategori: "twk",
        bidang: "Pilar Negara",
        question:
          "mengisi waktu. Suatu hari Andi melihat sebuah berita yang menarik perhatiannya di",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Apresiasi",
            img: "",
          },
          {
            option: "B",
            text: "Inflasi",
            img: "",
          },
          {
            option: "C",
            text: "Depresiasi",
            img: "",
          },
          {
            option: "D",
            text: "Devaluasi",
            img: "",
          },
          {
            option: "E",
            text: "Perubahan kurs nasional",
            img: "",
          },
        ],
      },
      {
        id: 31,
        nomor: 23,
        kategori: "twk",
        bidang: "Pilar Negara",
        question: "",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Pendidikan kewanegaraan",
            img: "",
          },
          {
            option: "B",
            text: "Pelatihan dasar kemiliteran secara wajib",
            img: "",
          },
          {
            option: "C",
            text: "Pengabdian sebagai TNI secara sukarela atau wajib",
            img: "",
          },
          {
            option: "D",
            text: "Pengabdian sesuai profesi",
            img: "",
          },
          {
            option: "E",
            text: "Cinta tanah air",
            img: "",
          },
        ],
      },
      {
        id: 32,
        nomor: 24,
        kategori: "twk",
        bidang: "Pilar Negara",
        question:
          "Bendera Merah Putih itu dikibarkan dari ombak putih-putih sampai gunung biru-biru, tidak ada bendera yang lain, hanya satu bendera, Bendera Sang Saka Merah putih, bendera kita disebut\r\n",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Partisipasi dan peranannya",
            img: "",
          },
          {
            option: "B",
            text: "Dapat didayagunakan dan dioptimalkan",
            img: "",
          },
          {
            option: "C",
            text: "Hasil pembangunan yang telah dicapai",
            img: "",
          },
          {
            option: "D",
            text: "Hasil pembangunan dapat ditingkatkan",
            img: "",
          },
          {
            option: "E",
            text: "Pelaksanaan pembangunan sesuai dengan kapasitas",
            img: "",
          },
        ],
      },
      {
        id: 40,
        nomor: 25,
        kategori: "twk",
        bidang: "Pilar Negara",
        question:
          "Kelompok Negara-negara maju yang megadakan pertemuan ekonomi dan politik yang dihadiri oleh pejabat dan kepala Negara lain adalah…",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Normatif",
            img: "",
          },
          {
            option: "B",
            text: "Subjektif",
            img: "",
          },
          {
            option: "C",
            text: "Objektif",
            img: "",
          },
          {
            option: "D",
            text: "Semantik",
            img: "",
          },
          {
            option: "E",
            text: "Nominal",
            img: "",
          },
        ],
      },
      {
        id: 34,
        nomor: 26,
        kategori: "twk",
        bidang: "Pilar Negara",
        question:
          "Tata urutan perundang-undangan yang benar menurut TAP MPR No. III Tahun 2000 adalah …",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Dia tidak mengakui perbuatannya yang salah.",
            img: "",
          },
          {
            option: "B",
            text: "Kami datang menjelang pesta dimulai.",
            img: "",
          },
          {
            option: "C",
            text: "Penduduk desa itu banyak yang merotan.",
            img: "",
          },
          {
            option: "D",
            text: "Kami mengontrak rumah di Pulau Mas.",
            img: "",
          },
          {
            option: "E",
            text: "Ia sering lupa mengunci pintu depan ",
            img: "",
          },
        ],
      },
      {
        id: 65,
        nomor: 27,
        kategori: "twk",
        bidang: "Pilar Negara",
        question:
          "\r\nBentuk negara Indonesia sebagai NKRI diatur pada Pasal .... UUD 1945\r\nJika seorang hakim menghukum tegas pada rakyat kecil yang melanggar hukum namun justru membebaskan seorang pejabat tinggi negara yang melanggar hukum, berarti hakim tersebut telah melanggar kepastian hukum yang adil, serta tidak memberikan perlakuan sama di depan hukum. Hal ini bertentangan dengan.....",
        questionImg: "",
        option: [
          {
            option: "A",
            text:
              "Kapan para mahasiswa IPB mengadakan penelitian daging ayam yang mengandung zatkarsiogenik",
            img: "",
          },
          {
            option: "B",
            text:
              "Berapa orang mahasiswa yang melakukan penelitian terhadap daging ayam?",
            img: "",
          },
          {
            option: "C",
            text:
              "Apa tindak lanjut hasil penelitian mahasiswa IPB terhadap daging ayam?",
            img: "",
          },
          {
            option: "D",
            text:
              "Apa hasil penelitian mahasiswa IPB terhadap daging ayam yang dijual di pasar tersebut?",
            img: "",
          },
          {
            option: "E",
            text:
              "Mengapa para mahasiswa IPB melakukan penelitian terhadap daging ayam yang di jual pasar itu?",
            img: "",
          },
        ],
      },
      {
        id: 38,
        nomor: 28,
        kategori: "twk",
        bidang: "Pilar Negara",
        question:
          "Petani dan nelayan bekerja untuk menyediakan sumber makanan, tenaga medis merawat sampai mengupayakan kesembuhan terhadap penyakit, sementara TNI untuk pertahanan dan Kepolisian yang menjaga keamanan dan ketertiban. Di sisi lain ada guru-guru yang mencerdaskan generasi, dan begitu untuk hal-hal lain. Semua itu adalah upaya bela negara yang berlatarbelakang …..\r\n",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Dasar Negara",
            img: "",
          },
          {
            option: "B",
            text: "Ideologi bangsa",
            img: "",
          },
          {
            option: "C",
            text: "Cita-cita dan tujuan bangsa Indonesia",
            img: "",
          },
          {
            option: "D",
            text: "Way of life",
            img: "",
          },
          {
            option: "E",
            text: "Kepribadian Bangsa Indonesia",
            img: "",
          },
        ],
      },
      {
        id: 66,
        nomor: 29,
        kategori: "twk",
        bidang: "Bahasa Indonesia",
        question: "",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Deduktif",
            img: "",
          },
          {
            option: "B",
            text: "Induktif",
            img: "",
          },
          {
            option: "C",
            text: "Deduktif-Induktif",
            img: "",
          },
          {
            option: "D",
            text: "Sebab-akibat",
            img: "",
          },
          {
            option: "E",
            text: "Generalisasi",
            img: "",
          },
        ],
      },
      {
        id: 45,
        nomor: 30,
        kategori: "twk",
        bidang: "Bahasa Indonesia",
        question:
          "Setelah dilakukan penelusuran oleh Muhammad Khairil, salah seorang relawan Masyarakat Anti Fitnah Indonesia (Mafindo), diketahui informasi tersebut tidak benar. Narasi posting-an dengan peristiwa sebenarnya tidak memiliki hubungan atau sangkutan sama sekali.",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Teori perjanjian",
            img: "",
          },
          {
            option: "B",
            text: "Teori pemerintahan",
            img: "",
          },
          {
            option: "C",
            text: "Teori politik",
            img: "",
          },
          {
            option: "D",
            text: "Teori yuridis",
            img: "",
          },
          {
            option: "E",
            text: "Teori kekuasaan",
            img: "",
          },
        ],
      },
      {
        id: 54,
        nomor: 31,
        kategori: "twk",
        bidang: "Bahasa Indonesia",
        question: 'Pembentukan konstitusi di Indonesia, menggunakan cara."',
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Kekuasaan kepala negara tak terbatas",
            img: "",
          },
          {
            option: "B",
            text: "Kekuasaan kepala negara perlu dibatasi",
            img: "",
          },
          {
            option: "C",
            text: " Kekuasaan kepala negara sangat terbatas",
            img: "",
          },
          {
            option: "D",
            text: "Kekuasaan kepala negara seumur hidup",
            img: "",
          },
          {
            option: "E",
            text: "Kekuasaan kepala negara tidak tak terbatas.",
            img: "",
          },
        ],
      },
      {
        id: 62,
        nomor: 32,
        kategori: "twk",
        bidang: "Nasionalisme",
        question:
          "Salah satu sikap yang dapat memecah keutuhan bangsa adalah.....",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Senang berbagi terhadap sesama",
            img: "",
          },
          {
            option: "B",
            text: "Beribadah sesuai dengan keyakinan masing masing",
            img: "",
          },
          {
            option: "C",
            text: "Tidak ikut tawuran antar pelajar",
            img: "",
          },
          {
            option: "D",
            text: "Persamaan atas hak dimata hukum",
            img: "",
          },
          {
            option: "E",
            text: "Tidak mengambil sesuatu yang bukan hak",
            img: "",
          },
        ],
      },
      {
        id: 60,
        nomor: 33,
        kategori: "twk",
        bidang: "Bahasa Indonesia",
        question:
          "\r\nProses terjadinya stratifikasi sosial di Indonesia antara lain akibat adanya perbedaan ...",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "DPR dan MPR",
            img: "",
          },
          {
            option: "B",
            text: "DPA dan BPK",
            img: "",
          },
          {
            option: "C",
            text: "Presiden",
            img: "",
          },
          {
            option: "D",
            text: "DPR",
            img: "",
          },
          {
            option: "E",
            text: "MPR",
            img: "",
          },
        ],
      },
      {
        id: 48,
        nomor: 34,
        kategori: "twk",
        bidang: "Bahasa Indonesia",
        question: "",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Memahami  kandungan pancasila",
            img: "",
          },
          {
            option: "B",
            text: "Pancasila harga mati dalam pengamalan sehari-hari",
            img: "",
          },
          {
            option: "C",
            text: "Implementasi nilai-nilai Pancasila",
            img: "",
          },
          {
            option: "D",
            text:
              "Mempertebal jiwa nasionalisme serta bangga menjadi bangsa Indonesia berdasarkan Pancasila dan UUD 1945",
            img: "",
          },
          {
            option: "E",
            text: "Menghargai ajaran leluhur",
            img: "",
          },
        ],
      },
      {
        id: 52,
        nomor: 35,
        kategori: "twk",
        bidang: "Bahasa Indonesia",
        question:
          "Kata berawalan me – yang tidak menyatakan kerja terdapat dalam kalimat ...",
        questionImg: "",
        option: [
          {
            option: "A",
            text: "Timbulnya golongan buruh dan pengusaha",
            img: "",
          },
          {
            option: "B",
            text: "Masyarakat Indonesia mulai mengenal sistem uang",
            img: "",
          },
          {
            option: "C",
            text: "Tumbuhnya golongan terpelajar",
            img: "",
          },
          {
            option: "D",
            text: "Terjadinya pengambilalihan tanah pedesaan",
            img: "",
          },
          {
            option: "E",
            text: "Taraf hidup dan tingkat sosial ekonomi masyarakat meningkat",
            img: "",
          },
        ],
      },
      {
        id: 71,
        nomor: 36,
        kategori: "tiu",
        bidang: "Verbal",
        question: "Mudun = …",
        questionImg: "",
        option: [],
      },
      {
        id: 80,
        nomor: 37,
        kategori: "tiu",
        bidang: "Verbal",
        question: "KEUNTUNGAN : PENJUALAN = …",
        questionImg: "",
        option: [],
      },
      {
        id: 77,
        nomor: 38,
        kategori: "tiu",
        bidang: "Verbal",
        question: "RATA : MULUS = …",
        questionImg: "",
        option: [],
      },
      {
        id: 75,
        nomor: 39,
        kategori: "tiu",
        bidang: "Verbal",
        question: "CACAT >< …",
        questionImg: "",
        option: [],
      },
      {
        id: 78,
        nomor: 40,
        kategori: "tiu",
        bidang: "Verbal",
        question: "HEMPASAN : ABRASI = …",
        questionImg: "",
        option: [],
      },
      {
        id: 76,
        nomor: 41,
        kategori: "tiu",
        bidang: "Verbal",
        question: "INDIVIDUAL >< …",
        questionImg: "",
        option: [],
      },
      {
        id: 74,
        nomor: 42,
        kategori: "tiu",
        bidang: "Verbal",
        question: "TUA >< …",
        questionImg: "",
        option: [],
      },
      {
        id: 72,
        nomor: 43,
        kategori: "tiu",
        bidang: "Verbal",
        question: "Futuristis = …",
        questionImg: "",
        option: [],
      },
      {
        id: 156,
        nomor: 44,
        kategori: "tiu",
        bidang: "Verbal",
        question:
          "Malioboro -  Candi Borobudur – Candi Prambanan – Parangtritis -  Alun alun – Keraton.",
        questionImg: null,
        option: [],
      },
      {
        id: 159,
        nomor: 45,
        kategori: "tiu",
        bidang: "Verbal",
        question: "RAPUH ><",
        questionImg: "",
        option: [],
      },
      {
        id: 87,
        nomor: 46,
        kategori: "tiu",
        bidang: "Numerik",
        question: "6, 4, 9, 7, 12, 10, 15, ...",
        questionImg: "",
        option: [],
      },
      {
        id: 157,
        nomor: 47,
        kategori: "tiu",
        bidang: "Numerik",
        question:
          "Jelas bahwa yang dikunjungi ketiga adalah candi prambanan (Jawaban C)",
        questionImg: null,
        option: [],
      },
      {
        id: 90,
        nomor: 48,
        kategori: "tiu",
        bidang: "Numerik",
        question: "anggota 7 orang. Jika setiap belajar",
        questionImg: null,
        option: [],
      },
      {
        id: 84,
        nomor: 49,
        kategori: "tiu",
        bidang: "Numerik",
        question: "(2) dan (1) adalah N, M, sehingga",
        questionImg: null,
        option: [],
      },
      {
        id: 85,
        nomor: 50,
        kategori: "tiu",
        bidang: "Numerik",
        question: "jawabannya M, N (ingat sebelumnya",
        questionImg: null,
        option: [],
      },
      {
        id: 83,
        nomor: 51,
        kategori: "tiu",
        bidang: "Numerik",
        question: "bergantian. Oleh karena itu, urutan huruf",
        questionImg: null,
        option: [],
      },
      {
        id: 89,
        nomor: 52,
        kategori: "tiu",
        bidang: "Numerik",
        question: "Suatu kelompok belajar mempunyai",
        questionImg: null,
        option: [],
      },
      {
        id: 82,
        nomor: 53,
        kategori: "tiu",
        bidang: "Numerik",
        question: "abjad dengan dua huruf dibalik",
        questionImg: null,
        option: [],
      },
      {
        id: 81,
        nomor: 54,
        kategori: "tiu",
        bidang: "Numerik",
        question: "X W U V T S Q R P O ...",
        questionImg: "",
        option: [],
      },
      {
        id: 86,
        nomor: 55,
        kategori: "tiu",
        bidang: "Numerik",
        question: "susunan telah dibalik). (A)",
        questionImg: "",
        option: [],
      },
      {
        id: 92,
        nomor: 56,
        kategori: "tiu",
        bidang: "Figural",
        question: "posisi melingkar, banyaknya cara untuk",
        questionImg: null,
        option: [],
      },
      {
        id: 100,
        nomor: 57,
        kategori: "tiu",
        bidang: "Figural",
        question: "berwarna hijau, 35 diantaranya adalah",
        questionImg: null,
        option: [],
      },
      {
        id: 91,
        nomor: 58,
        kategori: "tiu",
        bidang: "Figural",
        question: "semua anggota kelompok duduk dengan",
        questionImg: null,
        option: [],
      },
      {
        id: 95,
        nomor: 59,
        kategori: "tiu",
        bidang: "Figural",
        question: "= 6.5.4.3.2.1",
        questionImg: null,
        option: [],
      },
      {
        id: 158,
        nomor: 60,
        kategori: "tiu",
        bidang: "Figural",
        question: "",
        questionImg: "",
        option: [],
      },
      {
        id: 98,
        nomor: 61,
        kategori: "tiu",
        bidang: "Figural",
        question: "kelereng yang terdiri dari kelereng kecil",
        questionImg: null,
        option: [],
      },
      {
        id: 161,
        nomor: 62,
        kategori: "tiu",
        bidang: "Figural",
        question: "(tentang tubuh); (3) tidak teguh(tidak",
        questionImg: null,
        option: [],
      },
      {
        id: 99,
        nomor: 63,
        kategori: "tiu",
        bidang: "Figural",
        question: "dan kelereng besar. Ada 130 kelereng",
        questionImg: null,
        option: [],
      },
      {
        id: 94,
        nomor: 64,
        kategori: "tiu",
        bidang: "Figural",
        question: "tersebut adalah …",
        questionImg: "",
        option: [],
      },
      {
        id: 93,
        nomor: 65,
        kategori: "tiu",
        bidang: "Figural",
        question: "mengatur posisi duduk anggota kelompok",
        questionImg: null,
        option: [],
      },
      {
        id: 103,
        nomor: 66,
        kategori: "tkp",
        bidang: "Pelayanan Publik",
        question: "Berapakah jumlah kelereng kecil yang",
        questionImg: null,
        option: [],
      },
      {
        id: 105,
        nomor: 67,
        kategori: "tkp",
        bidang: "Pelayanan Publik",
        question: "Kelereng kecil hijau = 130 – 35 = 95",
        questionImg: null,
        option: [],
      },
      {
        id: 104,
        nomor: 68,
        kategori: "tkp",
        bidang: "Pelayanan Publik",
        question: "tidak berwarna hijau?",
        questionImg: "",
        option: [],
      },
      {
        id: 101,
        nomor: 69,
        kategori: "tkp",
        bidang: "Pelayanan Publik",
        question: "kelereng besar. Jumlah kelereng besar",
        questionImg: null,
        option: [],
      },
      {
        id: 107,
        nomor: 70,
        kategori: "tkp",
        bidang: "Pelayanan Publik",
        question: "– 95 = 30.",
        questionImg: "",
        option: [],
      },
      {
        id: 102,
        nomor: 71,
        kategori: "tkp",
        bidang: "Pelayanan Publik",
        question: "dalam kotak tersebut ada 75 buah.",
        questionImg: null,
        option: [],
      },
      {
        id: 106,
        nomor: 72,
        kategori: "tkp",
        bidang: "Pelayanan Publik",
        question: "Kelereng kecil tidak berwarna hijau = 125",
        questionImg: null,
        option: [],
      },
      {
        id: 113,
        nomor: 73,
        kategori: "tkp",
        bidang: "Jejaring Kerja",
        question: "Jika {x = 0,178 + 6,017 + 5,278925} dan",
        questionImg: null,
        option: [],
      },
      {
        id: 110,
        nomor: 74,
        kategori: "tkp",
        bidang: "Jejaring Kerja",
        question: "S, perbandingan isi tabung P terhadap isi",
        questionImg: null,
        option: [],
      },
      {
        id: 108,
        nomor: 75,
        kategori: "tkp",
        bidang: "Jejaring Kerja",
        question: "Jika tabung P tingginya dua kali tinggi",
        questionImg: null,
        option: [],
      },
      {
        id: 111,
        nomor: 76,
        kategori: "tkp",
        bidang: "Jejaring Kerja",
        question: "tabung S adalah ...",
        questionImg: null,
        option: [],
      },
      {
        id: 114,
        nomor: 77,
        kategori: "tkp",
        bidang: "Jejaring Kerja",
        question: "{y = 12}, manakah pernyataan yang",
        questionImg: null,
        option: [],
      },
      {
        id: 109,
        nomor: 78,
        kategori: "tkp",
        bidang: "Jejaring Kerja",
        question: "tabung S dan jari-jarinya setengah tabung",
        questionImg: null,
        option: [],
      },
      {
        id: 112,
        nomor: 79,
        kategori: "tkp",
        bidang: "Jejaring Kerja",
        question: "",
        questionImg: "",
        option: [],
      },
      {
        id: 117,
        nomor: 80,
        kategori: "tkp",
        bidang: "Sosial Budaya",
        question: "Jadi, x < y",
        questionImg: "",
        option: [],
      },
      {
        id: 122,
        nomor: 81,
        kategori: "tkp",
        bidang: "Sosial Budaya",
        question:
          '<p>Carilah pola gambar pencerminan berikut ini</p>\r\n\r\n<p><img alt="" src="https://adhigama.education/upload/filemanager/Paket 1/Screenshot_286.jpg"></p>\r\n',
        questionImg: "",
        option: [],
      },
      {
        id: 119,
        nomor: 82,
        kategori: "tkp",
        bidang: "Sosial Budaya",
        question:
          "<p>Kecepatan Heri berlari adalah tiga kali kecepatan Zainal berjalan. Zainal menyelesaikan ujian pukul 11.30 WIB dan kemudian berjalan pulang. Jika Heri menyelesaikan ujian pukul 11.36 WIB dan kemudian berlari mengejar Zainal, pukul berapa Heri tepat menyusul Zainal?</p>\r\n",
        questionImg: "",
        option: [],
      },
      {
        id: 123,
        nomor: 83,
        kategori: "tkp",
        bidang: "Sosial Budaya",
        question:
          "Semua siswa diminta mempersiapkan diri untuk ujian nasional. Sebagian siswa mendapatkan nilai baik dalam ujian nasional.",
        questionImg: "",
        option: [],
      },
      {
        id: 116,
        nomor: 84,
        kategori: "tkp",
        bidang: "Sosial Budaya",
        question: " ",
        questionImg: null,
        option: [],
      },
      {
        id: 115,
        nomor: 85,
        kategori: "tkp",
        bidang: "Sosial Budaya",
        question: "benar?",
        questionImg: "",
        option: [],
      },
      {
        id: 120,
        nomor: 86,
        kategori: "tkp",
        bidang: "Sosial Budaya",
        question:
          '<p><img alt="" src="https://adhigama.education/upload/filemanager/Paket 1/soal_paket1_55.png"></p>\r\n',
        questionImg: "",
        option: [],
      },
      {
        id: 145,
        nomor: 87,
        kategori: "tkp",
        bidang: "Teknologi Informasi dan Komunikasi",
        question: "",
        questionImg: "",
        option: [],
      },
      {
        id: 154,
        nomor: 88,
        kategori: "tkp",
        bidang: "Teknologi Informasi dan Komunikasi",
        question:
          "Candi Prambanan hanya boleh dikunjungi jika candi Borobudur sudah dikunjungi",
        questionImg: null,
        option: [],
      },
      {
        id: 149,
        nomor: 89,
        kategori: "tkp",
        bidang: "Teknologi Informasi dan Komunikasi",
        question:
          "Candi Prambanan hanya boleh dikunjungi jika candi Borobudur sudah dikunjungi",
        questionImg: null,
        option: [],
      },
      {
        id: 150,
        nomor: 90,
        kategori: "tkp",
        bidang: "Teknologi Informasi dan Komunikasi",
        question:
          "Parang Tritis harus dikunjungi setelah Malioboro namun sebelum ke alun alun. Yang tidak mungkin dikunjungi setelah alun alun adalah",
        questionImg: null,
        option: [],
      },
      {
        id: 146,
        nomor: 91,
        kategori: "tkp",
        bidang: "Teknologi Informasi dan Komunikasi",
        question:
          "<p>Bacalah wacana berikut untuk soal 63-65</p>\r\n\r\n<p>Sepasang pengantin akan jalan jalan ke Jokyakarta dan berencana mengunjungi enam tempat wisata yaitu Malioboro, Alun-Alun, candi Borobudur, Candi prambanan, Parang Tritis dan Keraton. Adapun aturan pengunjung yaitu : Malioboro harus dikunjungi sebelum candi Borobudur dan keratin Candi Prambanan hanya boleh dikunjungi jika candi Borobudur sudah dikunjungi Parang Tritis harus dikunjungi setelah Malioboro namun sebelum ke alun alun. Sepasang pengantin akan jalan jalan ke Jokyakarta dan berencana mengunjungi enam tempat wisata yaitu Malioboro, Alun-Alun, candi Borobudur, Candi prambanan, Parang Tritis dan Keraton. Adapun aturan pengunjung yaitu : Malioboro harus dikunjungi sebelum candi Borobudur dan keratin Candi Prambanan hanya boleh dikunjungi jika candi Borobudur sudah dikunjungi Parang Tritis harus dikunjungi setelah Malioboro namun sebelum ke alun alun. Jika alun alun dikunjungi sebelum candi Borobudur dan parang Tritis dikunjungi setelah keratin maka yang dikunjungi terakhir adalah</p>\r\n",
        questionImg: "",
        option: [],
      },
      {
        id: 155,
        nomor: 92,
        kategori: "tkp",
        bidang: "Teknologi Informasi dan Komunikasi",
        question:
          "Parang Tritis harus dikunjungi setelah Malioboro namun sebelum ke alun alun. 331. Jika Keraton dikunjungi setelah alun alun, dan Candi Prambanan dikunjungi sebelum Parangtritis, maka",
        questionImg: "",
        option: [],
      },
      {
        id: 153,
        nomor: 93,
        kategori: "tkp",
        bidang: "Teknologi Informasi dan Komunikasi",
        question:
          "Malioboro harus dikunjungi sebelum candi Borobudur dan keratin",
        questionImg: null,
        option: [],
      },
      {
        id: 135,
        nomor: 94,
        kategori: "tkp",
        bidang: "Profesionalisme",
        question: "Untuk memanggil Cesa, lampu 2, 3 dan 4 menyala",
        questionImg: null,
        option: [],
      },
      {
        id: 136,
        nomor: 95,
        kategori: "tkp",
        bidang: "Profesionalisme",
        question: "Untuk memanggil DIta, lampu 1 dan 2 menyala",
        questionImg: null,
        option: [],
      },
      {
        id: 127,
        nomor: 96,
        kategori: "tkp",
        bidang: "Profesionalisme",
        question: "Untuk memanggil Beta, hanya lampu 1 yang menyala",
        questionImg: null,
        option: [],
      },
      {
        id: 139,
        nomor: 97,
        kategori: "tkp",
        bidang: "Profesionalisme",
        question:
          "Di sebuah perlombaan memasak terdapat empat buah lampu yang disusun berdampingan dan bernomor urut. Lampu tersebut disusun berjajar dari kiri ke kanan dengan diberi nomor 1, 2, 3, dan 4. Lampu tersebut digunakan sebagai tanda untuk memanggil peserta lomba memaak agar membawakan masakannya ke meja juri. Jumlah peserta lomba tersebut terdiri atas 5 orang, yaitu Andin, Beta, Cesa, Dita dan Eki",
        questionImg: null,
        option: [],
      },
      {
        id: 132,
        nomor: 98,
        kategori: "tkp",
        bidang: "Profesionalisme",
        question:
          "Di sebuah perlombaan memasak terdapat empat buah lampu yang disusun berdampingan dan bernomor urut. Lampu tersebut disusun berjajar dari kiri ke kanan dengan diberi nomor 1, 2, 3, dan 4. Lampu tersebut digunakan sebagai tanda untuk memanggil peserta lomba memaak agar membawakan masakannya ke meja juri. Jumlah peserta lomba tersebut terdiri atas 5 orang, yaitu Andin, Beta, Cesa, Dita dan Eki",
        questionImg: null,
        option: [],
      },
      {
        id: 131,
        nomor: 99,
        kategori: "tkp",
        bidang: "Profesionalisme",
        question: "",
        questionImg: "",
        option: [],
      },
      {
        id: 133,
        nomor: 100,
        kategori: "tkp",
        bidang: "Profesionalisme",
        question: "Untuk memanggil Andin, semua lampu menyala",
        questionImg: null,
        option: [],
      },
    ],
  },
};

type tth = {
  title: string;
  value: string;
};

const thDataUser: Array<tth> = [
  {
    title: "Nama Peserta",
    value: "User Testing",
  },
  {
    title: "Nomor Test",
    value: "1212",
  },
  {
    title: "Nilai Total",
    value: "0",
  },
  {
    title: "Nilai TWK",
    value: "0",
  },
  {
    title: "Nilai TIU",
    value: "0",
  },
  {
    title: "Nilai TKP",
    value: "0",
  },
];

const thKeterangan: Array<tth> = [
  {
    title: "Status",
    value: "Gagal",
  },
  {
    title: "Kemungkinan dapat di panggil ke tahap selanjutnya",
    value: "0%",
  },
  {
    title: "Anda dapat mengerjakan paket soal ini pada tanggal",
    value: "17 Jan 2021",
  },
  {
    title: "Anda belum melewati ambang batas TWK sebesar",
    value: "75",
  },
  {
    title: "Anda belum melewati ambang batas TIU sebesar",
    value: "80",
  },
  {
    title: "Anda belum melewati ambang batas TKP sebesar",
    value: "143",
  },
];

export { typeDE, soal, resultExam, thDataUser, thKeterangan };
