
import { defineComponent, reactive } from "vue";
import { dataPaket, dataSifatSoal, dataStatus } from "./jenis-soal";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";
import axios from "axios";

export default defineComponent({
  setup() {
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();
    const listPaket = dataPaket();
    const listSifatSoal = dataSifatSoal();
    const listStatus = dataStatus();

    const titleTable: Array<string> = [
      "Paket",
      "Kode / Title",
      "Nilai PassingGrade / <br> Jumlah Soal",
      "Durasi / <br> Skor Max",
      "Sifat Soal",
      "Status",
      "Created",
      "#",
    ];

    const dataJS = reactive({
      data: [] as any,
      search: "",
    });

    const getData = async () => {
      cash("#loadingJSadmin").show();

      axios
        .get(
          api.adminJenisSoal,
          configGet
        )
        .then(async (result: any) => {
          const rs = result.data.response;
          // console.log("response get js ", rs);

          dataJS.data = rs;
          // console.log("dataJS.paginate ", dataJS.paginate);
          cash("#loadingJSadmin").hide();
        })
        .catch(async (error: any) => {
          cash("#loadingJSadmin").hide();
          // console.log("response get du err ", error.response);
          await gpf.handleEds(error);
        });
    };
    getData();

    return {
      titleTable,
      getData,
      dataJS,
      configGet,
      configXwfu,
      listPaket,
      listSifatSoal,
      listStatus,
    };
  },
  data() {
    return {
      // for modal data user
      section: "add",

      detailJS: {
        id: 0,
        kode: "",
        paket: "cpns",
        title: "",
        nilaiPg: "",
        jmlSoal: "",
        durasi: "",
        skorMax: "",
        sifatSoal: "umum",
        isActive: "1",
      } as any,

      exDataJS: {
        id: 0,
        kode: "",
        paket: "cpns",
        title: "",
        nilaiPg: "",
        jmlSoal: "",
        durasi: "",
        skorMax: "",
        sifatSoal: "umum",
        isActive: "1",
      },

      exDeleteJS: {
        id: 0,
        title: "",
      }
    };
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.exDataJS;
      ex[f] = val;
    },
    async emptyField() {
      const ex = this.exDataJS;
      ex.kode = "";
      ex.paket = "cpns";
      ex.title = "";
      ex.nilaiPg = "";
      ex.jmlSoal = "";
      ex.durasi = "";
      ex.skorMax = "";
      ex.sifatSoal = "umum";
      ex.isActive = "1";
    },
    async addJS() {
      await this.emptyField();
      this.section = "add";
      gpf.showModal("#modal-data-js");
    },
    addJSApi() {
      gpf.gLoading.show();
      const ex = this.exDataJS;
      const params = new URLSearchParams();
      params.append("kode", ex.kode);
      params.append("paket", ex.paket);
      params.append("title", ex.title);
      params.append("nilaiPg", ex.nilaiPg);
      params.append("jmlSoal", ex.jmlSoal);
      params.append("durasi", ex.durasi);
      params.append("skorMax", ex.skorMax);
      params.append("sifatSoal", ex.sifatSoal);
      params.append("isActive", ex.isActive);

      axios
        .post(api.adminJenisSoal, params, this.configXwfu)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-js");
          await this.emptyField();
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    editJS(data: any) {
      this.section = "edit";

      gpf.gLoading.show();

      axios
        .get(api.adminJenisSoal + data.id, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          const rs = result.data.response;
          // console.log("response get deu ", rs);

          // console.log("rs.status.toLowerCase() ", rs.status.toLowerCase() , rs.status.toLowerCase() === "active")

          const ex = this.exDataJS;
          ex.id = rs.id;
          ex.kode = rs.kode;
          ex.paket = rs.paket;
          ex.title = rs.title;
          ex.nilaiPg = rs.nilaiPg;
          ex.jmlSoal = rs.jmlSoal;
          ex.durasi = rs.durasi;
          ex.skorMax = rs.skorMax;
          ex.sifatSoal = rs.sifatSoal;
          ex.isActive = rs.isActive ? "1" : "0";

          await gpf.showModal("#modal-data-js");
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          // console.log("response get deu err ", error.response);
          await gpf.handleEds(error);
        });
    },
    editJSApi(): void {
      gpf.gLoading.show();
      const ex = this.exDataJS;
      const params = new URLSearchParams();
      params.append("kode", ex.kode);
      params.append("paket", ex.paket);
      params.append("title", ex.title);
      params.append("nilaiPg", ex.nilaiPg);
      params.append("jmlSoal", ex.jmlSoal);
      params.append("durasi", ex.durasi);
      params.append("skorMax", ex.skorMax);
      params.append("sifatSoal", ex.sifatSoal);
      params.append("isActive", ex.isActive);

      axios
        .put(api.adminJenisSoal + ex.id, params, this.configXwfu)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-js");
          await this.emptyField();
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    detailJSApi(data: any): void {
      gpf.gLoading.show();

      axios
        .get(api.adminJenisSoal + data.id, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          const rs = result.data.response;
          // console.log("response get deu ", rs);

          this.detailJS = rs;
          await gpf.showModal("#modal-detail-js");
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    deleteJS(data: any): void {
      const ex = this.exDeleteJS;
      ex.id = data.id;
      ex.title = data.title;

      gpf.showModal("#delete-modal-data-js");
    },
    deleteJSApi() {
      const ex = this.exDeleteJS;

      gpf.gLoading.show();

      axios
        .delete(api.adminJenisSoal + ex.id, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          ex.id = 0;
          ex.title = "";

          await gpf.handleSds(result);
          await gpf.hideModal("#delete-modal-data-js");
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    }
  },
});
