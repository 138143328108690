
import { defineComponent, reactive } from "vue";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";
import { dataStatus } from "./affiliate";
import axios from "axios";

export default defineComponent({
  setup() {
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();
		const listStatus = dataStatus();

    const titleTable: Array<string> = [
			"Name",
      "Username",
      "Email",
      "Phone Number",
      "Fee VIP",
			"Status",
      "Created",
			"#",
    ];

    const dataOA = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
    });

    const getData = async () => {
      cash("#loadingOA").show();

      const page = dataOA.page;
      const pageSize = dataOA.pageSize;
      const search = dataOA.search;
      axios
        .get(
          api.ownerAff +
            "?search=" +
            search +
            "&page=" +
            page +
            "&pageSize=" +
            pageSize,
          configGet
        )
        .then(async (result: any) => {
          const ds = result.data.diagnostic;
          const rs = result.data.response;
          // console.log("response get du ", rs);

          dataOA.data = rs;
          dataOA.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);
          // console.log("dataOA.paginate ", dataOA.paginate);
          cash("#loadingOA").hide();
        })
        .catch(async (error: any) => {
          cash("#loadingOA").hide();
          // console.log("response get du err ", error.response);
					await gpf.handleEds(error);
        });
    };
    getData();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataOA.page = p;
      getData();
    };

    const nextPage = () => {
      dataOA.page = dataOA.page + 1;
      getData();
    };

    const beforePage = () => {
      dataOA.page = dataOA.page - 1;
      getData();
    };

    const lastPage = () => {
      dataOA.page = dataOA.paginate.totalPages;
      getData();
    };

    return {
      titleTable,
      getData,
      dataOA,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
			listStatus,
    };
  },
  data() {
    return {
			section: "add",

			exDataOA: {
				id: "",
				nama: "",
				email: "",
				phone: "",
				username: "",
				password: "",
				feeVIP: "",
				status: "1",
			},

			detailDataOA: {
				id: "",
				nama: "",
				email: "",
				phone: "",
				username: "",
				feeVIP: "",
				photo: "",
			},

		};
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.exDataOA;
      ex[f] = val;
    },
		async emptyField() {
			const ex = this.exDataOA;
			ex.id = "0";
			ex.nama = "";
			ex.email = "";
			ex.phone = "";
			ex.username = "";
			ex.password = "";
			ex.feeVIP = "";
		},
		ctc(f: string) {
      const ex: any = this.exDataOA;
      ex[f] = gpf.ctc(ex[f]);
    },
		async addOA() {
      await this.emptyField();
      this.section = "add";
			gpf.showModal("#modal-data-oa");
    },
    addOAApi() {
      gpf.gLoading.show();
      const ex = this.exDataOA;
      const params = new URLSearchParams();
      params.append("nama", ex.nama);
      params.append("email", ex.email);
      params.append("phone", ex.phone);
      params.append("username", ex.username);
      params.append("password", ex.password);
      params.append("feeVIP", gpf.btc(ex.feeVIP));
      params.append("status", ex.status);

      axios
        .post(api.ownerAff, params, this.configXwfu)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-oa");
          await this.emptyField();
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    editOA(data: any) {
      this.section = "edit";
      gpf.gLoading.show();

      axios
        .get(api.ownerAff + data.id, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          const rs = result.data.response;
          // console.log("response get deu ", rs);
          const ex = this.exDataOA;
          ex.id = rs.id;
          ex.nama = rs.name;
          ex.email = rs.email;
          ex.phone = rs.phone;
          ex.username = rs.username;
          ex.feeVIP = gpf.ctc(rs.feeVIP);
					ex.status = rs.status === 'Active' ? '1' : '0';

          await gpf.showModal("#modal-data-oa");
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    editOAApi(): void {
      gpf.gLoading.show();
      const ex = this.exDataOA;
      const params = new URLSearchParams();
      params.append("nama", ex.nama);
      params.append("email", ex.email);
      params.append("phone", ex.phone);
      params.append("username", ex.username);
      params.append("password", ex.password);
      params.append("feeVIP", gpf.btc(ex.feeVIP));
      params.append("status", ex.status);

      axios
        .put(api.ownerAff + ex.id, params, this.configXwfu)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-oa");
          await this.emptyField();
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    detailOAApi(data: any): void {
      gpf.gLoading.show();

      axios
        .get(api.ownerAff + data.id, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          const rs = result.data.response;
          // console.log("response get deu ", rs);

          const ex = this.detailDataOA;
          ex.id = rs.id;
          ex.nama = rs.name;
          ex.email = rs.email;
          ex.phone = rs.phone;
          ex.username = rs.username;
          ex.feeVIP = rs.feeVIP;
          ex.photo = rs.photo;

          await gpf.showModal("#modal-detail-oa");
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },

  },
});
