import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  ref: "fileUploadRef",
  class: "dropzone"
}
const _hoisted_2 = { class: "dz-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_file_upload = _resolveDirective("file-upload")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ])), [
    [_directive_file_upload, { props: _ctx.props, emit: _ctx.emit }]
  ])
}