
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import { helper as $h } from "@/utils/helper";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";
import axios from "axios";
import { dataStatus } from "./kelas-webinar-admin";

export default defineComponent({
  setup() {
    const router = useRouter();
    const configGet = api.configGet();
		const listStatus = dataStatus();

    const dataKW = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
    });

    const titleTable: Array<string> = [
      "Banner",
      "Tanggal",
      "Title",
      "Deskripsi",
      "Link",
      "Status",
			"#",
    ];

    const getData = async () => {
      cash("#loadingKW").show();

      const page = dataKW.page;
      const pageSize = dataKW.pageSize;
      const search = dataKW.search;
      axios
        .get(
          api.adminKelasWebinar +
            "?page=" +
            page +
            "&pageSize=" +
            pageSize +
            "&search=" +
            search,
          configGet
        )
        .then(async (result: any) => {
          const ds = result.data.diagnostic;
          const rs = result.data.response;
          // console.log("response get kw ", rs);

          dataKW.data = rs;
          dataKW.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);
          // console.log("dataKW.paginate ", dataKW.paginate);
          cash("#loadingKW").hide();
        })
        .catch(async (error: any) => {
          // console.log("response get kw err ", error.response);
          await gpf.handleEds(error);
        });
    };
    getData();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataKW.page = p;
      getData();
    };

    const nextPage = () => {
      dataKW.page = dataKW.page + 1;
      getData();
    };

    const beforePage = () => {
      dataKW.page = dataKW.page - 1;
      getData();
    };

    const lastPage = () => {
      dataKW.page = dataKW.paginate.totalPages;
      getData();
    };

    return {
      titleTable,
      dataKW,
      getData,
      router,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
			configGet,
			listStatus,
    };
  },
  data() {
    return {
			section: "add",
			exDataKW: {
				id: "",
				title: "",
				description: "",
				banner: "",
				nameBanner: "",
				oversizeBanner: false,
				tempBanner: "",
				link: "",
				isActive: "1",
				tglMulai: "",
        jam: "00",
        menit: "00",
			},

			detailKW: {
				title: "",
				description: "",
				banner: "",
				nameBanner: "",
				oversizeBanner: false,
				tempBanner: "",
				link: "",
				isActive: "1",
				tglMulai: "",
			},

			exDeleteKW: {
				id: "",
				title: "",
			},

		};
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.exDataKW;
      ex[f] = val;
    },
		chooseBanner() {
      const id = "fileBannerDM";
      const btn: any = document.getElementById(id);
      btn.click();
    },
    removeBanner() {
      const ex = this.exDataKW;
      ex.nameBanner = "Choose File";
      ex.banner = "";
      cash("#showImage").attr("src", "");
    },
    handleFile(evt: any ) {
      const target = evt.target;
      const files = target.files;
      const sizeFiles = target.files[0].size;
      const ex = this.exDataKW;

      if (sizeFiles > 1000000) {
        ex.oversizeBanner = true;
        this.removeBanner();
      } else {
        const nameFile = target.files[0].name;
        ex.nameBanner = nameFile;
        ex.banner = files[0];
        ex.oversizeBanner = false;
        // const bodyFormData = new FormData();
        // bodyFormData.append("files", files[0]);

        this.renderImage(target.files[0]);
      }
      // console.log("evt ", evt);
    },
    renderImage(files: any) {
      // console.log('masuk render image ', files)
      const reader = new FileReader();
      reader.onload = function(e: any) {
        cash("#showImage").attr("src", e.target.result);
      };
      reader.readAsDataURL(files);
    },
		async emptyField() {
			const ex = this.exDataKW;
			const dateNow = new Date();

			ex.title = "";
			ex.description = "";
			ex.banner = "";
			ex.link = "";
			ex.isActive = "1";
			ex.tglMulai = $h.formatDate(dateNow.toString(), "DD MMM, YYYY");
		},
		async addKW() {
      await this.emptyField();
      this.section = "add";
      gpf.showModal("#modal-data-kw");
    },
    addKWApi() {
      gpf.gLoading.show();
      const ex = this.exDataKW;
      const body = new FormData();
      body.append("title", ex.title);
      body.append("description", ex.description);
      body.append("banner", ex.banner);
      body.append("link", ex.link);
      body.append("isActive", ex.isActive);
      body.append("tglMulai", $h.formatDate(`${ex.tglMulai} ${ex.jam}:${ex.menit}:00`, "YYYY-MM-DD HH:mm:ss"));

      axios
        .post(api.adminKelasWebinar, body, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-kw");
          await this.emptyField();
          await this.getData();
        })
        .catch(async(error: any) => {
          gpf.gLoading.hide();
          // console.log("response add dm err ", error.response);
          await gpf.handleEds(error);
        });
    },
    editKW(data: any) {
      this.section = "edit";

      gpf.gLoading.show();

      axios
        .get(api.adminKelasWebinar + data.id, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          const rs = result.data.response;
          // console.log("response get deu ", rs);

          const ex = this.exDataKW;
          ex.id = rs.id;
          ex.title = rs.title;
          ex.description = rs.description;
          ex.banner = rs.banner;
          ex.nameBanner = rs.banner;
          ex.tempBanner = rs.banner;
          ex.link = rs.link;
          ex.isActive = rs.isActive ? "1" : "0";
					ex.tglMulai = $h.formatDate(rs.startedAt, "DD MMM, YYYY");
          
          const jam = rs.startedAt.split(" ")[1].split(":")[0];
          const menit = rs.startedAt.split(" ")[1].split(":")[1];
          // console.log("jam ", jam);
          ex.jam = jam;
          ex.menit = menit;

          await gpf.showModal("#modal-data-kw");
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          // console.log("response get deu err ", error.response);
					await gpf.handleEds(error);
        });
    },
    editKWApi(): void {
      gpf.gLoading.show();
      const ex = this.exDataKW;
      const body = new FormData();
      body.append("title", ex.title);
			body.append("description", ex.description);
      if ( ex.nameBanner !== ex.tempBanner ) {
        body.append("banner", ex.banner);
      } else {
        body.append("banner", "");
      }
      body.append("link", ex.link);
      body.append("isActive", ex.isActive);
      body.append("tglMulai", $h.formatDate(`${ex.tglMulai} ${ex.jam}:${ex.menit}:00`, "YYYY-MM-DD HH:mm:ss"));

      axios
        .put(api.adminKelasWebinar + ex.id, body, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-kw");
          await this.emptyField();
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          // console.log("response get deu err ", error.response);
          await gpf.handleEds(error);
        });
    },
    detailKWApi(data: any): void {
      gpf.gLoading.show();
      const exd = this.detailKW;

      axios
        .get(api.adminKelasWebinar + data.id, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          const rs = result.data.response;
          // console.log("response get deu ", rs);
          exd.title = rs.title;
          exd.description = rs.description;
          exd.banner = rs.banner;
          exd.isActive = rs.isActive ? "Active" : "Suspend";
          exd.link = rs.link;
          exd.tglMulai = $h.formatDate(rs.startedAt, "DD MMM, YYYY HH:mm:ss");

          await gpf.showModal("#modal-detail-materi");
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          // console.log("response get deu err ", error.response);
					await gpf.handleEds(error);
        });
    },

		deleteKW(data: any): void {
      const ex = this.exDeleteKW;
      ex.id = data.id;
      ex.title = data.title;

      gpf.showModal("#delete-modal-data-kw");
    },
    deleteKWApi() {
      const ex = this.exDeleteKW;

      gpf.gLoading.show();

      axios
        .delete(api.adminKelasWebinar + ex.id, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          ex.id = "0";
          ex.title = "";

          await gpf.handleSds(result);
          await gpf.hideModal("#delete-modal-data-kw");
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          // console.log("response get deu err ", error.response);
          await gpf.handleEds(error);
        });
    },
	},
});
