import { App } from "vue";
import { gpf } from "@/utils/global-functions";
import router from "@/router";
import axios from "axios";
import { VueCookieNext } from "vue-cookie-next";
import { loadingScreen } from "@/utils/loading-screen";
import { HOST_API_V1 } from "@/services/env-init";

const cookie = VueCookieNext;

// const feHost = location.host;
// const host = "https://api.adhigama.education/";
// if (feHost !== "app.adhigama.education") {
//   host = "https://dev.adhigama.education/";
// }

// console.log('process env ', process.env)

// const version = "v1/";
const url = HOST_API_V1;
const clbkun = () => console.log("callback undefined");
const configXwfu = () => {
  const token: any = apiConfig.getToken();
  const obj = {
    headers: {
      Authorization: token,
    },
  };
  if (token === null) {
    apiConfig.somethingWrong();
  } else {
    return obj;
  }
};
const apiConfig = {
  // global functions for api

  logout(): void {
    loadingScreen().show();

    cookie.removeCookie("login-data");
    cookie.removeCookie("tkn-data");
    cookie.removeCookie("role-data");
    cookie.removeCookie("list-menu");

    setTimeout(() => {
      loadingScreen().hide();
      router.push({ name: "login" });
    }, 1000);
  },
  getLoginData(): any {
    const ld = cookie.getCookie("login-data");
    return ld;
  },
  getRole(): any {
    const role = cookie.getCookie("role-data");
    return role;
  },
  getToken(): any {
    const token = cookie.getCookie("tkn-data");
    return token;
  },
  configGet(): any {
    const token = apiConfig.getToken();
    const obj = {
      headers: {
        Authorization: token,
      },
    };
    if (token === null) {
      apiConfig.somethingWrong();
      return obj;
    } else {
      return obj;
    }
  },
  configXwfu(): any {
    const token = apiConfig.getToken();
    const obj = {
      headers: {
        Authorization: token,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    if (token === null) {
      apiConfig.somethingWrong();
    } else {
      return obj;
    }
  },
  somethingWrong(): void {
    gpf.showModal("#modal-something-wrong");
  },
  async getConfigFeatureMember(
    apiMember: string,
    apiFeature: string
  ): Promise<any> {
    const role = apiConfig.getRole();
    const api: any = apiConfig;
    if (role === "member") {
      return api[apiMember];
    } else if (role === "affiliate" || role === "sales") {
      return api[apiFeature];
    } else {
      return false;
    }
  },
  async getAkunBank(callback = clbkun): Promise<any> {
    const obj: any = apiConfig.configGet();

    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.publicAkunBank, obj)
        .then(async (result: any) => {
          const rs = result.data.response;
          resolve(rs);
          callback();
        })
        .catch(async (err: any) => {
          await gpf.handleEds(err);
          reject(err);
          callback();
        });
    });
  },
  async getSaldo(): Promise<any> {
    const obj: any = apiConfig.configGet();

    return new Promise((resolve, reject) => {
      axios
        .get(url + apiConfig.getRole() + "/saldo/", obj)
        .then(async (result: any) => {
          const rs = result.data.response;
          resolve(rs);
        })
        .catch(async (err: any) => {
          await gpf.handleEds(err);
          reject(err);
        });
    });
  },
  async getJenisTryout(): Promise<any> {
    const obj: any = apiConfig.configGet();

    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.publicJenisTryout, obj)
        .then(async (result: any) => {
          const rs = result.data.response;
          resolve(rs);
        })
        .catch(async (err: any) => {
          await gpf.handleEds(err);
          reject(err);
        });
    });
  },
  async getPackage(): Promise<any> {
    const obj: any = apiConfig.configGet();

    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.publicPackage, obj)
        .then(async (result: any) => {
          const rs = result.data.response;
          resolve(rs);
        })
        .catch(async (err: any) => {
          await gpf.handleEds(err);
          reject(err);
        });
    });
  },
  async getPayment(): Promise<any> {
    const obj: any = apiConfig.configGet();

    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.publicPayment, obj)
        .then(async (result: any) => {
          const rs = result.data.response;
          resolve(rs);
        })
        .catch(async (err: any) => {
          await gpf.handleEds(err);
          reject(err);
        });
    });
  },
  async getPromo(qp = ""): Promise<any> {
    const obj: any = apiConfig.configGet();

    return new Promise((resolve, reject) => {
      axios
        .get(apiConfig.publicPromo + qp, obj)
        .then(async (result: any) => {
          const rs = result.data.response;
          resolve(rs);
        })
        .catch(async (err: any) => {
          await gpf.handleEds(err);
          reject(err);
        });
    });
  },
  async hitApiGet(url: string, callback = clbkun): Promise<any> {
    const cg: any = apiConfig.configGet();

    return new Promise((resolve, reject) => {
      axios
        .get(url, cg)
        .then(async (result: any) => {
          resolve(result);
          callback();
        })
        .catch(async (err: any) => {
          await gpf.handleEds(err);
          reject(err);
          callback();
        });
    });
  },
  async hitApiPost(
    url: string,
    body: any,
    callback = clbkun,
    cg = configXwfu()
  ): Promise<any> {
    return new Promise((resolve) => {
      axios
        .post(url, body, cg)
        .then(async (result: any) => {
          callback();
          resolve(result);
        })
        .catch(async (err: any) => {
          await gpf.handleEds(err);
          callback();
          resolve(err.response);
        });
    });
  },
  async hitApiPut(
    url: string,
    body: any,
    callback = clbkun,
    cg = configXwfu()
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
        .put(url, body, cg)
        .then(async (result: any) => {
          callback();
          resolve(result);
        })
        .catch(async (err: any) => {
          await gpf.handleEds(err);
          callback();
          reject(err);
        });
    });
  },
  async hitApiDel(url: string, callback = clbkun): Promise<any> {
    const cg: any = apiConfig.configXwfu();

    return new Promise((resolve, reject) => {
      axios
        .delete(url, cg)
        .then(async (result: any) => {
          callback();
          resolve(result);
        })
        .catch(async (err: any) => {
          await gpf.handleEds(err);
          callback();
          reject(err);
        });
    });
  },

  // auth
  login: url + "auth/login", // done-refactor
  getMenu: url + "auth/menu", // done-refactor

  // public
  publicPackage: url + "public/package/",
  publicPayment: url + "public/payment/",
  publicPromo: url + "public/promo/",
  publicJenisTryout: url + "public/jenis-tryout",
  publicAkunBank: url + "public/bank/",
  publicProfile: url + "public/profile/", // done-refactor
  publicResetPassword: url + "public/reset-password/", // done-refactor
  publicForgotPassword: url + "public/forgot-password/", // done-refactor
  publicResetPin: url + "public/reset-pin/",

  // member
  memberWidgetTop: url + "member/widget",
  memberSK: url + "member/sk-garansi",
  memberTB: url + "member/tahap-belajar",
  memberMateri: url + "member/materi/",
  memberMateriJenis: url + "member/materi/jenis",
  memberJenisTryout: url + "member/cat/jenis/",
  memberPassingGrade: url + "member/cat/passing-grade/",
  memberSoalCat: url + "member/cat/question/", // done-refactor
  memberSubmitCat: url + "member/cat/submit/",
  memberPembahasanCat: url + "member/cat/pembahasan/",
  memberHasilCat: url + "member/cat/result/",
  memberHistoryCat: url + "member/cat/history/", // done-refactor
  memberPeringkatCat: url + "member/cat/leaderboard/",
  memberKelasWebinar: url + "member/kelas-webinar/",
  memberKelasPrivate: url + "member/kelas-private/",
  memberKelasKategori: url + "member/kelas-kategori/",
  memberTryoutChart: url + "member/tryout-chart/",
  memberGaransi: url + "member/garansi/",
  memberOrderPaket: url + "member/order/",

  // role feature as fitur coid
  featureWidgetTop: url + "feature/widget",
  featureSK: url + "feature/sk-garansi",
  featureTB: url + "feature/tahap-belajar",
  featureMateri: url + "feature/materi/",
  featureJenisTryout: url + "feature/cat/jenis/",
  featurePassingGrade: url + "feature/cat/passing-grade/",
  featureSoalCat: url + "feature/cat/question/",
  featureSubmitCat: url + "feature/cat/submit/",
  featurePembahasanCat: url + "feature/cat/pembahasan/",
  featureHasilCat: url + "feature/cat/result/",
  featureHistoryCat: url + "feature/cat/history/", // done-refactor
  featurePeringkatCat: url + "feature/cat/leaderboard/",
  featureKelasWebinar: url + "feature/kelas-webinar/",
  featureKelasPrivate: url + "feature/kelas-private/",
  featureKelasKategori: url + "feature/kelas-kategori/",

  // admin
  adminDataUser: url + "admin/user/",
  adminDataMateri: url + "admin/learning/",
  adminMasterSoal: url + "admin/bank-soal/",
  adminJenisSoal: url + "admin/bank-soal/jenis/",
  adminKategoriSoal: url + "admin/bank-soal/kategori/",
  adminBidangSoal: url + "admin/bank-soal/bidang/",
  adminAllBidangSoal: url + "admin/bank-soal/bidang-soal/",
  adminImportSoal: url + "admin/bank-soal/import/",
  adminPemasukan: url + "admin/transaksi/income/",
  adminPengeluaran: url + "admin/transaksi/expense/",
  adminKelasPrivate: url + "admin/forum/kelas-privat/",
  adminKelasWebinar: url + "admin/forum/kelas-webinar/",
  adminDataAffiliate: url + "admin/affiliate/data-affiliate/",
  adminAcceptTransaksi: url + "admin/transaksi/accept/",
  adminWithdrawal: url + "admin/transaksi/withdrawal/",
  adminMarketingKit: url + "admin/affiliate/marketing-kit/",
  adminLeaderboard: url + "admin/leaderboard/",
  adminFilterBankSoal: url + "admin/bank-soal/filter/",
  adminDataKomisi: url + "admin/report/komisi/",
  adminSaldoAff: url + "admin/report/saldo/",
  adminPaketMember: url + "admin/report/subscription/",
  adminHasilTryout: url + "admin/report/tryout/",
  adminDashboard: url + "admin/dashboard/",
  adminRegisterChart: url + "admin/register-chart/",
  adminRevenueChart: url + "admin/revenue-chart/",
  adminBlogKategori: url + "admin/blog/category/",
  adminBlogTag: url + "admin/blog/tag/",
  adminBlogArtikel: url + "admin/blog/article/",

  // affiliate
  affWidget: url + "affiliate/widget/",
  affMember: url + "affiliate/member/",
  affSales: url + "affiliate/cso/",
  affOrder: url + "affiliate/order/",
  affKomisi: url + "affiliate/komisi/",
  affSaldo: url + "affiliate/komisi/",
  affWithdrawal: url + "affiliate/withdrawal/",
  affMKExternal: url + "affiliate/marketing-kit/link-external/",
  affMKAffiliate: url + "affiliate/marketing-kit/link-affiliate/",
  affLeaderboardExt: url + "affiliate/marketing-kit/leaderboard/",
  affMK: url + "affiliate/marketing-kit/",
  affLinkRef: url + "affiliate/link-referral/",
  affLeaderboard: url + "affiliate/leaderboard/",
  affPS: url + "affiliate/pengaturan/",
  affSalesFilter: url + "affiliate/sales/",
  affPromo: url + "affiliate/promo/",

  // owner
  ownerWidget: url + "owner/widget/",
  ownerAff: url + "owner/affiliate/",
  ownerKomisi: url + "owner/komisi/",
  ownerWithdrawal: url + "owner/withdrawal/",
  ownerLeaderboard: url + "owner/leaderboard/",

  // sales
  salesWidget: url + "sales/widget/",
  salesOrder: url + "sales/order/",
  salesKomisi: url + "sales/bonus/",
  salesMember: url + "sales/member/",
  salesWithdrawal: url + "sales/withdrawal/",
  salesStatusOrder: url + "sales/order/status/",
  salesConfirm: url + "sales/confirm/",
  salesMarketingKit: url + "sales/marketing-kit/",
  salesLeaderboard: url + "sales/leaderboard/",

  // tentor
  tentorDashboard: url + "tentor/dashboard/",
  tentorMasterSoal: url + "tentor/bank-soal/",
  tentorJenisSoal: url + "tentor/bank-soal/jenis/",
  tentorKategoriSoal: url + "tentor/bank-soal/kategori/",
  tentorBidangSoal: url + "tentor/bank-soal/bidang/",
  tentorFilterBankSoal: url + "tentor/bank-soal/filter/",
  tentorImportSoal: url + "tentor/bank-soal/import/",
  tentorKelasPrivate: url + "tentor/kelas-privat/",
  tentorForumKelasPrivate: url + "tentor/forum/kelas-privat/",
  tentorJenisTryout: url + "tentor/cat/jenis/",
  tentorPassingGrade: url + "tentor/cat/passing-grade/",
  tentorSoalCat: url + "tentor/cat/question/",
  tentorSubmitCat: url + "tentor/cat/submit/",
  tentorPembahasanCat: url + "tentor/cat/pembahasan/",
  tentorHasilCat: url + "tentor/cat/result/",
  tentorHistoryCat: url + "tentor/cat/history/",
};

const install = (app: App): void => {
  app.config.globalProperties.gpf = apiConfig;
};

const logout = (): void => {
  apiConfig.logout();
};

export { install as default, apiConfig as api, logout };
