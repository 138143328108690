import { reactive } from "vue";
import { gpf } from "../global-functions";

const defaultMessage = "Loading...";

export const loadingScreenState = reactive({
  show: false,
  message: defaultMessage,
});

export const loadingScreen = (message?: string) => ({
  show: () => {
    gpf.removeDoubleModal("#gLoading");
    loadingScreenState.message = message || defaultMessage;
    loadingScreenState.show = true;
  },
  hide: () => {
    gpf.removeDoubleModal("#gLoading");
    loadingScreenState.message = defaultMessage;
    loadingScreenState.show = false;
  },
});
