
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { dataRoles, dataStatus } from "./data-user";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";
import { helper } from "@/utils/helper";
import axios from "axios";
import { setBreadcrumb } from "@/utils/breadcrumb";
import { getRole } from "@/utils/cookie-next";
import { Breadcrumb, dashboardPath } from "@/components/top-bar";
import { prettierPhotoUser } from "@/utils/user";
import { DataTableHeader } from "@/global-components/data-table/types";
import { User } from "@/services/v1/admin/user/types";
import {
  popupConfirmation,
  popupConfirmationHide,
} from "@/utils/popup/confirmation";
import { popupStatus } from "@/utils/popup/status";

const DataTable = defineAsyncComponent(
  () => import(`@/global-components/data-table/Main.vue`)
);

const ModalDetailUser = defineAsyncComponent(
  () => import("./components/modal-detail-user.vue")
);

const servicesV1Admin = async () =>
  await import(`@/services/v1/admin/user/index`);

export default defineComponent({
  components: {
    DataTable,
    ModalDetailUser,
  },
  setup() {
    const config = api.configGet();
    const listRoles = dataRoles();
    const listStatus = dataStatus();

    // START --- setup breadcrumb -------------------------------------------------
    const breadcrumb: Breadcrumb[] = [
      {
        title: "Dashboard",
        to: dashboardPath,
      },
      {
        title: "Data User",
        to: `${dashboardPath}/data-user`,
        disabled: true,
      },
    ];

    onMounted(() => {
      setBreadcrumb(breadcrumb);
    });
    // END --- setup breadcrumb ---------------------------------------------------

    // START === Setup list data user ========================================================
    const tabelHeaders = [
      {
        text: "Photo",
        value: "photo",
        headerClass: "w-24",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Username",
        value: "username",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Phone",
        value: "phone",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Role",
        value: "role",
      },
      {
        text: "Created At",
        value: "createdAt",
        headerClass: "w-48",
      },
      {
        text: "Action",
        value: "action",
        itemClass: "w-32",
        align: "center",
      },
    ] as DataTableHeader[];

    const dataUser = reactive({
      data: [] as any[],
      loading: false,
      search: "",
      page: 1,
      pageSize: 10,
      totalData: 1,
    });

    const getListUserData = async () => {
      const { getUserList } = await servicesV1Admin();

      dataUser.loading = true;

      try {
        const page = dataUser.page;
        const pageSize = dataUser.pageSize;

        const params = {
          page,
          pageSize,
          search: dataUser.search,
        };

        const hit = await getUserList({ params } as any);

        dataUser.data = hit.response;

        const ds = hit.diagnostic;
        dataUser.totalData = ds.totalData as number;
      } catch (e: any) {
        await gpf.handleEds(e);
      } finally {
        dataUser.loading = false;
      }
    };

    const prettierDataUsers = computed(() =>
      dataUser?.data?.map((user: User) => ({
        ...user,
        photo: prettierPhotoUser(user.photo),
      }))
    );

    const handleSearch = () => {
      dataUser.page = 1;
      getListUserData();
    };

    watch(
      () => dataUser.pageSize,
      () => {
        dataUser.page = 1;
        getListUserData();
      }
    );

    watch(
      () => dataUser.page,
      () => {
        getListUserData();
      }
    );

    onMounted(() => {
      getListUserData();
    });
    // END === Setup list data user ==========================================================

    // START === Funcions for handle modal detail user =======================================
    const userId = ref(0 as number);
    const showDetailUser = (id: any) => {
      userId.value = id;
      gpf.showModal("#modal-detail-user");
    };
    // END === Funcions for handle modal detail user =========================================

    // START === Functions for delete user ===================================================
    const deleteUserPopup = (user: User): void => {
      popupConfirmation({
        title: "Hapus Data Materi",
        message: `Apakah Anda yakin, Ingin menghapus data user dengan nama : "${user.name}"`,
        buttons: [
          {
            type: "outline",
            text: "Batal",
            action: popupConfirmationHide,
          },
          {
            type: "danger",
            text: "Hapus",
            action: () => {
              fixDeleteUser(user.id);
              popupConfirmationHide();
            },
          },
        ],
      }).show();
    };

    const fixDeleteUser = async (adminUserId: number) => {
      const { deleteUserById } = await servicesV1Admin();

      gpf.gLoading.show();

      try {
        const hit = await deleteUserById(adminUserId);

        console.log("hit deleteUserById ", hit);

        const messageSuccess = hit?.diagnostic?.message;

        popupStatus({
          type: "s",
          message: messageSuccess,
        }).show();
      } catch (err: any) {
        console.error("err fixDeleteUser ", err, err?.response);

        const errData = err?.response?.data;
        const messageErr = errData?.diagnostic?.message;

        popupStatus({
          type: "f",
          message: messageErr,
        }).show();
      } finally {
        gpf.gLoading.hide();

        // refetch list data-user
        getListUserData();
      }
    };
    // END === Functions for delete user =====================================================

    return {
      tabelHeaders,
      getListUserData,
      dataUser,
      config,
      listRoles,
      listStatus,
      gpf,
      helper,

      userId,
      showDetailUser,

      handleSearch,
      prettierDataUsers,

      deleteUserPopup,
    };
  },
  data() {
    return {
      // for modal data user
      section: "add",

      exDataUser: {
        id: 0,
        name: "",
        email: "",
        phone: "",
        status: "1",
        roles: "member",
        username: "",
        pin: "",
        password: "",
      },

      required: {
        name: false,
        email: false,
        phone: false,
        status: false,
        roles: false,
        username: false,
        pin: false,
        password: false,
      },

      exDeleteUser: {
        id: 0,
        name: "",
      },
    };
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.exDataUser;
      ex[f] = val;
    },
    async emptyField() {
      const ex = this.exDataUser;
      ex.id = 0;
      ex.name = "";
      ex.email = "";
      ex.phone = "";
      ex.status = "1";
      ex.roles = "member";
      ex.username = "";
      ex.pin = "";
      ex.password = "";
    },
    requiredField(): boolean {
      const ex = this.exDataUser;
      const er = this.required;
      if (ex.pin) {
        er.pin = false;
        return true;
      } else {
        er.pin = true;
        return false;
      }
    },
    async addUser() {
      await this.emptyField();
      this.section = "add";
      gpf.showModal("#modal-data-user");
    },
    addUserApi() {
      gpf.gLoading.show();
      const ex = this.exDataUser;

      const body = {
        username: ex.username.trim(),
        password: ex.password ? ex.password : null,
        pin: ex.pin ? ex.pin : null,
        name: ex.name,
        email: ex.email,
        phone: ex.phone,
        status: ex.status,
        roles: ex.roles,
      };

      axios
        .post(api.adminDataUser, body, this.config)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          ex.name = "";
          ex.email = "";
          ex.phone = "";
          ex.roles = "";
          ex.status = "1";
          ex.username = "";

          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-user");
          await this.emptyField();
          await this.getListUserData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          // console.log("response add deu err ", error.response);
          await gpf.handleEds(error);
        });
      // if ( this.requiredField() ) {
      // }
    },
    async editUser(data: any) {
      await this.emptyField();
      this.section = "edit";

      gpf.gLoading.show();

      axios
        .get(api.adminDataUser + data.id, this.config)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          const rs = result.data.response;
          // console.log("response get deu ", rs);

          // console.log("rs.status.toLowerCase() ", rs.status.toLowerCase() , rs.status.toLowerCase() === "active")

          const ex = this.exDataUser;
          ex.id = rs.id;
          ex.name = rs.name;
          ex.email = rs.email;
          ex.phone = rs.phone;
          ex.roles = rs.role;
          rs.status.toLowerCase() === "active"
            ? (ex.status = "1")
            : (ex.status = "0");
          ex.username = rs.username;

          await gpf.showModal("#modal-data-user");
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          // console.log("response get deu err ", error.response);
          await gpf.handleEds(error);
        });
    },
    editUserApi(): void {
      gpf.gLoading.show();
      const ex = this.exDataUser;
      const body = {
        username: ex.username,
        password: ex.password ? ex.password : null,
        pin: ex.pin ? ex.pin : null,
        name: ex.name,
        email: ex.email,
        phone: ex.phone,
        status: ex.status,
        roles: [ex.roles],
      };

      axios
        .put(api.adminDataUser + ex.id, body, this.config)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          ex.name = "";
          ex.email = "";
          ex.phone = "";
          ex.roles = "";
          ex.status = "1";
          ex.username = "";

          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-user");
          await this.emptyField();
          await this.getListUserData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          // console.log("response get deu err ", error.response);
          await gpf.handleEds(error);
        });
      // if ( this.requiredField() ) {
      // }
    },
    showPass(id: string) {
      const idHtml = cash("#" + id);
      if (idHtml.attr("type") === "password") {
        idHtml.attr("type", "text");
      } else {
        idHtml.attr("type", "password");
      }
    },
  },
});
