
import { defineComponent, ref, reactive } from "vue";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";
import { dataKeyDS } from "./withdrawal";

export default defineComponent({
  setup() {
    const salesReportFilter = ref("");
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();
    const listKeyDS = dataKeyDS();

    const titleTable: Array<string> = [
      "Account Name / <br> Account Number",
      "Bank Name",
      "Amount",
      "Status",
      "Created",
    ];

    const dataAW = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
      dataSaldo: {
        komisi: 0,
        paidWithdrawal: 0,
        unpaidWithdrawal: 0,
        saldo: 0,
      } as any,
    });

    const dataSaldo = async () => {
      cash("#loadingSaldoAff").show();

      const ds = await api.getSaldo();
      dataAW.dataSaldo = ds;

      cash("#loadingSaldoAff").hide();
    };

    const getData = async () => {
      dataSaldo();
      cash("#loadingDMaff").show();

      const page = dataAW.page;
      const pageSize = dataAW.pageSize;
      const search = dataAW.search;
      const url =
        api.ownerWithdrawal +
        "?search=" +
        search +
        "&page=" +
        page +
        "&pageSize=" +
        pageSize;

      const hide = () => cash("#loadingDMaff").hide();
      const hitApi = await api.hitApiGet(url, hide);
      const ds = hitApi.data.diagnostic;
      const rs = hitApi.data.response;

      dataAW.data = rs;
      dataAW.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);

    };
    getData();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataAW.page = p;
      getData();
    };

    const nextPage = () => {
      dataAW.page = dataAW.page + 1;
      getData();
    };

    const beforePage = () => {
      dataAW.page = dataAW.page - 1;
      getData();
    };

    const lastPage = () => {
      dataAW.page = dataAW.paginate.totalPages;
      getData();
    };

    return {
      salesReportFilter,
      titleTable,
      getData,
      dataAW,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
      listKeyDS,
    };
  },
  data() {
    return {
      exDataAW: {
        id: "",
        pin: "",
        amount: "",
        bank: "",
      },
      listBank: [],
    };
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.exDataAW;
      ex[f] = val;
    },
    async emptyField() {
      const ex = this.exDataAW;
      ex.id = "0";
      ex.pin = "";
      ex.amount = "";
      ex.bank = "";
    },
    ctc(f: string) {
      const ex: any = this.exDataAW;
      ex[f] = gpf.ctc(ex[f]);
    },
    async addAW() {
      gpf.gLoading.show();
      const hide = () => gpf.gLoading.hide();

      await this.emptyField();
      const lb = await api.getAkunBank(hide);

      this.listBank = lb;
      this.exDataAW.bank = lb[0].id ? lb[0].id : "";
      // console.log("listBank ", this.listBank);
      gpf.showModal("#modal-data-aw");

    },
    async addAWApi() {
      gpf.gLoading.show();
      const hide = () => gpf.gLoading.hide();
      const ex = this.exDataAW;
      const params = new URLSearchParams();
      params.append("pin", ex.pin);
      params.append("amount", gpf.btc(ex.amount));
      params.append("bank", ex.bank);

      const hitApi = await api.hitApiPost(api.ownerWithdrawal, params, hide);
      await gpf.handleSds(hitApi);
      await gpf.hideModal("#modal-data-aw");
      await this.emptyField();
      await this.getData();
    },
  },
});
