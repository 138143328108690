import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import globalComponents from "./global-components";
import utils from "./utils";
import "./libs";

// Theme
import "@/assets/sass/app.scss";
import setupAuth from "./services/auth-init";
import { setupRoles } from "./utils/roles";
import { setupCurrentUserPackage } from "./utils/current-user-package";

setupAuth();
setupRoles();
setupCurrentUserPackage();

const app = createApp(App).use(store).use(router);

globalComponents(app);
utils(app);

app.mount("#app");
