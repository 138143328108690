type Files = {
  fileName: string;
  slug?: string | "not-found";
};

const dataPPPK: Array<Files> = [
  { fileName: "Tryout Kompetensi Teknis", slug: "tryout-kompetensi-teknis" },
  { fileName: "Tryout TBS (Penalaran)", slug: "tryout-tbs" },
  { fileName: "Tryout Manajerial", slug: "tryout-manajerialb" },
  { fileName: "Tryout Sosio Kultural", slug: "tryout-sosio-kultural" },
  { fileName: "Tryout Wawancara", slug: "tryout-wawancara" },
];

const filesPPPK = (): Array<Files> => {
  return dataPPPK.map((e) => {
    const obj = {
      fileName: `${e.fileName}`,
      slug: `${e.slug}`,
    };
    return obj;
  });
};

const dataSKD: Array<Files> = [
  { fileName: "Admin", slug: "admin" },
  { fileName: "Pengelola", slug: "pengelola" },
  { fileName: "Premium", slug: "premium" },
  { fileName: "Free", slug: "free" },
  { fileName: "Platinum", slug: "platinum" },
];

const filesSKD = (): Array<Files> => {
  return dataSKD;
};

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

type pplist = {
  peringkat: number;
  nomorTes: number;
  namaPeserta: string;
  skorTWK: number;
  skorTIU: number;
  skorTKP: number;
  totalSkor: number;
  status: string;
};

const dataList: pplist[] = [];
const setDataList = (): pplist[] => {
  for (let i = 0; i < 100; i++) {
    const twk: number = getRandomInt(1, 200);
    const tiu: number = getRandomInt(1, 100);
    const tkp: number = getRandomInt(1, 200);
    const total: number = twk + tiu + tkp;

    const obj = {
      peringkat: getRandomInt(1, 1000),
      nomorTes: getRandomInt(1, 10000),
      namaPeserta: "Test " + getRandomInt(1, 1000),
      skorTWK: twk,
      skorTIU: tiu,
      skorTKP: tkp,
      totalSkor: total,
      status: total > 350 ? "Lulus" : "Gagal",
    };
    dataList.push(obj);
  }

  return dataList;
};

type tv = {
  title: string,
  value: string,
}

const dataRoles = (): Array<tv> => {
  const obj: Array<tv> = [
    {
      title: "Member", value: "member",
    },
    {
      title: "Admin", value: "admin",
    },
    {
      title: "Keuangan", value: "keuangan",
    },
    {
      title: "Tentor", value: "tentor",
    },
    {
      title: "Affiliate", value: "affiliate",
    },
    {
      title: "Sales", value: "sales",
    },
    {
      title: "Owner", value: "owner",
    },
  ];
  return obj;
};

const dataStatus = (): Array<tv> => {
  const obj: Array<tv> = [
    {
      title: "Suspend", value: "0",
    },
    {
      title: "Active", value: "1",
    },
  ];
  return obj;
}

export { Files, pplist, filesPPPK, filesSKD, setDataList, dataRoles, dataStatus };
