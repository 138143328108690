import { getRole } from "@/utils/cookie-next";
import { computed, ref } from "vue";
import { RouteLocationRaw } from "vue-router";

export interface Breadcrumb {
  title: string;
  to?: RouteLocationRaw;
  disabled?: boolean;
}

export const breadcrumb = ref([
  {
    title: "Dashboard",
    to: "/" + getRole(),
    disabled: true,
  },
] as Breadcrumb[]);

export const listBreadcrumb = computed(() => breadcrumb.value);

export const dashboardPath = "/" + getRole();
