
import { defineComponent, ref, reactive } from "vue";
import { useRoute } from "vue-router";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import InlineEditor from "@ckeditor/ckeditor5-editor-inline/src/inlineeditor";
import BalloonEditor from "@ckeditor/ckeditor5-editor-balloon/src/ballooneditor";
import DocumentEditor from "@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import UnderlinePlugin from "@ckeditor/ckeditor5-basic-styles/src/underline";
import StrikethroughPlugin from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import CodePlugin from "@ckeditor/ckeditor5-basic-styles/src/code";
import SubscriptPlugin from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import SuperscriptPlugin from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import EasyImage from "@ckeditor/ckeditor5-easy-image/src/easyimage";
import Font from "@ckeditor/ckeditor5-font/src/font";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import HeadingButtonsUI from "@ckeditor/ckeditor5-heading/src/headingbuttonsui";
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight";

export default defineComponent({
  setup() {
    const route = useRoute();
    const slug: any = route.params.slug;
    const ld = api.getLoginData();
    const photoUser = ld.photo;

    const dataRC = reactive({
      detail: {
        id: "",
        title: "",
        content: "",
        tanggal: "",
        kategori: "",
        answers: [
          // {
          //   isMentor: true,
          //   tanggal: "2021-02-25 00:00:00",
          //   content: "test reply",
          // },
        ],
      },
      contentResponse: "",
    });

    const getData = async () => {
      gpf.gLoading.show();

      const hide = () => gpf.gLoading.hide();
      const url = api.adminKelasPrivate + slug;
      const hitApi = await api.hitApiGet(url, hide);
      const rs = hitApi.data.response;

      dataRC.detail = rs;
    };
    getData();

    const tambahResponse = async () => {
      gpf.gLoading.show();

      const hide = () => gpf.gLoading.hide();
      const idClass = dataRC.detail.id;
      const content = dataRC.contentResponse;
      const params = new URLSearchParams();
      params.append("idClass", idClass);
      params.append("content", content);

      const url = api.adminKelasPrivate;
      await api.hitApiPut(url, params, hide);
      dataRC.contentResponse = "";
      await getData();
    };

    // BEGIN for CKEditor
    const date = ref("");
    const classicEditor = ClassicEditor;
    const inlineEditor = InlineEditor;
    const balloonEditor = BalloonEditor;
    const documentEditor = DocumentEditor;
    const simpleEditorConfig = {
      plugins: [
        ParagraphPlugin,
        BoldPlugin,
        UnderlinePlugin,
        ItalicPlugin,
        LinkPlugin,
      ],
      toolbar: {
        items: ["bold", "italic", "underline", "link"],
      },
    };
    const editorConfig = {
      cloudServices: {
        tokenUrl: "",
        uploadUrl: "",
      },
      plugins: [
        Font,
        EssentialsPlugin,
        BoldPlugin,
        UnderlinePlugin,
        StrikethroughPlugin,
        ItalicPlugin,
        LinkPlugin,
        ParagraphPlugin,
        CodePlugin,
        SubscriptPlugin,
        SuperscriptPlugin,
        EasyImage,
        Heading,
        HeadingButtonsUI,
        Highlight,
      ],
      toolbar: {
        items: [
          "fontSize",
          "fontFamily",
          "fontColor",
          "fontBackgroundColor",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "code",
          "subscript",
          "superscript",
          "link",
          "undo",
          "redo",
          "imageUpload",
          "highlight",
        ],
      },
    };
    const editorData = ref("<p>Content of the editor.</p>");
    const initDocumentEditor = (editor: any) => {
      const toolbarContainer = document.querySelector(
        ".document-editor__toolbar"
      );
      toolbarContainer?.appendChild(editor.ui.view.toolbar.element);
      const win: any = window;
      win.editor = editor;
    };
    // END for CKEditor

    return {
      dataRC,
      getData,
      tambahResponse,
      photoUser,

      // for CKEditor
      date,
      classicEditor,
      inlineEditor,
      balloonEditor,
      documentEditor,
      simpleEditorConfig,
      editorConfig,
      editorData,
      initDocumentEditor,
    };
  },
  data() {
    return {};
  },
  methods: {},
});
