type tv = {
  title: string,
  value: string,
};

const dataPaket = (): Array<tv> => {
  const obj: Array<tv> = [
    {
      title: "CPNS", value: "cpns",
    },
    {
      title: "PPPK", value: "pppk",
    },
  ];
  return obj;
};

const dataSifatSoal = (): Array<tv> => {
  const obj: Array<tv> = [
    {
      title: "Umum", value: "umum",
    },
    {
      title: "Bidang", value: "bidang",
    },
  ];
  return obj;
};

const dataStatus = (): Array<tv> => {
  const obj: Array<tv> = [
    {
      title: "Suspend", value: "0",
    },
    {
      title: "Active", value: "1",
    },
  ];
  return obj;
}

export { dataPaket, dataSifatSoal, dataStatus };
