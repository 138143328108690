import { VueCookieNext as $ck } from "vue-cookie-next";
import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import { api } from "../utils/api-config";
import { defineAsyncComponent } from "vue";

// role admin
import DataAffiliateAdmin from "../views/admin/data-affiliate/Main.vue";
import DataKomisiAdmin from "../views/admin/data-komisi/Main.vue";
import DataMateri from "../views/admin/data-materi/Main.vue";
import DataPemasukan from "../views/admin/data-pemasukan/Main.vue";
import DataUser from "../views/admin/data-user/Main.vue";
import HasilTryoutAdmin from "../views/admin/hasil-tryout/Main.vue";
import JenisSoal from "../views/admin/jenis-soal/Main.vue";
import KategoriSoal from "../views/admin/kategori-soal/Main.vue";
import KelasPrivateAdmin from "../views/admin/kelas-private-admin/Main.vue";
import KelasWebinarAdmin from "../views/admin/kelas-webinar-admin/Main.vue";
import LeaderboardAdmin from "../views/admin/leaderboard/Main.vue";
import MarketingKitAdmin from "../views/admin/marketing-kit/Main.vue";
import MasterSoal from "../views/admin/master-soal/Main.vue";
import PaketMemberAdmin from "../views/admin/paket-member/Main.vue";
import Pengeluaran from "../views/admin/pengeluaran/Main.vue";
import ResponChatAdmin from "../views/admin/respon-chat-admin/Main.vue";
import SaldoAffAdmin from "../views/admin/saldo-affiliate/Main.vue";
import WithdrawalAdmin from "../views/admin/withdrawal/Main.vue";

// role-affiliate
import DashboardAffiliate from "../views/affiliate/dashboard-affiliate/Main.vue";
import AffKomisi from "../views/affiliate/komisi/Main.vue";
import AffLeaderboard from "../views/affiliate/leaderboard/Main.vue";
import AffLinkReferral from "../views/affiliate/link-referral/Main.vue";
import AffMarketingKit from "../views/affiliate/marketing-kit/Main.vue";
import AffMember from "../views/affiliate/member/Main.vue";
import AffOrder from "../views/affiliate/order/Main.vue";
import AffPengaturan from "../views/affiliate/pengaturan/Main.vue";
import AffSales from "../views/affiliate/sales/Main.vue";
import AffWithdrawal from "../views/affiliate/withdrawal/Main.vue";

// role-owner
import AkunBank from "../views/owner/akun-bank/Main.vue";
import OwnerAffiliate from "../views/owner/affiliate/Main.vue";
import DashboardOwner from "../views/owner/dashboard-owner/Main.vue";
import DataKomisi from "../views/owner/data-komisi/Main.vue";
import OwnerLeaderboard from "../views/owner/leaderboard/Main.vue";
import OwnerWithdrawal from "../views/owner/withdrawal/Main.vue";

// role-sales
import SalesBonus from "../views/sales/bonus/Main.vue";
import DashboardSales from "../views/sales/dashboard-sales/Main.vue";
import SalesLB from "../views/sales/leaderboard/Main.vue";
import SalesMK from "../views/sales/marketing-kit/Main.vue";
import SalesMember from "../views/sales/member/Main.vue";
import SalesOrder from "../views/sales/order/Main.vue";
import SalesWithdrawal from "../views/sales/withdrawal/Main.vue";

// role-tentor

import CatOnlineTentor from "../views/tentor/cat-online/Main.vue";
import CatRiwayatTentor from "../views/tentor/cat-riwayat/Main.vue";
import DashboardTentor from "../views/tentor/dashboard-tentor/Main.vue";
import KelasPrivateTentor from "../views/tentor/kelas-private/Main.vue";
import BankSoalTentor from "../views/tentor/master-soal/Main.vue";
import ResponChatTentor from "../views/tentor/respon-chat/Main.vue";
import TryoutExamTentor from "../views/tentor/tryout-exam/Main.vue";
import TryoutHasilTentor from "../views/tentor/tryout-hasil/Main.vue";

// START === components for public page =================================================================
const SideMenu = defineAsyncComponent(
  () => import(`@/layouts/side-menu/Main.vue`)
);
const ErrorPage = defineAsyncComponent(
  () => import(`@/views/error-page/Main.vue`)
);
const InProgressPage = defineAsyncComponent(
  () => import(`@/views/in-progress/Main.vue`)
);
const Profile = defineAsyncComponent(() => import(`@/views/profile/Main.vue`));
const Register = defineAsyncComponent(
  () => import(`@/views/register/Main.vue`)
);
const Login = defineAsyncComponent(() => import(`@/views/login/Main.vue`));
// END === components for public page ===================================================================

// START === components for member page =================================================================
const MemberDashboard = defineAsyncComponent(
  () => import(`@/views/member/dashboard-member/index.vue`)
);
const MemberMateri = defineAsyncComponent(
  () => import(`@/views/member/materi/index.vue`)
);
const MateriPenunjang = defineAsyncComponent(
  () => import(`@/views/member/materi-penunjang/Main.vue`)
);
const CatCpns = defineAsyncComponent(
  () => import(`@/views/member/cat-cpns/Main.vue`)
);
const CatPeringkat = defineAsyncComponent(
  () => import(`@/views/member/cat-peringkat/Main.vue`)
);
const CatPppk = defineAsyncComponent(
  () => import(`@/views/member/cat-pppk/Main.vue`)
);
const CatRiwayat = defineAsyncComponent(
  () => import(`@/views/member/cat-riwayat/Main.vue`)
);
const CekGaransi = defineAsyncComponent(
  () => import(`@/views/member/cek-garansi/Main.vue`)
);
const FormKomplain = defineAsyncComponent(
  () => import(`@/views/member/form-komplain/Main.vue`)
);
const KelasPrivate = defineAsyncComponent(
  () => import(`@/views/member/kelas-private/Main.vue`)
);
const KelasWebinar = defineAsyncComponent(
  () => import(`@/views/member/kelas-webinar/Main.vue`)
);
const DetailMateri = defineAsyncComponent(
  () => import(`@/views/member/materi-detail/Main.vue`)
);
const ResponChat = defineAsyncComponent(
  () => import(`@/views/member/respon-chat/Main.vue`)
);
const TipsAndTrik = defineAsyncComponent(
  () => import(`@/views/member/tips-and-trik/Main.vue`)
);
const TryoutExam = defineAsyncComponent(
  () => import(`@/views/member/tryout-exam/Main.vue`)
);
const TryoutHasil = defineAsyncComponent(
  () => import(`@/views/member/tryout-hasil/Main.vue`)
);
const Tutorial = defineAsyncComponent(
  () => import(`@/views/member/tutorial/Main.vue`)
);
const UpgradePaket = defineAsyncComponent(
  () => import(`@/views/member/upgrade-paket/Main.vue`)
);
const UpgradePaketInvoice = defineAsyncComponent(
  () => import(`@/views/member/upgrade-paket/Invoice.vue`)
);
// END === components for member page ===================================================================

// END === components for admin page ===================================================================
const AdminDashboard = defineAsyncComponent(
  () => import(`@/views/admin/dashboard-admin/Main.vue`)
);
const AdminBidangSoal = defineAsyncComponent(
  () => import(`@/views/admin/bidang-soal/Main.vue`)
);
const AdminBlogArtikel = defineAsyncComponent(
  () => import(`@/views/admin/blog-artikel/Main.vue`)
);
const AdminBlogKategori = defineAsyncComponent(
  () => import(`@/views/admin/blog-kategori/Main.vue`)
);
const AdminBlogTag = defineAsyncComponent(
  () => import(`@/views/admin/blog-tag/Main.vue`)
);
// END === components for admin page ===================================================================

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: Login,
  },
  {
    path: "/member",
    component: SideMenu,
    children: [
      {
        path: "",
        name: "member-dashboard",
        component: MemberDashboard,
      },
      {
        path: "materi",
        name: "member-materi",
        component: MemberMateri,
      },
      {
        path: "materi/:categoryCode",
        name: "member-materi-topics",
        component: async () => await import(`@/views/member/materi/topics.vue`),
      },
      {
        path: "materi/:categoryCode/:type",
        name: "member-materi-ebooks-and-videos",
        component: async () =>
          await import(`@/views/member/materi/ebooks-and-videos.vue`),
      },
      {
        path: "materi-ebook/:slug",
        name: "member-materi-view-ebook",
        component: async () =>
          await import(`@/views/member/materi/view-ebook.vue`),
      },
      {
        path: "materi-video/:slug",
        name: "member-materi-view-video",
        component: async () =>
          await import(`@/views/member/materi/view-video.vue`),
      },
      {
        path: "materi-penunjang",
        name: "member-materi-penunjang",
        component: MateriPenunjang,
      },
      {
        path: "materi-penunjang/:slug",
        name: "member-detail-materi-penunjang",
        component: DetailMateri,
      },
      {
        path: "tips-and-trik",
        name: "member-tips-and-trik",
        component: TipsAndTrik,
      },
      {
        path: "tips-and-trik/:slug",
        name: "member-detail-tips-and-trik",
        component: DetailMateri,
      },
      {
        path: "cat-pppk",
        name: "member-cat-pppk",
        component: CatPppk,
      },
      {
        path: "cat-pppk/:slug",
        name: "member-cat-pppk-exam",
        component: TryoutExam,
      },
      {
        path: "cat-pppk/:slug/hasil",
        name: "member-cat-pppk-hasil",
        component: TryoutHasil,
      },
      {
        path: "cat-cpns",
        name: "member-cat-cpns",
        component: CatCpns,
      },
      {
        path: "cat-cpns/:slug",
        name: "member-cat-cpns-exam",
        component: TryoutExam,
      },
      {
        path: "cat-cpns/:slug/hasil",
        name: "member-cat-cpns-hasil",
        component: TryoutHasil,
      },
      {
        path: "riwayat-cat",
        name: "member-riwayat-cat",
        component: CatRiwayat,
      },
      {
        path: "riwayat-cat/:slug/hasil",
        name: "member-riwayat-cat-hasil",
        component: TryoutHasil,
      },
      {
        path: "riwayat-cat/hasil",
        name: "member-riwayat-cat-hasil-new",
        component: TryoutHasil,
      },
      {
        path: "peringkat-cat",
        name: "member-peringkat-cat",
        component: CatPeringkat,
      },
      {
        path: "kelas-private",
        name: "member-kelas-private",
        component: KelasPrivate,
      },
      {
        path: "kelas-private/:slug",
        name: "member-kelas-private-respon",
        component: ResponChat,
      },
      {
        path: "kelas-webinar",
        name: "member-kelas-webinar",
        component: KelasWebinar,
      },
      {
        path: "form-komplain",
        name: "member-form-komplain",
        component: FormKomplain,
      },
      {
        path: "form-komplain/:slug",
        name: "member-form-komplain-respon",
        component: ResponChat,
      },
      {
        path: "cek-garansi",
        name: "member-cek-garansi",
        component: CekGaransi,
      },
      {
        path: "in-progress",
        name: "member-tutorial",
        component: InProgressPage,
      },
      {
        path: "upgrade-akun",
        name: "member-upgrade-akun",
        redirect: {
          name: "member-upgrade-paket",
        },
      },
      {
        path: "upgrade-paket",
        name: "member-upgrade-paket",
        component: UpgradePaket,
      },
      {
        path: "upgrade-paket/invoice/:slug",
        name: "member-upgrade-paket-invoice",
        component: UpgradePaketInvoice,
      },
      {
        path: "tutorial",
        name: "member-tutorial",
        component: Tutorial,
      },
      {
        path: "akun-bank",
        name: "member-akun-bank",
        component: AkunBank,
      },
      {
        path: "profile",
        name: "member-profile",
        component: Profile,
      },
    ],
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: any
    ) => {
      if ($ck.isCookieAvailable("login-data") && api.getRole() === "member") {
        if (api.getRole() === "member") {
          next();
        } else {
          next({ name: "login" });
        }
      } else {
        next({ name: "login" });
      }
    },
  },
  {
    path: "/admin",
    component: SideMenu,
    children: [
      {
        path: "",
        name: "admin-dashboard",
        component: AdminDashboard,
      },
      {
        path: "data-user",
        name: "admin-data-user",
        component: DataUser,
      },
      {
        path: "data-materi",
        name: "admin-data-materi",
        component: DataMateri,
      },
      {
        path: "data-categories",
        name: "admin-data-categories",
        component: async () => {
          const AdminDataCategories = await import(
            `@/views/admin/data-categories/Main.vue`
          );
          return AdminDataCategories;
        },
      },
      {
        path: "data-topics",
        name: "admin-data-topics",
        component: async () => {
          const AdminDataTopics = await import(
            `@/views/admin/data-topics/Main.vue`
          );
          return AdminDataTopics;
        },
      },
      {
        path: "jenis-soal",
        name: "admin-jenis-soal",
        component: JenisSoal,
      },
      {
        path: "kategori-soal",
        name: "admin-kategori-soal",
        component: KategoriSoal,
      },
      {
        path: "bidang-soal",
        name: "admin-bidang-soal",
        component: AdminBidangSoal,
      },
      {
        path: "master-soal",
        name: "admin-master-soal",
        component: MasterSoal,
      },
      {
        path: "pemasukan",
        name: "admin-pemasukan",
        component: DataPemasukan,
      },
      {
        path: "pengeluaran",
        name: "admin-pengeluaran",
        component: Pengeluaran,
      },
      {
        path: "withdrawal",
        name: "admin-withdrawal",
        component: WithdrawalAdmin,
      },
      {
        path: "kelas-private",
        name: "admin-kelas-private",
        component: KelasPrivateAdmin,
      },
      {
        path: "kelas-private/:slug",
        name: "admin-kelas-private-respon",
        component: ResponChatAdmin,
      },
      {
        path: "kelas-webinar",
        name: "admin-kelas-webinar",
        component: KelasWebinarAdmin,
      },
      {
        path: "form-komplain",
        name: "admin-form-komplain",
        component: InProgressPage,
        // component: MasterSoal,
      },
      {
        path: "news-update",
        name: "admin-news-update",
        component: InProgressPage,
        // component: MasterSoal,
      },
      {
        path: "data-affiliate",
        name: "admin-data-affiliate",
        component: DataAffiliateAdmin,
      },
      {
        path: "marketing-kit",
        name: "admin-marketing-kit",
        component: MarketingKitAdmin,
      },
      {
        path: "peringkat",
        name: "admin-peringkat",
        component: LeaderboardAdmin,
      },
      {
        path: "data-komisi",
        name: "admin-data-komisi",
        component: DataKomisiAdmin,
      },
      {
        path: "hasil-tryout",
        name: "admin-hasil-tryout",
        component: HasilTryoutAdmin,
      },
      {
        path: "saldo-affiliate",
        name: "admin-saldo-affiliate",
        component: SaldoAffAdmin,
      },
      {
        path: "paket-member",
        name: "admin-paket-member",
        component: PaketMemberAdmin,
      },
      {
        path: "akun-bank",
        name: "owner-akun-bank",
        component: AkunBank,
      },
      {
        path: "profile",
        name: "admin-profile",
        component: Profile,
      },
      {
        path: "blog/artikel",
        name: "admin-artikel",
        component: AdminBlogArtikel,
      },
      {
        path: "blog/tag",
        name: "admin-tag",
        component: AdminBlogTag,
      },
      {
        path: "blog/kategori",
        name: "admin-kategori",
        component: AdminBlogKategori,
      },
    ],
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: any
    ) => {
      if ($ck.isCookieAvailable("login-data")) {
        if (api.getRole() === "admin") {
          next();
        } else {
          next({ name: "login" });
        }
      } else {
        next({ name: "login" });
      }
    },
  },
  {
    path: "/keuangan",
    component: SideMenu,
    children: [
      {
        path: "",
        name: "keuangan-dashboard",
        component: InProgressPage,
      },
    ],
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: any
    ) => {
      if ($ck.isCookieAvailable("login-data")) {
        if (api.getRole() === "keuangan") {
          next();
        } else {
          next({ name: "login" });
        }
      } else {
        next({ name: "login" });
      }
    },
  },
  {
    path: "/tentor",
    component: SideMenu,
    children: [
      {
        path: "",
        name: "tentor-dashboard",
        component: DashboardTentor,
      },
      {
        path: "bank-soal",
        name: "tentor-bank-soal",
        component: BankSoalTentor,
      },
      {
        path: "kelas-private",
        name: "tentor-kelas-private",
        component: KelasPrivateTentor,
      },
      {
        path: "kelas-private/:slug",
        name: "tentor-kelas-private-respon",
        component: ResponChatTentor,
      },
      {
        path: "cat-online",
        name: "tentor-cat-online",
        component: CatOnlineTentor,
      },
      {
        path: "cat-online/:slug",
        name: "tentor-cat-online-exam",
        component: TryoutExamTentor,
      },
      {
        path: "cat-online/:slug/hasil",
        name: "tentor-cat-online-hasil",
        component: TryoutHasilTentor,
      },
      {
        path: "riwayat-cat",
        name: "tentor-riwayat-cat",
        component: CatRiwayatTentor,
      },
    ],
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: any
    ) => {
      if ($ck.isCookieAvailable("login-data")) {
        if (api.getRole() === "tentor") {
          next();
        } else {
          next({ name: "login" });
        }
      } else {
        next({ name: "login" });
      }
    },
  },
  {
    path: "/affiliate",
    component: SideMenu,
    children: [
      {
        path: "",
        name: "affiliate-dashboard",
        component: DashboardAffiliate,
      },
      {
        path: "member",
        name: "affiliate-member",
        component: AffMember,
      },
      {
        path: "sales",
        name: "affiliate-sales",
        component: AffSales,
      },
      {
        path: "order",
        name: "affiliate-order",
        component: AffOrder,
      },
      {
        path: "komisi",
        name: "affiliate-komisi",
        component: AffKomisi,
      },
      {
        path: "withdrawal",
        name: "affiliate-withdrawal",
        component: AffWithdrawal,
      },
      {
        path: "marketing-kit",
        name: "affiliate-marketing-kit",
        component: AffMarketingKit,
      },
      {
        path: "leaderboard",
        name: "affiliate-leaderboard",
        component: AffLeaderboard,
      },
      {
        path: "pengaturan",
        name: "affiliate-pengaturan",
        component: AffPengaturan,
      },
      {
        path: "akun-bank",
        name: "affiliate-akun-bank",
        component: AkunBank,
      },
      {
        path: "link-referral",
        name: "affiliate-link-referral",
        component: AffLinkReferral,
      },
      {
        path: "profile",
        name: "affiliate-profile",
        component: Profile,
      },
      {
        path: "materi-penunjang",
        name: "affiliate-materi-penunjang",
        component: MateriPenunjang,
      },
      {
        path: "materi-penunjang/:slug",
        name: "affiliate-detail-materi-penunjang",
        component: DetailMateri,
      },
      {
        path: "tips-and-trik",
        name: "affiliate-tips-and-trik",
        component: TipsAndTrik,
      },
      {
        path: "tips-and-trik/:slug",
        name: "affiliate-detail-tips-and-trik",
        component: DetailMateri,
      },
      {
        path: "cat-pppk",
        name: "affiliate-cat-pppk",
        component: CatPppk,
      },
      {
        path: "cat-pppk/:slug",
        name: "affiliate-cat-pppk-exam",
        component: TryoutExam,
      },
      {
        path: "cat-pppk/:slug/hasil",
        name: "affiliate-cat-pppk-hasil",
        component: TryoutHasil,
      },
      {
        path: "cat-cpns",
        name: "affiliate-cat-cpns",
        component: CatCpns,
      },
      {
        path: "cat-cpns/:slug",
        name: "affiliate-cat-cpns-exam",
        component: TryoutExam,
      },
      {
        path: "cat-cpns/:slug/hasil",
        name: "affiliate-cat-cpns-hasil",
        component: TryoutHasil,
      },
      {
        path: "riwayat-cat",
        name: "affiliate-riwayat-cat",
        component: CatRiwayat,
      },
      {
        path: "riwayat-cat/:slug/hasil",
        name: "affiliate-riwayat-cat-hasil",
        component: TryoutHasil,
      },
      {
        path: "peringkat-cat",
        name: "affiliate-peringkat-cat",
        component: CatPeringkat,
      },
      {
        path: "kelas-private",
        name: "affiliate-kelas-private",
        component: KelasPrivate,
      },
      {
        path: "kelas-private/:slug",
        name: "affiliate-kelas-private-respon",
        component: ResponChat,
      },
      {
        path: "kelas-webinar",
        name: "affiliate-kelas-webinar",
        component: KelasWebinar,
      },
    ],
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: any
    ) => {
      if ($ck.isCookieAvailable("login-data")) {
        if (api.getRole() === "affiliate") {
          next();
        } else {
          next({ name: "login" });
        }
      } else {
        next({ name: "login" });
      }
    },
  },
  {
    path: "/sales",
    component: SideMenu,
    children: [
      {
        path: "",
        name: "sales-dashboard",
        component: DashboardSales,
      },
      {
        path: "member",
        name: "sales-member",
        component: SalesMember,
      },
      {
        path: "order",
        name: "sales-order",
        component: SalesOrder,
      },
      {
        path: "bonus",
        name: "sales-bonus",
        component: SalesBonus,
      },
      {
        path: "withdrawal",
        name: "sales-withdrawal",
        component: SalesWithdrawal,
      },
      {
        path: "marketing-kit",
        name: "sales-marketing-kit",
        component: SalesMK,
      },
      {
        path: "leaderboard",
        name: "sales-leaderboard",
        component: SalesLB,
      },
      {
        path: "pengaturan",
        name: "sales-pengaturan",
        component: InProgressPage,
      },
      {
        path: "akun-bank",
        name: "sales-akun-bank",
        component: AkunBank,
      },
      {
        path: "profile",
        name: "sales-profile",
        component: Profile,
      },
      {
        path: "materi-penunjang",
        name: "sales-materi-penunjang",
        component: MateriPenunjang,
      },
      {
        path: "materi-penunjang/:slug",
        name: "sales-detail-materi-penunjang",
        component: DetailMateri,
      },
      {
        path: "tips-and-trik",
        name: "sales-tips-and-trik",
        component: TipsAndTrik,
      },
      {
        path: "tips-and-trik/:slug",
        name: "sales-detail-tips-and-trik",
        component: DetailMateri,
      },
      {
        path: "cat-pppk",
        name: "sales-cat-pppk",
        component: CatPppk,
      },
      {
        path: "cat-pppk/:slug",
        name: "sales-cat-pppk-exam",
        component: TryoutExam,
      },
      {
        path: "cat-pppk/:slug/hasil",
        name: "sales-cat-pppk-hasil",
        component: TryoutHasil,
      },
      {
        path: "cat-cpns",
        name: "sales-cat-cpns",
        component: CatCpns,
      },
      {
        path: "cat-cpns/:slug",
        name: "sales-cat-cpns-exam",
        component: TryoutExam,
      },
      {
        path: "cat-cpns/:slug/hasil",
        name: "sales-cat-cpns-hasil",
        component: TryoutHasil,
      },
      {
        path: "riwayat-cat",
        name: "sales-riwayat-cat",
        component: CatRiwayat,
      },
      {
        path: "riwayat-cat/:slug/hasil",
        name: "sales-riwayat-cat-hasil",
        component: TryoutHasil,
      },
      {
        path: "peringkat-cat",
        name: "sales-peringkat-cat",
        component: CatPeringkat,
      },
      {
        path: "kelas-private",
        name: "sales-kelas-private",
        component: KelasPrivate,
      },
      {
        path: "kelas-private/:slug",
        name: "sales-kelas-private-respon",
        component: ResponChat,
      },
      {
        path: "kelas-webinar",
        name: "sales-kelas-webinar",
        component: KelasWebinar,
      },
    ],
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: any
    ) => {
      if ($ck.isCookieAvailable("login-data")) {
        if (api.getRole() === "sales") {
          next();
        } else {
          next({ name: "login" });
        }
      } else {
        next({ name: "login" });
      }
    },
  },
  {
    path: "/owner",
    component: SideMenu,
    children: [
      {
        path: "",
        name: "owner-dashboard",
        component: DashboardOwner,
      },
      {
        path: "affiliate",
        name: "owner-affiliate",
        component: OwnerAffiliate,
      },
      {
        path: "komisi",
        name: "owner-komisi",
        component: DataKomisi,
      },
      {
        path: "withdrawal",
        name: "owner-withdrawal",
        component: OwnerWithdrawal,
      },
      {
        path: "leaderboard",
        name: "owner-leaderboard",
        component: OwnerLeaderboard,
      },
      {
        path: "akun-bank",
        name: "owner-akun-bank",
        component: AkunBank,
      },
      {
        path: "profile",
        name: "owner-profile",
        component: Profile,
      },
    ],
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: any
    ) => {
      if ($ck.isCookieAvailable("login-data")) {
        if (api.getRole() === "owner") {
          next();
        } else {
          next({ name: "login" });
        }
      } else {
        next({ name: "login" });
      }
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: any
    ) => {
      if ($ck.isCookieAvailable("login-data") === false) {
        next();
      } else {
        router.push("/" + api.getRole());
      }
    },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: any
    ) => {
      if ($ck.isCookieAvailable("login-data") === false) {
        next();
      } else {
        router.push("/" + api.getRole());
      }
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

export default router;
