type tvIcon = {
  title: string;
  value: string;
  icon: string;
  color: string;
};

const dataKeyDS = (): Array<tvIcon> => {
  const obj: Array<tvIcon> = [
    {
      title: "Saldo",
      value: "saldo",
      icon: "DollarSignIcon",
      color: "text-theme-6",
    },
    {
      title: "Komisi",
      value: "komisi",
      icon: "CreditCardIcon",
      color: "text-primary",
    },
    {
      title: "Paid Withdrawal",
      value: "paidWithdrawal",
      icon: "ShoppingBagIcon",
      color: "text-theme-9",
    },
    {
      title: "Unpaid Withdrawal",
      value: "unpaidWithdrawal",
      icon: "ShoppingCartIcon",
      color: "text-theme-11",
    },
  ];
  return obj;
};

export { dataKeyDS };
