import { VueCookieNext } from "vue-cookie-next";

const cookie = VueCookieNext;

export const setCookie = (title: string, data: any) => {
  return cookie.setCookie(title, data);
};

export const getLoginData = () => {
  return cookie.getCookie("login-data");
};

export const getRole = () => {
  const role = cookie.getCookie("role-data");
  return role;
};
