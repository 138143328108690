
import { defineComponent, ref, reactive } from "vue";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";

export default defineComponent({
  setup() {
    const salesReportFilter = ref("");
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();

    const titleTable: Array<string> = [
      "Kode",
      "Nama",
      "Jenis",
      "Total Skor",
      "Status",
      "Tanggal",
      "#",
    ];

    const dataHT = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      type: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
      listTypeTryout: [] as any,
    });

    const getData = async () => {
      cash("#loadingHT").show();

      const page = dataHT.page;
      const pageSize = dataHT.pageSize;
      const search = dataHT.search;
      const type = dataHT.type;
      const url =
        api.adminHasilTryout +
        "?search=" +
        search +
        "&page=" +
        page +
        "&pageSize=" +
        pageSize +
        "&type=" +
        type;

      const hide = () => cash("#loadingHT").hide();
      const hitApi = await api.hitApiGet(url, hide);
      const ds = hitApi.data.diagnostic;
      const rs = hitApi.data.response;

      dataHT.data = rs;
      dataHT.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);
    };

    const getListTypeTryout = async () => {
      gpf.gLoading.show();

      const rs = await api.getJenisTryout();

      dataHT.type = rs[0].kode;
      dataHT.listTypeTryout = rs;

      await getData();
      gpf.gLoading.hide();
    };
    getListTypeTryout();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataHT.page = p;
      getData();
    };

    const nextPage = () => {
      dataHT.page = dataHT.page + 1;
      getData();
    };

    const beforePage = () => {
      dataHT.page = dataHT.page - 1;
      getData();
    };

    const lastPage = () => {
      dataHT.page = dataHT.paginate.totalPages;
      getData();
    };

    return {
      salesReportFilter,
      titleTable,
      getData,
      dataHT,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
    };
  },
  data() {
    return {
      exDetailHT: {
        type: "",
        bidang: "",
        startDate: "",
        endDate: "",
        summary: {
          maksSkor: 0,
          totalSkor: 0,
          jmlBenar: 0,
          jmlSkip: 0,
          jmlSalah: 0,
          status: "",
        },
        hasil: [],
      },
    };
  },
  methods: {
    async detailHTApi(data: any) {
      gpf.gLoading.show();
      const hide = () => gpf.gLoading.hide();

      const url = api.adminHasilTryout + data.kode;
      const hitApi = await api.hitApiGet(url, hide);
      const rs = hitApi.data.response;

      this.exDetailHT = rs;
      await gpf.showModal("#modal-detail-ht");
    },
  },
});
