
import { defineComponent, reactive } from "vue";
import { api } from "@/utils/api-config";

export default defineComponent({
  setup() {
    const dataDB = reactive({
      data: [] as any,
      dateRange: "",
    });

    const getData = async () => {
      cash("#loadingDT").show();

      const url = api.tentorDashboard;

      const hide = () => cash("#loadingDT").hide();
      const hitApi = await api.hitApiGet(url, hide);
      const rs = hitApi.data.response;

      dataDB.data = rs;

      // console.log("getData clicked");
    };
    getData();

    return {
      dataDB,
      getData,
    };
  },
  data() {
    return {};
  },
});
