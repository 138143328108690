
import {
  defineComponent,
  defineAsyncComponent,
  onMounted,
  reactive,
  ref,
} from "vue";
import { dataStatus, dataPayType, dataPayStatus } from "./data-pemasukan";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";
import { helper as $h } from "@/utils/helper";
import axios from "axios";
import { getRole } from "@/utils/cookie-next";
import { Breadcrumb } from "@/components/top-bar";
import { setBreadcrumb } from "@/utils/breadcrumb";
import { formatDate } from "@/utils/date-format";

const ModalDetailPemasukan = defineAsyncComponent(
  () => import("./components/modal-detail-pemasukan.vue")
);

const ModalEditPemasukan = defineAsyncComponent(
  () => import("./components/modal-edit-pemasukan.vue")
);

export default defineComponent({
  components: {
    ModalDetailPemasukan,
    ModalEditPemasukan,
  },
  setup() {
    // START --- setup breadcrumb -------------------------------------------------
    const dashboardPath = "/" + getRole();
    const breadcrumb: Breadcrumb[] = [
      {
        title: "Dashboard",
        to: dashboardPath,
      },
      {
        title: "Data Pemasukan",
        to: `${dashboardPath}/pemasukan`,
        disabled: true,
      },
    ];

    onMounted(() => {
      setBreadcrumb(breadcrumb);
    });
    // END --- setup breadcrumb ---------------------------------------------------

    const configGet = api.configGet();
    const configXwfu = api.configXwfu();
    const listStatus = dataStatus();
    const listPayType = dataPayType();
    const listPayStatus = dataPayStatus();

    const titleTable: Array<string> = [
      "Invoice",
      "Member",
      "Price",
      "Referal",
      "Paket",
      "Payment Type / <br> Payment Status",
      "Created At / <br> Accepted At",
      "Status",
      "#",
    ];

    const dataDP = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
      dateRange: "",
    });

    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    dataDP.dateRange = `${$h.formatDate(
      firstDay.toString(),
      "DD MMM, YYYY"
    )} - ${$h.formatDate(lastDay.toString(), "DD MMM, YYYY")}`;

    const getData = async () => {
      cash("#loadingDPadmin").show();

      // console.log("dateRange ", dataDP.dateRange);
      // console.log("firstDay lastDay ", firstDay, lastDay);

      const page = dataDP.page;
      const pageSize = dataDP.pageSize;
      const search = dataDP.search;
      const since = $h.formatDate(dataDP.dateRange.split("-")[0], "YYYY-MM-DD");
      const until = $h.formatDate(dataDP.dateRange.split("-")[1], "YYYY-MM-DD");
      axios
        .get(
          api.adminPemasukan +
            "?search=" +
            search +
            "&page=" +
            page +
            "&pageSize=" +
            pageSize +
            "&since=" +
            since +
            "&until=" +
            until,
          configGet
        )
        .then(async (result: any) => {
          const ds = result.data.diagnostic;
          const rs = result.data.response;
          // console.log("response get du ", rs);

          dataDP.data = rs.map((e: any) => {
            e.price = gpf.convertToCurrency(e.price, "IDR ");
            return e;
          });
          dataDP.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);
          // console.log("dataDP.paginate ", dataDP.paginate);
          cash("#loadingDPadmin").hide();
        })
        .catch(async (error: any) => {
          cash("#loadingDPadmin").hide();
          // console.log("response get du err ", error.response);
          await gpf.handleEds(error);
        });
    };
    getData();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataDP.page = p;
      getData();
    };

    const nextPage = () => {
      dataDP.page = dataDP.page + 1;
      getData();
    };

    const beforePage = () => {
      dataDP.page = dataDP.page - 1;
      getData();
    };

    const lastPage = () => {
      dataDP.page = dataDP.paginate.totalPages;
      getData();
    };

    const invoiceNumber = ref("" as string);
    const showDetailPemasukan = (id: string) => {
      invoiceNumber.value = id;

      gpf.showModal("#modal-detail-pemasukan");
    };

    const showEditPemasukan = (id: string) => {
      invoiceNumber.value = id;

      setTimeout(() => {
        gpf.showModal("#modal-edit-pemasukan");
      }, 770);
    };

    return {
      titleTable,
      getData,
      dataDP,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
      listStatus,
      listPayType,
      listPayStatus,
      gpf,
      formatDate,

      invoiceNumber,
      showDetailPemasukan,
      showEditPemasukan,
    };
  },
  data() {
    return {
      // for modal data bs
      section: "add",
      msgSuccess: "",

      exDataDP: {
        invoice: "",
        price: "",
        uniqueCode: "",
        paymentMethod: "",
        paymentType: "",
        paymentStatus: "",
        status: "",
        createdAt: "",
        member: {
          name: "",
          phone: "",
          email: "",
        },
        paymentId: "",
        payment: {
          id: 0,
          code: "",
          accountNumber: "",
          accountName: "",
          bankName: "",
          paymentType: "",
        },
        paketId: "",
        paket: {
          id: 0,
          title: "",
          price: 0,
        },
        promoId: "",
        promo: {
          id: 0,
          code: "",
          discount: 0,
          type: "",
        },
        refferal: {
          id: 0,
          code: "",
          name: "",
        },
        confirm: {
          nominal: 0,
          accountNumber: "",
          accountName: "",
          bankName: "",
          attachment: "",
          status: "",
          confirmAt: "",
        },
      },

      listPackage: [],
      listPayment: [],
      listPromo: [],

      exConfirmDP: {
        invoice: "",
      },
    };
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.exDataDP;
      ex[f] = val;
    },
    ctcDP(f: string) {
      const ex: any = this.exDataDP;
      ex[f] = gpf.ctc(ex[f]);
    },
    async editDPApi(data: any) {
      this.section = "edit";
      gpf.gLoading.show();

      axios
        .get(api.adminPemasukan + data.invoice, this.configGet)
        .then(async (result: any) => {
          const rs = result.data.response;
          console.log("response get dpp ", rs);

          await this.populateDataDP(rs);

          const qpPromo = "?refid=" + this.exDataDP.refferal.id;
          this.listPackage = await api.getPackage();
          this.listPayment = await api.getPayment();
          this.listPromo = await api.getPromo(qpPromo);

          await gpf.showModal("#modal-data-dp");
          gpf.gLoading.hide();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    async populateDataDP(data: any) {
      const ex = this.exDataDP;
      const rs = data;

      ex.invoice = rs.invoice;
      ex.price = gpf.ctc(rs.price);
      ex.uniqueCode = rs.uniqueCode;
      ex.paymentMethod = rs.paymentMethod;
      ex.paymentType = rs.paymentType;
      ex.paymentStatus = rs.paymentStatus;
      ex.status = rs.status;
      ex.createdAt = rs.createdAt;
      ex.member = rs.member;
      ex.payment = rs.payment;
      ex.refferal = rs.refferal;
      ex.paket = rs.paket;
      ex.promo = rs.promo;
      if (rs.confirm !== null) {
        ex.confirm = rs.confirm;
      }

      ex.paketId = rs.paket.id.toString();
      ex.paymentId = rs.payment.id.toString();
      ex.promoId = rs.promo.id.toString();
    },
    updatedDP(): void {
      gpf.gLoading.show();
      const ex = this.exDataDP;
      const body = new FormData();
      body.append("harga", gpf.btc(ex.price));
      body.append("kodeUnik", ex.uniqueCode);
      body.append("paymentType", ex.paymentType);
      body.append("paymentStatus", ex.paymentStatus);
      body.append("status", ex.status);
      body.append("packageId", ex.paketId.toString());
      body.append("paymentId", ex.paymentId.toString());
      body.append("promoId", ex.promoId.toString());

      axios
        .put(api.adminPemasukan + ex.invoice, body, this.configXwfu)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-dp");
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    async confirmDP(data: any) {
      const ex = this.exConfirmDP;
      ex.invoice = data.invoice;

      gpf.showModal("#modal-confirm-dp");
    },
    async confirmDPApi() {
      gpf.gLoading.show();

      const hide = () => gpf.gLoading.hide();
      const ex = this.exConfirmDP;
      const url = api.adminAcceptTransaksi + ex.invoice;
      const hitApi = await api.hitApiGet(url, hide);
      await gpf.handleSds(hitApi);
      gpf.hideModal("#modal-confirm-dp");
      await this.getData();
    },
  },
});
