type tv = {
  title: string;
  value: string;
};

const dataStatus = (): Array<tv> => {
  const obj: Array<tv> = [
    {
      title: "New",
      value: "new",
    },
    {
      title: "Confirm",
      value: "confirm",
    },
    {
      title: "Accept",
      value: "accept",
    },
    {
      title: "Reject",
      value: "reject",
    },
  ];
  return obj;
};

const dataPayType = (): Array<tv> => {
  const obj: Array<tv> = [
    {
      title: "Cash",
      value: "cash",
    },
    {
      title: "Credit",
      value: "credit",
    },
  ];
  return obj;
};

const dataPayStatus = (): Array<tv> => {
  const obj: Array<tv> = [
    {
      title: "Paid",
      value: "paid",
    },
    {
      title: "Unpaid",
      value: "unpaid",
    },
  ];
  return obj;
};

export { dataStatus, dataPayType, dataPayStatus };
