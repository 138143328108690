import { gpf } from "@/utils/global-functions";
import { reactive } from "vue";
import type { PopupDialogButtons, PopupDialogState } from "./types";

const idPopupConfirmation = `#popup-confirmation`;

export const statePopupConfirmation = reactive({
  title: "Any title",
  message: "Any message",
  buttonsPosition: "r",
  buttons: [] as PopupDialogButtons[],
}) as PopupDialogState;

export const setupPopupConfirmation = (popupData?: PopupDialogState) => {
  statePopupConfirmation.title = popupData?.title || "Any title";
  statePopupConfirmation.message = popupData?.message || "Any message";
  statePopupConfirmation.buttons = popupData?.buttons || [];
  statePopupConfirmation.buttonsPosition = popupData?.buttonsPosition || "r";
};

export const emptyStatePopupConfirmation = () => {
  statePopupConfirmation.title = "Any title";
  statePopupConfirmation.message = "Any message";
  statePopupConfirmation.buttons = [];
  statePopupConfirmation.buttonsPosition = "r";
};

export const popupConfirmation = (popupData?: PopupDialogState) => {
  setupPopupConfirmation(popupData);

  return {
    show: () => {
      setTimeout(() => {
        gpf.showModal(idPopupConfirmation);
      }, 500);
    },
    hide: () => {
      gpf.hideModal(idPopupConfirmation);
      emptyStatePopupConfirmation();
    },
  };
};

export const popupConfirmationHide = () => popupConfirmation().hide();
