
import { defineAsyncComponent, defineComponent } from "vue";
import { api } from "@/utils/api-config";

const LoadingScreen = defineAsyncComponent(
  () => import(`@/components/loading-screen/Main.vue`)
);

const PopupConfirmation = defineAsyncComponent(
  () => import(`@/global-components/popup/confirmation/Main.vue`)
);

const PopupStatus = defineAsyncComponent(
  () => import(`@/global-components/popup/status/Main.vue`)
);

export default defineComponent({
  props: {
    icon: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#FFFFFF",
    },
  },
  components: {
    LoadingScreen,
    PopupConfirmation,
    PopupStatus,
  },
  methods: {
    logout() {
      api.logout();
    },
  },
});
