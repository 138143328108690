
import { defineComponent, ref, reactive } from "vue";
import { helper as $h} from "@/utils/helper";
import { api } from "@/utils/api-config";
import { gpf } from "@/utils/global-functions";

export default defineComponent({
  setup() {
    const salesReportFilter = ref("");
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();

    const titleTable: Array<string> = [
      "Sales",
      "Link",
    ];

    const dataLR = reactive({
      tempData: [] as any,
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
        dataPaginate: [],
      } as any,
      sales: "",
      dataSalesFilter: [] as any,
    });

    const getDataSalesFilter = async () => {
      gpf.gLoading.show();

      const hide = () => gpf.gLoading.hide();
      const url = api.affSalesFilter;
      const hitApi = await api.hitApiGet(url, hide);
      const rs = hitApi.data.response;

      dataLR.sales = rs[0].id.toString();
      dataLR.dataSalesFilter = rs;
    }

    const getDataPagination = async () => {
      const page = dataLR.page;
      const pageSize = dataLR.pageSize;
      dataLR.paginate = $h.paginationLocal(dataLR.tempData.length, page, pageSize, 3, dataLR.tempData);
      dataLR.data = dataLR.paginate.dataPaginate;
    };

    const getData = async () => {
      cash("#loadingDataLR").show();

      const sales = dataLR.sales;
      const url = api.affLinkRef + '?sales=' + sales;
      const hide = () => cash("#loadingDataLR").hide();
      const hitApi = await api.hitApiGet(url, hide);
      const rs = hitApi.data.response;

      dataLR.tempData = rs;
      await getDataPagination();

    };

    const getFirstData = async () => {
      await getDataSalesFilter();
      await getData();
    };
    getFirstData();

    const changeTS = (v: any, f: any) => {
      const val = v.detail.args[1].key;
      const ex: any = dataLR;
      ex[f] = val;
    };

    const changePS = () => {
      getDataPagination();
    };

    const goToPage = (p: number) => {
      dataLR.page = p;
      getDataPagination();
    };

    const nextPage = () => {
      dataLR.page = dataLR.page + 1;
      getDataPagination();
    };

    const beforePage = () => {
      dataLR.page = dataLR.page - 1;
      getDataPagination();
    };

    const lastPage = () => {
      dataLR.page = dataLR.paginate.totalPages;
      getDataPagination();
    };

    return {
      salesReportFilter,
      titleTable,
      getData,
      dataLR,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
      changeTS,
    };
  },
  data() {
    return {};
  },
  methods: {
  },
});
