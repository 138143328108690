import { HOST_API_V1 } from "@/services/env-init";

export const prettierPhotoUser = (linkPhotoUser: string) => {
  // console.log("linkPhotoUser ", linkPhotoUser);

  // handle default image photo user
  const avatarPNG = "avatar.png";
  const defaultPhotoUser = HOST_API_V1 + "img/users/avatar.png";
  if (linkPhotoUser.includes(avatarPNG)) {
    return defaultPhotoUser;
  }

  // handle updated photo user
  const splitText = "users/";
  const checkLink = linkPhotoUser.includes(splitText);

  if (checkLink) {
    return linkPhotoUser.split(splitText)[1];
  }

  return linkPhotoUser;
};
