
import { defineComponent, ref, reactive } from "vue";
import { helper as $h} from "@/utils/helper";
import { api } from "@/utils/api-config";

export default defineComponent({
  setup() {
    const salesReportFilter = ref("");
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();

    const titleTable: Array<string> = [
      "Photo",
      "Name",
      "Jumlah",
    ];

    const dataLB = reactive({
      tempData: [] as any,
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
        dataPaginate: [],
      } as any,
      dateRange: "",
    });

    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    dataLB.dateRange = `${$h.formatDate(firstDay.toString(), 'DD MMM, YYYY')} - ${$h.formatDate(lastDay.toString(), 'DD MMM, YYYY')}`;

    const getData = async () => {
      cash("#loadingDataLB").show();

      const since = $h.formatDate(dataLB.dateRange.split('-')[0], 'YYYY-MM-DD');
      const until = $h.formatDate(dataLB.dateRange.split('-')[1], 'YYYY-MM-DD');

      const url =
        api.salesLeaderboard +
        "?since=" +
        since +
        "&until=" +
        until;
      const hide = () => cash("#loadingDataLB").hide();
      const hitApi = await api.hitApiGet(url, hide);
      const rs = hitApi.data.response;

      dataLB.data = rs;

    };
    getData();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataLB.page = p;
      getData();
    };

    const nextPage = () => {
      dataLB.page = dataLB.page + 1;
      getData();
    };

    const beforePage = () => {
      dataLB.page = dataLB.page - 1;
      getData();
    };

    const lastPage = () => {
      dataLB.page = dataLB.paginate.totalPages;
      getData();
    };

    return {
      salesReportFilter,
      titleTable,
      getData,
      dataLB,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
    };
  },
  data() {
    return {};
  },
  methods: {
  },
});
