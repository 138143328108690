import { renderSlot as _renderSlot, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  ref: "sliderRef",
  class: "tiny-slider"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_slider = _resolveDirective("slider")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default")
  ])), [
    [_directive_slider, { props: _ctx.props, emit: _ctx.emit }]
  ])
}