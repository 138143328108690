
import { defineComponent } from "vue";
import * as XLSX from "xlsx";
import { gpf } from "@/utils/global-functions";

export default defineComponent({
  setup() {
    const kuyIE = "kuyIE";
    return {
      kuyIE,
    };
  },
  data() {
    return {
      arrayBuffer: "" as any,
      file: "" as any,
      allData: [] as any,
      fileName: "",
    };
  },
  methods: {
    chooseExcel() {
      const id = document.getElementById("fileImportExcel");
      id?.click();
    },
    getDataFromExcel(evt: HTMLInputElement) {
      const file: any = evt.files;
      this.fileName = file[0].name;

      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.arrayBuffer = fileReader.result;
        var data = new Uint8Array(this.arrayBuffer);
        var arr = new Array();
        for (var i = 0; i !== data.length; ++i)
          arr[i] = String.fromCharCode(data[i]);
        var bstr = arr.join("");
        var workbook = XLSX.read(bstr, { type: "binary" });
        var firstSheetName = workbook.SheetNames[0];
        var worksheet = workbook.Sheets[firstSheetName];
        const allData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
        this.allData = allData;

        // console.log("allData ", allData);
      };

      fileReader.readAsArrayBuffer(file[0]);
    },
    handleFile(evt: any, cond: string) {
      // console.log('handleFile evt ', evt, cond)

      cond === "select"
        ? this.getDataFromExcel(evt.target)
        : this.getDataFromExcel(evt.dataTransfer);
    },
    async uploadExcel() {
      this.$emit("importExcelFunc", this.allData);
      await gpf.hideModal("#modal-import-excel");
    },
  },
});
