
import { Breadcrumb, dashboardPath } from "@/components/top-bar";
import { DataTableHeader } from "@/global-components/data-table/types";
import { ListCategories } from "@/services/v1/admin/learning/categories/types";
import { ListTopic } from "@/services/v1/admin/learning/topic/types";
import {
  ListMateri,
  MateriDetailType,
} from "@/services/v1/admin/learning/types";
import { api } from "@/utils/api-config";
import { setBreadcrumb } from "@/utils/breadcrumb";
import { gpf } from "@/utils/global-functions";
import { helper as $h } from "@/utils/helper";
import {
  popupConfirmation,
  popupConfirmationHide,
} from "@/utils/popup/confirmation";
import { popupStatus } from "@/utils/popup/status";
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";

const TextField = defineAsyncComponent(
  () => import(`@/global-components/input/text-field/Main.vue`)
);

const Autocomplete = defineAsyncComponent(
  () => import(`@/global-components/input/autocomplete/Main.vue`)
);

const ChipActiveInactive = defineAsyncComponent(
  () => import(`@/global-components/chip/active-inactive.vue`)
);

const TooltipActiveInactive = defineAsyncComponent(
  () => import(`@/global-components/tooltip/active-inactive.vue`)
);

const DataTable = defineAsyncComponent(
  () => import(`@/global-components/data-table/Main.vue`)
);

const ModalFormlMateri = defineAsyncComponent(
  () => import(`./components/modal-form-materi.vue`)
);

const ModalDetailMateri = defineAsyncComponent(
  () => import(`./components/modal-detail-materi.vue`)
);

const servicesV1AdminLearning = async () =>
  import(`@/services/v1/admin/learning`);

const servicesV1AdminLearningCategories = async () =>
  import(`@/services/v1/admin/learning/categories`);

const servicesV1AdminLearningTopic = async () =>
  import(`@/services/v1/admin/learning/topic`);

export default defineComponent({
  components: {
    TextField,
    Autocomplete,
    ChipActiveInactive,
    TooltipActiveInactive,
    DataTable,
    ModalFormlMateri,
    ModalDetailMateri,
  },
  setup() {
    const config = api.configGet();

    // START === setup breadcrumb =====================================================
    const breadcrumb: Breadcrumb[] = [
      {
        title: "Dashboard",
        to: dashboardPath,
      },
      {
        title: "Data Materi",
        to: `${dashboardPath}/data-materi`,
        disabled: true,
      },
    ];

    onMounted(() => {
      setBreadcrumb(breadcrumb);
    });
    // END === setup breadcrumb =======================================================

    const tabelHeaders = [
      {
        text: "Banner",
        value: "banner",
        itemClass: "w-36",
      },
      {
        text: "Title",
        value: "title",
      },
      {
        text: "Content",
        value: "content",
        itemClass: "max-w-60",
      },
      {
        text: "Created At",
        value: "createdAt",
        headerClass: "w-32",
      },
      {
        text: "Action",
        value: "action",
        itemClass: "max-w-20",
        align: "center",
      },
    ] as DataTableHeader[];

    const showAllObj = {
      text: "Tampilkan Semua",
      value: "0",
    };

    const filterTypes = [
      showAllObj,
      {
        text: "e-Book",
        value: "ebook",
      },
      {
        text: "Video",
        value: "video",
      },
    ];

    const dataMateri = reactive({
      data: [] as any,
      loading: false,

      // for pagination
      page: 1,
      pageSize: 10,
      totalData: 1,

      // for filter and search
      search: "",
      types: "",
      jenis: 1,
      category: "0",
      topic: "0",
    });

    // START === Function for get list categories ============================================
    const categories = ref([] as any[]);
    const getCategories = async () => {
      const {
        getAdminLearningCategories,
      } = await servicesV1AdminLearningCategories();

      try {
        const hit = await getAdminLearningCategories();

        const resData = hit?.response;

        categories.value = [];
        categories.value = resData.map((category: ListCategories) => ({
          ...category,
          text: category.title,
          value: category.id,
        }));

        categories.value.splice(0, 0, showAllObj);

        // dataMateri.category = resData?.[0].id ? resData?.[0].id : 1;
      } catch (err: any) {
        console.error("err getCategories ", err, err?.response);
      }
    };

    onMounted(() => {
      getCategories();
    });
    // END === Function for get list categories ==============================================

    // START === Function for get list topic ============================================
    const topics = ref([] as any[]);
    const getTopics = async () => {
      const { getAdminLearningTopics } = await servicesV1AdminLearningTopic();

      try {
        const hit = await getAdminLearningTopics();

        const resData = hit?.response;

        topics.value = [];
        topics.value = resData.map((topic: ListTopic) => ({
          ...topic,
          text: topic.title,
          value: topic.id,
        }));

        topics.value.splice(0, 0, showAllObj);

        // dataMateri.topic = resData?.[0].id ? resData?.[0].id : 1;
      } catch (err: any) {
        console.error("err getTopics ", err, err?.response);
      }
    };

    onMounted(() => {
      getTopics();
    });
    // END === Function for get list topic ==============================================

    // START === Functions for handle list materi data =========================================
    const refetchListMateri = ref(false as boolean);
    const getListMateriData = async () => {
      const { getAdminLearningList } = await servicesV1AdminLearning();

      dataMateri.loading = true;

      const page = dataMateri.page;
      const pageSize = dataMateri.pageSize;

      const params = {
        search: dataMateri.search,
        page,
        pageSize,
      } as any;

      if (dataMateri.types !== "0") params.types = dataMateri.types;
      if (dataMateri.category !== "0") params.category = dataMateri.category;
      if (dataMateri.topic !== "0") params.topic = dataMateri.topic;

      try {
        const hit = await getAdminLearningList({ params });
        const ds = hit.diagnostic;
        const rs = hit.response;
        // console.log("response get dm ", rs);

        dataMateri.data = rs;
        dataMateri.totalData = ds.totalData as number;

        // back to default refetch
        refetchListMateri.value = false;
      } catch (err: any) {
        console.error(
          "err getListMateriData Materi Admin ",
          err,
          err?.response
        );

        const errData = err?.response?.data;
        const messageErr = errData?.diagnostic?.message;

        popupStatus({
          type: "f",
          message: messageErr,
        }).show();
      } finally {
        dataMateri.loading = false;
      }
    };

    const dataMateriItems = computed(() =>
      dataMateri.data.map((materi: ListMateri) => ({
        ...materi,
        status: materi.isActive ? "Active" : "Inactive",
      }))
    );

    onMounted(() => {
      getListMateriData();
    });

    const handleSearch = () => {
      dataMateri.page = 1;
      getListMateriData();
    };

    watch(
      () => dataMateri.pageSize,
      () => {
        dataMateri.page = 1;
        getListMateriData();
      }
    );

    watch(
      () => dataMateri.page,
      () => {
        getListMateriData();
      }
    );

    // for refetch list materi data
    watch(
      () => refetchListMateri.value,
      (newVal) => {
        if (newVal) getListMateriData();
      }
    );
    // END === Functions for handle list materi data ===========================================

    // START === add new materi ============================================================
    const setupFormMateri = reactive({
      show: false,
      id: 0,
      slug: "",
    });

    const addNewMateri = () => {
      setupFormMateri.show = true;
    };

    const editMateri = (materi: MateriDetailType) => {
      setupFormMateri.show = true;
      setupFormMateri.id = materi.id;
      setupFormMateri.slug = materi.slug;
    };
    // END === add new materi ==============================================================

    // START === Function for show modal detail materi =====================================
    const detailMateri = reactive({
      id: "",
      show: false,
    });

    const showModalDetailMateri = async (dataMateri: any) => {
      detailMateri.id = dataMateri?.id || "";
      detailMateri.show = true;
    };
    // END === Function for show modal detail materi =======================================

    // START === Function for delete materi ================================================
    const deleteMateriPopup = (data: any): void => {
      popupConfirmation({
        title: "Hapus Data Materi",
        message: `Apakah Anda yakin, ingin menghapus data dengan title : "${data.title}"`,
        buttons: [
          {
            type: "outline",
            text: "Batal",
            action: popupConfirmationHide,
          },
          {
            type: "danger",
            text: "Hapus",
            action: () => {
              fixDeleteMateri(data.id);
              popupConfirmationHide();
            },
          },
        ],
      }).show();
    };

    const fixDeleteMateri = async (adminLearningId: string) => {
      const { deleteAdminLearningById } = await servicesV1AdminLearning();

      gpf.gLoading.show();

      try {
        const hit = await deleteAdminLearningById(adminLearningId);

        const messageSuccess = hit?.diagnostic?.message;

        popupStatus({
          type: "s",
          message: messageSuccess,
        }).show();
      } catch (err: any) {
        console.error("err fixDeleteMateri ", err, err?.response);
        const errData = err?.response?.data;
        const messageErr = errData?.diagnostic?.message;

        popupStatus({
          type: "f",
          message: messageErr,
        }).show();
      } finally {
        gpf.gLoading.hide();

        // refetch list data-materi
        getListMateriData();
      }
    };
    // END === Function for delete materi ==================================================

    return {
      tabelHeaders,
      getListMateriData,
      dataMateri,
      config,
      filterTypes,
      handleSearch,
      dataMateriItems,
      categories,
      topics,
      refetchListMateri,

      setupFormMateri,
      addNewMateri,
      editMateri,

      $h,
      gpf,
      detailMateri,
      showModalDetailMateri,

      deleteMateriPopup,
      fixDeleteMateri,
    };
  },
});
