import { resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_editor = _resolveDirective("editor")!

  return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tagName), {
    ref: "editorRef",
    class: "select"
  }, null, 512)), [
    [_directive_editor, { props: _ctx.props, emit: _ctx.emit, cacheData: _ctx.cacheData }]
  ])
}