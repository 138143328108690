
/* eslint-disable */
import { defineComponent, ref, reactive } from "vue";
import MarketingKit from "./MarketingKit.vue";
import LinkReferral from "./LinkReferral.vue";

export default defineComponent({
  components: {
    MarketingKit,
    LinkReferral,
  },
  setup() {
    return {};
  },
  data() {
    return {};
  },
  methods: {},
});
