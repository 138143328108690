
import { defineComponent, reactive } from "vue";
import { helper as $h } from "@/utils/helper";
import { api } from "@/utils/api-config";

export default defineComponent({
  setup() {
    const dataDB = reactive({
      data: [] as any,
      dateRange: "",
    });

    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    dataDB.dateRange = `${$h.formatDate(
      firstDay.toString(),
      "DD MMM, YYYY"
    )} - ${$h.formatDate(lastDay.toString(), "DD MMM, YYYY")}`;

    const getData = async () => {
      cash("#loadingDS").show();

      const since = $h.formatDate(dataDB.dateRange.split("-")[0], "YYYY-MM-DD");
      const until = $h.formatDate(dataDB.dateRange.split("-")[1], "YYYY-MM-DD");
      const url =
        api.affWidget +
        "?since=" +
        since +
        "&until=" +
        until;

      const hide = () => cash("#loadingDS").hide();
      const hitApi = await api.hitApiGet(url, hide);
      const rs = hitApi.data.response;

      dataDB.data = rs;

      // console.log("getData clicked");
    };
    getData();

    return {
      dataDB,
      getData,
    };
  },
  data() {
    return {};
  },
});
