
import { defineComponent } from "vue";
import { useRoute } from "vue-router";
import { resultExam, thDataUser, thKeterangan } from "./tryout-hasil";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";

export default defineComponent({
  setup() {
    const route = useRoute();
    const params = route.params;
    const resp = resultExam.response;
    const soalList = resultExam.response.soal;
    const config = api.configGet();
    const kodeExam = gpf.getParameterByName("kode");
    const jwbCat: any = JSON.parse(gpf.memberHasilCat(kodeExam));

    const rn = (): void => {
      cash("#cvRn").toggleClass("transform rotate-180");
      cash("#nsRn").toggleClass("flex-wrap justify-center");
    };

    const rnTop = (id1: string, id2: string): void => {
      cash("#" + id1).toggleClass("transform rotate-180");
      cash("#" + id2).toggleClass("hidden");
    };

    return {
      params,
      rn,
      resp,
      soalList,
      rnTop,
      kodeExam,
      jwbCat,
      config,
    };
  },
  data() {
    return {
      cs: {
        id: 0,
        nomor: 0,
        kategori: "",
        bidang: "",
        question: "",
        questionImg: "",
        option: [
          {
            option: "",
            text: "",
            img: "",
          },
        ],
      },
      allSoal: resultExam.response.soal,
      cis: 0,
      dataUser: thDataUser,
      ketUjian: thKeterangan,
      dataTH: {},
    };
  },
  methods: {
    changeSoal(soal: any, idx: number): void {
      this.cs = soal;
      this.cis = idx;
    },
    changeSoalCis(tp: string): void {
      tp === "next" ? this.cis++ : this.cis--;
      this.cs = this.allSoal[this.cis];
    },
    chooseOpt(cs: any, no: any, idx: any): void {
      const a = cs.option;
      for (let i = 0; i < a.length; i++) {
        if (i !== idx) {
          cash('[opt="chs-' + no + "-" + i + '"]').removeClass("active");
        } else {
          cash('[opt="chs-' + no + "-" + idx + '"]').addClass("active");
          this.allSoal[this.cis].answer = cs.option[idx].option;
          this.allSoal[this.cis].answerIdx = idx;
        }
      }
    },
    trimText(txt: string): string {
      return gpf.trimText(txt);
    },
    async memberHasilCat() {
      
      const url = api.tentorHasilCat + this.kodeExam;
      const hitApi = await api.hitApiGet(url);
      const rs = hitApi.data.response;
      this.dataTH = rs;

      // window.localStorage.setItem(
      //   "submitCatApi" + this.kodeExam,
      //   JSON.stringify(rs)
      // );

      await this.memberPembahasanCat();
      
    },
    async memberPembahasanCat() {

      const hide = () => gpf.gLoading.hide();
      const url = api.tentorPembahasanCat + this.kodeExam;
      const hitApi = await api.hitApiGet(url, hide);
      const rs = hitApi.data.response;
      this.allSoal = rs;
      this.cs = this.allSoal[this.cis];

    },
  },
  mounted() {
    gpf.gLoading.show();

    setTimeout(() => {
      this.memberHasilCat();
    }, 1500);

  },
});
