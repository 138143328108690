type tv = {
  title: string,
  value: string,
};

const dataStatus = (): Array<tv> => {
  const obj: Array<tv> = [
    {
      title: "Suspend", value: "0",
    },
    {
      title: "Active", value: "1",
    },
  ];
  return obj;
};

const dataPaket = (): Array<tv> => {
  const obj: Array<tv> = [
    {
      title: "CPNS", value: "cpns",
    },
    {
      title: "PPPK", value: "pppk",
    },
  ];
  return obj;
};

interface OptionMS {
  option: string;
  text: string;
  img: string;
  poin: number;
}

interface DataMS  {
  id: number;
  idJenis: string;
  idCategory: string;
  idBidang: string;
  question: string;
  questionImg: string;
  pembahasan: string;
  pembahasanImg: string;
  answer: string;
  option: Array<OptionMS>;
}

export { dataStatus, dataPaket, DataMS, OptionMS };
