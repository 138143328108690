import axios, { AxiosInstance } from "axios";
import type { AxiosError, AxiosRequestConfig } from "axios";
import { getToken, setAxios } from "./constant";

const onPostCall = (config: AxiosRequestConfig) => {
  return config.method === "post";
};

const onPutCall = (config: AxiosRequestConfig) => {
  return config.method === "put";
};

const setupAuth = () => {
  const axiosApiInstance: AxiosInstance = axios.create();

  // init axios for handling authorization on any request hit API
  axiosApiInstance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const token = getToken();
      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    },
    async (error: AxiosError) => {
      Promise.reject(error);
    }
  );

  // init axios for handling authorization on any request hit API
  axiosApiInstance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const token = getToken();
      if (token && onPutCall(config)) {
        config.headers.Authorization = token;
        config.headers["Content-Type"] =
          config.headers["Content-Type"] || "application/x-www-form-urlencoded";
      }
      return config;
    },
    async (error: AxiosError) => {
      Promise.reject(error);
    }
  );

  // init axios for handling authorization on any request hit API
  axiosApiInstance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const token = getToken();
      if (token && onPostCall(config)) {
        config.headers.Authorization = token;
        config.headers["Content-Type"] =
          config.headers["Content-Type"] || "application/x-www-form-urlencoded";
      }
      return config;
    },
    async (error: AxiosError) => {
      Promise.reject(error);
    }
  );

  setAxios(axiosApiInstance);
};

export default setupAuth;
