// Create initial state
export const state = {
  // BEGIN Menu Member
  member: [
    {
      icon: "HomeIcon",
      pageName: "dashboard-member",
      title: "Dashboard Member",
    },
    {
      icon: "CodeIcon",
      pageName: "materi",
      title: "Materi",
      subMenu: [
        {
          icon: "",
          pageName: "materi-penunjang",
          title: "Materi Penunjang",
        },
        {
          icon: "",
          pageName: "tips-and-trik",
          title: "Tips & Trik",
        },
      ],
    },
    {
      icon: "CodeIcon",
      pageName: "cat-online-cpns",
      title: "CAT Online CPNS",
      subMenu: [
        {
          icon: "",
          pageName: "tryout-skb",
          title: "Tryout SKB",
        },
        {
          icon: "",
          pageName: "tryout-skd",
          title: "Tryout SKD",
        },
      ],
    },
    {
      icon: "CodeIcon",
      pageName: "tryout-pppk",
      title: "CAT Online PPPK",
    },
    {
      icon: "CodeIcon",
      pageName: "riwayat-cat",
      title: "Riwayat CAT",
    },
    {
      icon: "CodeIcon",
      pageName: "peringkat-cat",
      title: "Peringkat CAT",
    },
    {
      icon: "CodeIcon",
      pageName: "kelas-online",
      title: "Kelas Online",
      subMenu: [
        {
          icon: "",
          pageName: "kelas-private",
          title: "Kelas Private",
        },
        {
          icon: "",
          pageName: "kelas-webinar",
          title: "Kelas Webinar",
        },
      ],
    },
    {
      icon: "CodeIcon",
      pageName: "other",
      title: "Other",
      subMenu: [
        {
          icon: "",
          pageName: "form-komplain",
          title: "Form Komplain",
        },
        {
          icon: "",
          pageName: "cek-garansi",
          title: "Cek Garansi",
        },
        {
          icon: "",
          pageName: "tutorial",
          title: "Tutorial",
        },
      ],
    },
    {
      icon: "UserIcon",
      pageName: "upgrade-paket",
      title: "Upgrade Paket",
    },
    "devider",
  ],
  // END Menu Member

  // BEGIN Menu ADMIN
  admin: [
    {
      icon: "HomeIcon",
      pageName: "dashboard-admin",
      title: "Dashboard Admin",
    },
    {
      icon: "CodeIcon",
      pageName: "data-master",
      title: "Data Master",
      subMenu: [
        {
          icon: "",
          pageName: "data-user",
          title: "Data User",
        },
        {
          icon: "",
          pageName: "bonus-materi",
          title: "Bonus Materi",
        },
        {
          icon: "",
          pageName: "paket-soal",
          title: "Paket Soal",
        },
        {
          icon: "",
          pageName: "bank-soal",
          title: "Bank Soal",
        },
      ],
    },
    {
      icon: "CodeIcon",
      pageName: "data-transaksi",
      title: "Data Transaksi",
      subMenu: [
        {
          icon: "",
          pageName: "pemasukan",
          title: "Pemasukan",
        },
        {
          icon: "",
          pageName: "withdrawal",
          title: "Withdrawal",
        },
        {
          icon: "",
          pageName: "pengeluaran",
          title: "Pengeluaran",
        },
      ],
    },
    {
      icon: "CodeIcon",
      pageName: "forum-member",
      title: "Forum Member",
      subMenu: [
        {
          icon: "",
          pageName: "kelas-private",
          title: "Kelas Private",
        },
        {
          icon: "",
          pageName: "kelas-webinar",
          title: "Kelas Webinar",
        },
        {
          icon: "",
          pageName: "form-komplain",
          title: "Form Komplain",
        },
      ],
    },
    {
      icon: "CodeIcon",
      pageName: "news-update",
      title: "News Update",
    },
    {
      icon: "CodeIcon",
      pageName: "affiliate-program",
      title: "Affiliate Program",
      subMenu: [
        {
          icon: "",
          pageName: "data-affiliate",
          title: "Data Affiliate",
        },
        {
          icon: "",
          pageName: "marketing-kit",
          title: "Marketing Kit",
        },
        {
          icon: "",
          pageName: "peringkat-affiliate-sales",
          title: "Peringkat Affiliate & Sales",
        },
      ],
    },
    {
      icon: "CodeIcon",
      pageName: "laporan",
      title: "Laporan",
      subMenu: [
        {
          icon: "",
          pageName: "data-komisi",
          title: "Data Komisi",
        },
        {
          icon: "",
          pageName: "hasil-tryout",
          title: "Hasil Tryout",
        },
        {
          icon: "",
          pageName: "saldo-affiliate",
          title: "Saldo Affiliate",
        },
        {
          icon: "",
          pageName: "arus-kas",
          title: "Arus Kas",
        },
      ],
    },
    "devider",
  ],
  // END Menu ADMIN

  // BEGIN Menu AFFILIATE
  affiliate: [
    {
      icon: "HomeIcon",
      pageName: "dashboard-affiliate",
      title: "Dashboard Affiliate",
    },
    {
      icon: "CodeIcon",
      pageName: "data-master",
      title: "Data Master",
      subMenu: [
        {
          icon: "",
          pageName: "data-subaff",
          title: "Data SUBAFF",
        },
        {
          icon: "",
          pageName: "data-sales",
          title: "Data Sales",
        },
        {
          icon: "",
          pageName: "data-member",
          title: "Data Member",
        },
      ],
    },
    {
      icon: "CodeIcon",
      pageName: "data-transaksi",
      title: "Data Transaksi",
      subMenu: [
        {
          icon: "",
          pageName: "pemasukan",
          title: "Pemasukan",
        },
        {
          icon: "",
          pageName: "bonus-sales",
          title: "Bonus Sales",
        },
        {
          icon: "",
          pageName: "withdrawal",
          title: "Withdrawal",
        },
      ],
    },
    {
      icon: "CodeIcon",
      pageName: "affiliate-program",
      title: "Affiliate Program",
      subMenu: [
        {
          icon: "",
          pageName: "marketing-kit",
          title: "Marketing Kit",
        },
        {
          icon: "",
          pageName: "peringkat-affiliate",
          title: "Peringkat Affiliate",
        },
        {
          icon: "",
          pageName: "pengaturan",
          title: "Pengaturan",
        },
      ],
    },
    {
      icon: "CodeIcon",
      pageName: "materi",
      title: "Materi",
      subMenu: [
        {
          icon: "",
          pageName: "materi-penunjang",
          title: "Materi Penunjang",
        },
        {
          icon: "",
          pageName: "tips-and-trik",
          title: "Tips & Trik",
        },
      ],
    },
    {
      icon: "CodeIcon",
      pageName: "cat-online-cpns",
      title: "CAT Online CPNS",
      subMenu: [
        {
          icon: "",
          pageName: "tryout-skb",
          title: "Tryout SKB",
        },
        {
          icon: "",
          pageName: "tryout-skd",
          title: "Tryout SKD",
        },
      ],
    },
    {
      icon: "CodeIcon",
      pageName: "tryout-pppk",
      title: "CAT Online PPPK",
    },
    {
      icon: "CodeIcon",
      pageName: "riwayat-cat",
      title: "Riwayat CAT",
    },
    {
      icon: "CodeIcon",
      pageName: "peringkat-cat",
      title: "Peringkat CAT",
    },
    {
      icon: "CodeIcon",
      pageName: "kelas-online",
      title: "Kelas Online",
      subMenu: [
        {
          icon: "",
          pageName: "kelas-private",
          title: "Kelas Private",
        },
        {
          icon: "",
          pageName: "kelas-webinar",
          title: "Kelas Webinar",
        },
      ],
    },
    "devider",
  ],
  // END Menu AFFILIATE

  // BEGIN Menu SALES,
  sales: [
    {
      icon: "HomeIcon",
      pageName: "dashboard-sales",
      title: "Dashboard Sales",
    },
    {
      icon: "CodeIcon",
      pageName: "data-member",
      title: "Data Member",
    },
    {
      icon: "CodeIcon",
      pageName: "marketing-kit",
      title: "Marketing Kit",
    },
    {
      icon: "CodeIcon",
      pageName: "bonus-penjualan",
      title: "Bonus Penjualan",
    },
    {
      icon: "CodeIcon",
      pageName: "peringkat-sales",
      title: "Peringkat Sales",
    },
    {
      icon: "CodeIcon",
      pageName: "materi",
      title: "Materi",
      subMenu: [
        {
          icon: "",
          pageName: "materi-penunjang",
          title: "Materi Penunjang",
        },
        {
          icon: "",
          pageName: "tips-and-trik",
          title: "Tips & Trik",
        },
      ],
    },
    {
      icon: "CodeIcon",
      pageName: "cat-online-cpns",
      title: "CAT Online CPNS",
      subMenu: [
        {
          icon: "",
          pageName: "tryout-skb",
          title: "Tryout SKB",
        },
        {
          icon: "",
          pageName: "tryout-skd",
          title: "Tryout SKD",
        },
      ],
    },
    {
      icon: "CodeIcon",
      pageName: "tryout-pppk",
      title: "CAT Online PPPK",
    },
    {
      icon: "CodeIcon",
      pageName: "riwayat-cat",
      title: "Riwayat CAT",
    },
    {
      icon: "CodeIcon",
      pageName: "peringkat-cat",
      title: "Peringkat CAT",
    },
    {
      icon: "CodeIcon",
      pageName: "kelas-online",
      title: "Kelas Online",
      subMenu: [
        {
          icon: "",
          pageName: "kelas-private",
          title: "Kelas Private",
        },
        {
          icon: "",
          pageName: "kelas-webinar",
          title: "Kelas Webinar",
        },
      ],
    },
    "devider",
  ],
  // END Menu SALES

  // BEGIN Menu KEUANGAN
  keuangan: [
    {
      icon: "HomeIcon",
      pageName: "dashboard-keuangan",
      title: "Dashboard Keuangan",
    },
    {
      icon: "CodeIcon",
      pageName: "pemasukan",
      title: "Pemasukan",
    },
    {
      icon: "CodeIcon",
      pageName: "withdrawal",
      title: "Withdrawal",
    },
    {
      icon: "CodeIcon",
      pageName: "pengeluaran",
      title: "Pengeluaran",
    },
    "devider",
  ],
  // END Menu KEUANGAN

  // BEGIN Menu TENTOR
  tentor: [
    {
      icon: "HomeIcon",
      pageName: "dashboard-tentor",
      title: "Dashboard Tentor",
    },
    {
      icon: "CodeIcon",
      pageName: "bank-soal",
      title: "Bank Soal",
    },
    {
      icon: "CodeIcon",
      pageName: "kelas-private",
      title: "Kelas Private",
    },
    "devider",
  ],
  // END Menu TENTOR

  // BEGIN Menu OWNER
  owner: [
    {
      icon: "HomeIcon",
      pageName: "dashboard-owner",
      title: "Dashboard Owner",
    },
    {
      icon: "CodeIcon",
      pageName: "bagi-hasil",
      title: "Bagi Hasil",
    },
    {
      icon: "CodeIcon",
      pageName: "withdrawal",
      title: "Withdrawal",
    },
    "devider",
  ],
  // END Menu OWNER

  menu: [],
  activeTitle: 'Dashboard',
  activeUrlSubMenu: '',
};

// Declare state
export type State = typeof state;
