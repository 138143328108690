
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    to: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {}
  },
});
