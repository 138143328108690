type tv = {
  title: string;
  value: string;
};

const dataTipeLB = (): Array<tv> => {
  const obj: Array<tv> = [
    {
      title: "Affiliate",
      value: "affiliate",
    },
    {
      title: "Sales",
      value: "sales",
    },
    {
      title: "VIP Affiliate",
      value: "vip",
    },
  ];
  return obj;
};

export { dataTipeLB };
