
import { defineComponent, reactive, ref } from "vue";
import { dataStatus, dataPaket, DataMS, OptionMS } from "./master-soal";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";
import axios from "axios";
import importExcelFunc from "@/components/import-excel/Main.vue";

// for ckeditor
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import InlineEditor from "@ckeditor/ckeditor5-editor-inline/src/inlineeditor";
import BalloonEditor from "@ckeditor/ckeditor5-editor-balloon/src/ballooneditor";
import DocumentEditor from "@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import UnderlinePlugin from "@ckeditor/ckeditor5-basic-styles/src/underline";
import StrikethroughPlugin from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import CodePlugin from "@ckeditor/ckeditor5-basic-styles/src/code";
import SubscriptPlugin from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import SuperscriptPlugin from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import EasyImage from "@ckeditor/ckeditor5-easy-image/src/easyimage";
import Font from "@ckeditor/ckeditor5-font/src/font";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import HeadingButtonsUI from "@ckeditor/ckeditor5-heading/src/headingbuttonsui";
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight";

export default defineComponent({
  setup() {
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();
    const listStatus = dataStatus();
    const listPaket = dataPaket();

    // "id": 1474,
    // "paket": "cpns",
    // "jenis": "Seleksi Kompetensi Dasar",
    // "kategori": "Tes Wawasan Kebangsaan",
    // "bidang": "Nasionalisme",
    // "question": "Pembentukan konstitusi di Indonesia, menggunakan cara.\r\n",
    // "answer": "C",
    // "createdAt": "2021-02-20 00:00:00"

    const titleTable: Array<string> = [
      "Paket",
      "Jenis Soal",
      "Kategori Soal",
      "Master Soal",
      "Pertanyaan",
      "Kunci Jawaban",
      "Created",
      "#",
    ];

    const dataMS = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
      paket: listPaket[0].value,
      jenis: "",
      listJenis: [] as any,
      kategori: "",
      listKategori: [] as any,
      bidang: "",
      listBidang: [] as any,
      filterMasterSoal: {
        cpns: [
          {
            value: "skd",
            text: "Seleksi Kompetensi Dasar",
            kategori: [
              {
                value: "twk",
                text: "Tes Wawasan Kebangsaan",
                bidang: [
                  "Nasionalisme",
                  "Integritas",
                  "Bela Negara",
                  "Pilar Negara",
                  "Bahasa Indonesia",
                ],
              },
            ],
          },
        ],
        pppk: [
          {
            value: "tkt",
            text: "Tes Kompetensi Teknis",
            kategori: [
              {
                value: "ptp",
                text: "PPPK Tenaga Pendidik",
                bidang: ["Guru"],
              },
            ],
          },
        ],
      } as any,
    });

    const getDataListMS = async () => {
      cash("#loadingBSadmin").show();

      const page = dataMS.page;
      const pageSize = dataMS.pageSize;
      const search = dataMS.search;
      const paket = dataMS.paket;
      const jenis = dataMS.jenis;
      const kategori = dataMS.kategori;
      const bidang = dataMS.bidang;
      axios
        .get(
          api.tentorMasterSoal +
            "?search=" +
            search +
            "&paket=" +
            paket +
            "&jenis=" +
            jenis +
            "&kategori=" +
            kategori +
            "&bidang=" +
            bidang +
            "&page=" +
            page +
            "&pageSize=" +
            pageSize,
          configGet
        )
        .then(async (result: any) => {
          gpf.gLoading.hide();
          const ds = result.data.diagnostic;
          const rs = result.data.response;
          // console.log("response get du ", rs);

          dataMS.data = rs;
          dataMS.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);
          // console.log("dataMS.paginate ", dataMS.paginate);
          cash("#loadingBSadmin").hide();
        })
        .catch(async (error: any) => {
          cash("#loadingBSadmin").hide();
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    };

    const changePaketSoal = async (type = "static", pkt?: any) => {
      const exfms = dataMS.filterMasterSoal;
      const paket = type === "static" ? dataMS.paket : pkt.detail.args[1].key;
      dataMS.paket = paket;

      // console.log("pkt ", type, paket, pkt);

      dataMS.listJenis = exfms[paket];
      dataMS.listKategori = exfms[paket][0].kategori;
      dataMS.listBidang = exfms[paket][0].kategori[0].bidang;

      dataMS.jenis = exfms[paket][0].value;
      dataMS.kategori = exfms[paket][0].kategori[0].value;
      dataMS.bidang = exfms[paket][0].kategori[0].bidang[0];

      await getDataListMS();
    };

    const changeJenisSoal = async (jns?: any) => {
      const paket = dataMS.paket;
      const jenis = jns.detail.args[1].key;
      dataMS.jenis = jenis;
      const exfms = dataMS.filterMasterSoal[paket];

      exfms.map((e: any, idx: any) => {
        if ( jenis === e.value ) {
          dataMS.listKategori = exfms[idx].kategori;
          dataMS.listBidang = exfms[idx].kategori[0].bidang;

          dataMS.kategori = exfms[idx].kategori[0].value;
          dataMS.bidang = exfms[idx].kategori[0].bidang[0];
        }
      });

      await getDataListMS();
    }; 

    const changeKategoriSoal = async (ktg?: any) => {
      const kategori = ktg.detail.args[1].key;
      dataMS.kategori = kategori;
      const exfms = dataMS.listKategori;

      exfms.map((e: any, idx: any) => {
        if ( kategori === e.value ) {
          dataMS.listBidang = exfms[idx].bidang;

          dataMS.bidang = exfms[idx].bidang[0];
        }
      });

      await getDataListMS();
    }; 

    const changeBidangSoal = async (bd?: any) => {
      const bidang = bd.detail.args[1].key;
      dataMS.bidang = bidang;

      await getDataListMS();
    }; 

    const getFilterMasterSoal = async () => {
      gpf.gLoading.show();
      const hide = () => gpf.gLoading.hide();

      const url = api.tentorFilterBankSoal;
      const hitApi = await api.hitApiGet(url, hide);
      const rs = hitApi.data.response;
      dataMS.filterMasterSoal = rs;

      await changePaketSoal();
    }
    getFilterMasterSoal();

    const changePS = () => {
      getDataListMS();
    };

    const goToPage = (p: number) => {
      dataMS.page = p;
      getDataListMS();
    };

    const nextPage = () => {
      dataMS.page = dataMS.page + 1;
      getDataListMS();
    };

    const beforePage = () => {
      dataMS.page = dataMS.page - 1;
      getDataListMS();
    };

    const lastPage = () => {
      dataMS.page = dataMS.paginate.totalPages;
      getDataListMS();
    };

    // BEGIN for CKEditor
    const date = ref("");
    const classicEditor = ClassicEditor;
    const inlineEditor = InlineEditor;
    const balloonEditor = BalloonEditor;
    const documentEditor = DocumentEditor;
    const simpleEditorConfig = {
      plugins: [
        ParagraphPlugin,
        BoldPlugin,
        UnderlinePlugin,
        ItalicPlugin,
        LinkPlugin,
      ],
      toolbar: {
        items: ["bold", "italic", "underline", "link"],
      },
    };
    const editorConfig = {
      cloudServices: {
        tokenUrl: "",
        uploadUrl: "",
      },
      plugins: [
        Font,
        EssentialsPlugin,
        BoldPlugin,
        UnderlinePlugin,
        StrikethroughPlugin,
        ItalicPlugin,
        LinkPlugin,
        ParagraphPlugin,
        CodePlugin,
        SubscriptPlugin,
        SuperscriptPlugin,
        EasyImage,
        Heading,
        HeadingButtonsUI,
        Highlight,
      ],
      toolbar: {
        items: [
          "fontSize",
          "fontFamily",
          "fontColor",
          "fontBackgroundColor",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "code",
          "subscript",
          "superscript",
          "link",
          "undo",
          "redo",
          "imageUpload",
          "highlight",
        ],
      },
    };
    const editorData = ref("<p>Content of the editor.</p>");
    const initDocumentEditor = (editor: any) => {
      const toolbarContainer = document.querySelector(
        ".document-editor__toolbar"
      );
      toolbarContainer?.appendChild(editor.ui.view.toolbar.element);
      const win: any = window;
      win.editor = editor;
    };
    // END for CKEditor

    return {
      titleTable,
      getDataListMS,
      dataMS,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
      listStatus,
      listPaket,
      changePaketSoal,
      changeJenisSoal,
      changeKategoriSoal,
      changeBidangSoal,

      // for CKEditor
      date,
      classicEditor,
      inlineEditor,
      balloonEditor,
      documentEditor,
      simpleEditorConfig,
      editorConfig,
      editorData,
      initDocumentEditor,
    };
  },
  components: {
    importExcelFunc,
  },
  data() {
    return {
      // for modal data bs
      section: "add",
      msgSuccess: "",

      detailMS: {
        id: 0,
        idJenis: "",
        idCategory: "",
        idBidang: "",
        question: "",
        questionImg: "",
        pembahasan: "",
        pembahasanImg: "",
        answer: "",
        option: [
          {
            option: "A",
            text: "",
            img: "",
            poin: 0,
          },
          {
            option: "B",
            text: "",
            img: "",
            poin: 0,
          },
          {
            option: "C",
            text: "",
            img: "",
            poin: 0,
          },
          {
            option: "D",
            text: "",
            img: "",
            poin: 0,
          },
          {
            option: "E",
            text: "",
            img: "",
            poin: 0,
          },
        ],
      } as DataMS,

      exDataMS: {
        id: 0,
        idJenis: "",
        idCategory: "",
        idBidang: "",
        question: "",
        questionImg: "",
        pembahasan: "",
        pembahasanImg: "",
        answer: "",
        option: [
          {
            option: "A",
            text: "",
            img: "",
            poin: 0,
          },
          {
            option: "B",
            text: "",
            img: "",
            poin: 0,
          },
          {
            option: "C",
            text: "",
            img: "",
            poin: 0,
          },
          {
            option: "D",
            text: "",
            img: "",
            poin: 0,
          },
          {
            option: "E",
            text: "",
            img: "",
            poin: 0,
          },
        ],
      } as DataMS,

      optionObj: {
        option: "",
        text: "",
        img: "",
        poin: 0,
      },

      exDeleteMS: {
        id: 0,
        question: "",
      },

      exListMS: {
        jenis: [],
        kategori: [],
        bidang: [],
      },

      listKategoriMS: [],
      listJenisMS: [],
      listBidangMS: [],
      showModalMS: "" as any,
    };
  },
  methods: {
    async listBidangSoalApi(): Promise<void> {
      const showMKS = async () => {
        gpf.showModal("#modal-data-bs");
        setTimeout(() => {
          cash("#modal-data-bs").css({ "padding-left": "0px !important" });
        }, 10000);
      };

      axios
        .get(api.tentorBidangSoal, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();
          const rs = result.data.response;
          // console.log("response get js ", rs);
          if (this.section === "add") {
            this.exDataMS.idBidang = rs[0].id;
          }
          this.exListMS.bidang = rs.map((e: any) => {
            const obj = {
              title: e.title,
              value: e.id,
              id: e.id,
            };
            return obj;
          });

          await showMKS();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    async listKategoriSoalApi(): Promise<void> {
      axios
        .get(api.tentorKategoriSoal, this.configGet)
        .then(async (result: any) => {
          const rs = result.data.response;
          // console.log("response get js ", rs);
          if (this.section === "add") {
            this.exDataMS.idCategory = rs[0].id;
          }
          this.exListMS.kategori = rs.map((e: any) => {
            const obj = {
              title: e.title,
              value: e.id,
              id: e.id,
              kode: e.kode,
            };
            return obj;
          });

          await this.listBidangSoalApi();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    async listJenisSoalApi(): Promise<void> {
      axios
        .get(api.tentorJenisSoal, this.configGet)
        .then(async (result: any) => {
          const rs = result.data.response;
          // console.log("response get js ", rs);
          if (this.section === "add") {
            this.exDataMS.idJenis = rs[0].id;
          }
          this.exListMS.jenis = rs.map((e: any) => {
            const obj = {
              title: e.title,
              value: e.id,
              id: e.id,
              kode: e.kode,
            };
            return obj;
          });

          await this.listKategoriSoalApi();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    async emptyField() {
      const ex: DataMS = this.exDataMS;
      ex.idJenis = "";
      ex.idCategory = "";
      ex.idBidang = "";
      ex.question = "";
      ex.questionImg = "";
      ex.pembahasan = "";
      ex.pembahasanImg = "";
      ex.answer = "";
      ex.option.forEach((e): OptionMS => {
        return {
          option: e.option,
          text: "",
          img: "",
          poin: 0,
        };
      });
    },
    async addMS() {
      await this.emptyField();
      this.section = "add";
      gpf.gLoading.show();
      // gpf.showModal("#modal-data-bs");

      await this.listJenisSoalApi();
    },
    addMSApi() {
      gpf.gLoading.show();
      const ex: DataMS = this.exDataMS;

      axios
        .post(api.tentorMasterSoal, ex, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-bs");
          await this.emptyField();
          await this.getDataListMS();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    editMS(data: any) {
      this.section = "edit";
      gpf.gLoading.show();

      axios
        .get(api.tentorMasterSoal + data.id, this.configGet)
        .then(async (result: any) => {
          const rs = result.data.response;
          // console.log("response get deu ", rs);
          const ex: DataMS = this.exDataMS;
          ex.id = rs.id;
          ex.idJenis = rs.idJenis.toString();
          ex.idCategory = rs.idKategori.toString();
          ex.idBidang = rs.idBidang.toString();
          ex.question = rs.question;
          ex.questionImg = rs.questionImg;
          ex.pembahasan = rs.pembahasan;
          ex.pembahasanImg = rs.pembahasanImg;
          ex.answer = rs.answer;
          ex.option = rs.options;

          await this.listJenisSoalApi();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    editMSApi(): void {
      gpf.gLoading.show();
      const ex: DataMS = this.exDataMS;

      axios
        .put(api.tentorMasterSoal + ex.id, ex, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-bs");
          await this.emptyField();
          await this.getDataListMS();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    detailMSApi(data: any): void {
      gpf.gLoading.show();

      axios
        .get(api.tentorMasterSoal + data.id, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          const rs = result.data.response;
          // console.log("response get deu ", rs);

          this.detailMS = rs;

          await gpf.showModal("#modal-detail-bs");
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    deleteMS(data: any): void {
      const ex = this.exDeleteMS;
      ex.id = data.id;
      ex.question = data.question;

      gpf.showModal("#delete-modal-data-ms");
    },
    deleteMSApi() {
      const ex = this.exDeleteMS;

      gpf.gLoading.show();

      axios
        .delete(api.tentorMasterSoal + ex.id, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          ex.id = 0;
          ex.question = "";

          await gpf.handleSds(result);
          await gpf.hideModal("#delete-modal-data-ms");
          await this.getDataListMS();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    async importExcelFunc(data?: any) {
      // console.log("importExcelFunc ", data);

      typeof data === "string"
        ? gpf.showModal("#modal-import-excel")
        : await this.uploadImportExcel(data);
    },
    convertBodyIE(data: any) {
      return data.map((e: any) => {
        return {
          jenis: e["JENIS"],
          kategori: e["KATEGORI"],
          bidang: e["BIDANG"],
          pertanyaan: e["PERTANYAAN"],
          questionImg: e["GAMBAR PERTANYAAN"],
          jawaban: e["JAWABAN"],
          pembahasan: e["PEMBAHASAN"],
          pembahasanImg: e["GAMBAR PEMBAHASAN"],
          options: [
            {
              opsi: "A",
              text: e["OPSI A"],
              img: e["GAMBAR A"],
              bobot: e["BOBOT A"],
            },
            {
              opsi: "B",
              text: e["OPSI B"],
              img: e["GAMBAR B"],
              bobot: e["BOBOT B"],
            },
            {
              opsi: "C",
              text: e["OPSI C"],
              img: e["GAMBAR C"],
              bobot: e["BOBOT C"],
            },
            {
              opsi: "D",
              text: e["OPSI D"],
              img: e["GAMBAR D"],
              bobot: e["BOBOT D"],
            },
            {
              opsi: "E",
              text: e["OPSI E"],
              img: e["GAMBAR E"],
              bobot: e["BOBOT E"],
            },
          ]
        };
      });
    },
    async uploadImportExcel(data: any) {
      gpf.gLoading.show();
      const body = this.convertBodyIE(data);

      // console.log("body IE ", body);

      axios
        .post(api.tentorImportSoal, body, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await gpf.handleSds(result);
          await this.getDataListMS();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
  },
});
