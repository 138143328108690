import { App, defineAsyncComponent } from "vue";
import Chart from "./chart/Main.vue";
import GoogleMapLoader from "./google-map-loader/Main.vue";
import Highlight from "./highlight/Main.vue";
import Litepicker from "./litepicker/Main.vue";
import Tippy from "./tippy/Main.vue";
import TippyContent from "./tippy-content/Main.vue";
import TailSelect from "./tail-select/Main.vue";
import LoadingIcon from "./loading-icon/Main.vue";
import TinySlider from "./tiny-slider/Main.vue";
import CKEditor from "./ckeditor/Main.vue";
import Dropzone from "./dropzone/Main.vue";
import * as featherIcons from "@zhuowenli/vue-feather-icons";
import UrlSlug from "./url-slug/Main.vue";
import LoadingDiv from "./loading-div/Main.vue";
import msgHandler from "./msg-handler/Main.vue";
import PageTitleMain from "./page-title/main.vue";

const InputAutocomplete = defineAsyncComponent(
  () => import(`@/global-components/input/autocomplete/Main.vue`)
);

const DataTable = defineAsyncComponent(
  () => import(`@/global-components/data-table/Main.vue`)
);
export default (app: App): void => {
  app.component("Chart", Chart);
  app.component("GoogleMapLoader", GoogleMapLoader);
  app.component("Highlight", Highlight);
  app.component("Litepicker", Litepicker);
  app.component("Tippy", Tippy);
  app.component("TippyContent", TippyContent);
  app.component("TailSelect", TailSelect);
  app.component("LoadingIcon", LoadingIcon);
  app.component("TinySlider", TinySlider);
  app.component("Dropzone", Dropzone);
  app.component("CKEditor", CKEditor);
  app.component("UrlSlug", UrlSlug);
  app.component("LoadingDiv", LoadingDiv);
  app.component("MsgHandler", msgHandler);
  app.component("PageTitleMain", PageTitleMain);
  app.component("InputAutocomplete", InputAutocomplete);
  app.component("DataTable", DataTable);

  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = "24";
    app.component(key, icon);
  }
};
