
import { defineComponent, reactive } from "vue";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";

export default defineComponent({
  setup() {
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();

    const titleTable: Array<string> = [
      "Invoice",
      "Sales",
      "Name",
      "Phone Number",
      "Email",
      "Price",
      "Status",
      "Payment Status",
      "Paket",
      "Created",
    ];

    const dataOrder = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
    });

    const getData = async () => {
      cash("#loadingDMaff").show();

      const hide = () => cash("#loadingDMaff").hide();
      const page = dataOrder.page;
      const pageSize = dataOrder.pageSize;
      const search = dataOrder.search;
      const url = api.affOrder +
        "?search=" +
        search +
        "&page=" +
        page +
        "&pageSize=" +
        pageSize;

      const hitApi = await api.hitApiGet(url, hide);
      const ds = hitApi.data.diagnostic;
      const rs = hitApi.data.response;

      dataOrder.data = rs;
      dataOrder.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);

    };
    getData();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataOrder.page = p;
      getData();
    };

    const nextPage = () => {
      dataOrder.page = dataOrder.page + 1;
      getData();
    };

    const beforePage = () => {
      dataOrder.page = dataOrder.page - 1;
      getData();
    };

    const lastPage = () => {
      dataOrder.page = dataOrder.paginate.totalPages;
      getData();
    };

    return {
      titleTable,
      getData,
      dataOrder,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
    };
  },
  data() {
    return {};
  },
  methods: {
  },
});
